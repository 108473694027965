import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import { Title }     from '@angular/platform-browser';

import { APP_CONFIG } from '../../../constants';
import { ENGAGEMENT_CONSTANTS } from '../../constants';

@Component({
  selector: 'co-work-root',
  templateUrl: './co-work.component.html',
  styleUrls: []
})
export class CoWorkComponent {
    CONFIG=APP_CONFIG;
    ENGAGEMENT_CONSTANTS=ENGAGEMENT_CONSTANTS;
    constructor(private titleService: Title) {
        this.CONFIG=APP_CONFIG;
        this.ENGAGEMENT_CONSTANTS=ENGAGEMENT_CONSTANTS;
        this.titleService.setTitle( APP_CONFIG.APP_NAME+ " :: "+ this.ENGAGEMENT_CONSTANTS.LABEL.CO_WORK);
    }
}
import { NgModule }     from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { ScreenLockComponent } from './views/screen-lock/screen-lock.component';
import { NotConfirmedComponent } from './views/not-confirmed/not-confirmed.component';
import { ConfirmedComponent } from './views/confirmed/confirmed.component';
import { ForgotPasswordConfirmedComponent } from './views/forgot-password-confirmed/forgot-password-confirmed.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { RegisteredComponent } from './views/registered/registered.component';
import { PasswordResetComponent } from './views/password-reset/password-reset.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';

import { AUTH_CONSTANTS } from './constants';

const authRoutes: Routes = [
    { path: AUTH_CONSTANTS.LINK.LOGIN,  component: LoginComponent },
    { path: AUTH_CONSTANTS.LINK.REGISTER, component: RegisterComponent },
    { path: AUTH_CONSTANTS.LINK.SCREEN_LOCK, component: ScreenLockComponent },
    { path: AUTH_CONSTANTS.LINK.NOT_CONFIRMED, component: NotConfirmedComponent },
    { path: AUTH_CONSTANTS.LINK.CONFIRMED, component: ConfirmedComponent },
    { path: AUTH_CONSTANTS.LINK.FORGOT_PASSWORD, component: ForgotPasswordComponent },
    { path: AUTH_CONSTANTS.LINK.FORGOT_PASSWORD_CONFIRMATION, component: ForgotPasswordConfirmedComponent },
    { path: AUTH_CONSTANTS.LINK.REGISTERED, component: RegisteredComponent },
    { path: AUTH_CONSTANTS.LINK.PASSWORD_RESET, component: PasswordResetComponent },
    { path: AUTH_CONSTANTS.LINK.RESET_PASSWORD, component: ResetPasswordComponent },

];


@NgModule({
    imports: [RouterModule.forChild(authRoutes)],
    exports: [RouterModule]
})
export class AuthRoutingModule {}
import { NgModule }             from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HealthCareComponent } from './views/health-care/health-care.component';
import { EducationComponent } from './views/education/education.component';
import { AdCampaignComponent } from './views/ad-campaign/ad-campaign.component';
import { SoftwareInformationComponent } from './views/software-information/software-information.component';
import { LogisticsComponent } from './views/logistics/logistics.component';
import { EventManagementComponent } from './views/event-management/event-management.component';

import { AuthGuard } from '../auth/services/auth.guard';

import { WORKING_DOMAIN_CONSTANTS } from './constants';


const workingDomainRoutes: Routes = [
    { path: WORKING_DOMAIN_CONSTANTS.LINK.HEALTH_CARE, component: HealthCareComponent },
    { path: WORKING_DOMAIN_CONSTANTS.LINK.EDUCATION, component: EducationComponent },
    { path: WORKING_DOMAIN_CONSTANTS.LINK.AD_CAMPAIGN, component: AdCampaignComponent },
    { path: WORKING_DOMAIN_CONSTANTS.LINK.SOFTWARE_INFORMATION, component: SoftwareInformationComponent },
    { path: WORKING_DOMAIN_CONSTANTS.LINK.LOGISTICS, component: LogisticsComponent },
    { path: WORKING_DOMAIN_CONSTANTS.LINK.EVENT_MANAGEMENT, component: EventManagementComponent },
];

@NgModule({
    imports: [RouterModule.forChild(workingDomainRoutes)],
    exports: [RouterModule]
})
export class WorkingDomainRoutingModule {}

import { NgModule }            from '@angular/core';
import { CommonModule }        from '@angular/common';
import {TranslateModule}       from '@ngx-translate/core';
import {HttpClientModule,HttpClient} from '@angular/common/http';
import { FormsModule }   from '@angular/forms';
import { TemplateModule} from '../template/template.module';
import { AboutUsRoutingModule }   from './about-us-routing.module';
import { OverviewComponent } from './views/overview/overview.component';
import { VisionMissionComponent } from './views/vision-mission/vision-mission.component';
import { ManagementTeamComponent } from './views/management-team/management-team.component';


@NgModule({
    declarations: [
        OverviewComponent,
        VisionMissionComponent,
        ManagementTeamComponent,
    ],
    imports: [
        TranslateModule,
        HttpClientModule,
        CommonModule,
        FormsModule,
        TemplateModule,
        AboutUsRoutingModule,
    ],
    exports: [OverviewComponent,VisionMissionComponent,ManagementTeamComponent,],
    providers: [],
    bootstrap: [OverviewComponent,VisionMissionComponent,ManagementTeamComponent,]
})
export class AboutUsModule { }

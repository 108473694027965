<div class="home-banner-client-say">
    <div class="bg-opacity">
        <div class="container-fluid">
            <div id="homebannercarouselIndicators" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators">
                    <li data-target="#homebannercarouselIndicators" data-slide-to="0" class="active"></li>
                    <li data-target="#homebannercarouselIndicators" data-slide-to="1"></li>
                    <li data-target="#homebannercarouselIndicators" data-slide-to="2"></li>
                    <li data-target="#homebannercarouselIndicators" data-slide-to="3"></li>
                </ol>
                <div class="carousel-inner pb-75px">
                    <div class="carousel-item active">
                        <div class="client-says">
                            <div class="heading-white text-left">
                                <h3 class="heading-title-white1">Product Development</h3>
                            </div>
                            <div class="client-content">
                                <p>We, at Indian Technologies Dreams offer an array
                                    of web design services like Web site Design, Software, Custom Web site
                                    development & Graphic Design Services in order to enhance the corporate
                                    image of our clients. A strong web site must be developed with careful
                                    planning, which should be visually appealing spreading out its distinctive
                                    flavors...</p>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="client-says">
                            <div class="heading-white text-left">
                                <h3 class="heading-title-white1">Data Processing</h3>
                            </div>
                            <div class="client-content">
                                <p>      Indian Technologies Dreams is a leading software development company in India providing Offshore
                                    SoftwareDevelopment Services & solutions with services such as outsourcing software development
                                    India, Web
                                    Development India Product Development, E-strategy Consulting, Offshore software
                                    development,E-strategy Consulting...</p>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="client-says">
                            <div class="heading-white text-left">
                                <h3 class="heading-title-white1">Mobile Application</h3>
                            </div>
                            <div class="client-content">
                                <p>Indian Tech Dreams has wide experience in Mobile Application Development. We provide Mobile
                                    Application
                                    Development platform including Windows Mobile Application, Mobile enabled website development, J2ME
                                    based Mobile Application Development on Nokia, Samsung, LG phones, Android - Google mobile
                                    hosted...</p>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="client-says">
                            <div class="heading-white text-left">
                                <h3 class="heading-title-white1">Web Technology</h3>
                            </div>
                            <div class="client-content">
                                <p>"Information Technology is a fast growing industry. New architecture and multiplying software
                                    platforms are forcing the application development to undergo a massive change in the Technologies .
                                    And yet the pressure of building a robust & agile application is growing continuously...</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

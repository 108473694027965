import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import { AlertComponent } from './directives/alert/alert.component';
import { LanguageSwitcherComponent } from './directives/language-switcher/language-switcher.component';
import { AlertService } from './services/alert.service';
import { HttpClientService } from './services/http-client.service';
import { BreadCrumService } from './services/bread-crum.service';
import { SweetAlertService } from './services/sweet-alert.service';


@NgModule({
  declarations: [
      AlertComponent,
      LanguageSwitcherComponent
    ],
  imports: [
      CommonModule,
      TranslateModule
  ],
  exports:[
      AlertComponent,
      LanguageSwitcherComponent
  ],
  providers: [
      AlertService,BreadCrumService,SweetAlertService
  ],
  bootstrap: [
      AlertComponent,
      LanguageSwitcherComponent
  ]
})
export class SharedModule {

}

<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>Maintenance and Support</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <p class="mb-4">Indian Technologies Dreams has a unique blend of web site programmers web site designers that ensures you quality work in quick time. Our web site design and development services guarantee hassle-free software development from conception to conceptualization at throw-away budget. As a result we have been blessed with a great base of clients that involve us in some exciting projects. Our growing list of clientele and proud portfolio suggest the same and vouch safes authentic service from expert and qualified professionals.</p>
                    <p class="mb-4">We, at Indian Technologies Dreams offer an array of web design services like Web site Design, Software, Custom Web site development & Graphic Design Services in order to enhance the corporate image of our clients. A strong web site must be developed with careful planning, which should be visually appealing spreading out its distinctive flavors and colors across the world. We believes in creations that not just only stand out due to its amiable look and matchless splendor, but can also effectively draw the target audience.</p>
                </div>
            </div>
        </div>
    </div>
</div>
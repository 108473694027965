<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>Vision & Mission</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <p class="mb-4">Our Vision is to be a software and knowledge services leader that creates value for stakeholders through integrity, mutual respect, and an enduring commitment to preserve the environment.</p>
                    <ul>
                        <li>Be voted as one of the most preferred working places.</li>
                        <li>Build creditable IP in area of RIA and web portals.</li>
                        <li>have a sustainable social arm.</li>
                    </ul>
                    <p class="mb-4">Our Mission is to become a software and knowledge services leader that enables our clients to transform business processes and achieve lasting competitive advantage. We will be an inimitable software and knowledge services provider that makes it possible for our customers to win through innovative business solutions.</p>
                    <p class="mb-4">We will create a culture that encourages our employees to excel and execute with passion for our customers.</p>
                    <p class="mb-4">Do you need an offshore software development company to cut the cost of your business software solution? Indian Technologies dreams provides you a low cost software development solution and makes you happy with a very high quality work. This makes your purpose complete to contact an offshore vendor</p>
                </div>
            </div>
        </div>
    </div>
</div>
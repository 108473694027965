<div class="row">
    <div class="main-login col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2 col-md-4 col-md-offset-4 col-lg-4 col-lg-offset-4">
        <div class="logo text-center">
            <img src="{{CONFIG.IMAGE_URL}}/images/logo.png" alt="{{CONFIG.APP_NAME}}" class="img-responsive" />
        </div>
        <alert-message></alert-message>
        <p class="text-center text-dark text-bold text-extra-large margin-top-15">{{"auth.view.welcomeMessage" | translate }}</p>
        <p class="text-center">{{"auth.view.resetDetail" | translate }}</p>
        <!-- start: REGISTER BOX -->
        <div class="box-register">
            <form class="form-register" name="resetPasswordForm" role="form" (ngSubmit)="f.form.valid && resetPassword(registerUser,f)" #f="ngForm" novalidate novalidate="novalidate" >
                <p>{{"auth.view.passwordDetails" | translate }}</p>
                <div class="form-group"  [ngClass]="{ 'has-error': f.submitted && !password.valid }">
                    <input autocomplete="false" type="password" class="form-control" id="password" name="password" placeholder="{{ 'register.placeholder.password' | translate }}" [(ngModel)]="registerUser.password" #password="ngModel" required>
                    <span class="error text-small block" *ngIf="f.submitted && !password.valid">{{ "register.error.password" | translate }}</span>
                </div>
                <div class="form-group"  [ngClass]="{ 'has-error': f.submitted && !confirmPassword.valid }">
                    <input autocomplete="false" type="password" class="form-control" name="confirmPassword" placeholder="{{ 'register.placeholder.confirmPassword' | translate }}" [(ngModel)]="registerUser.confirmPassword" #confirmPassword="ngModel" required/>
                    <span class="error text-small block" *ngIf="f.submitted && !confirmPassword.valid">{{ "register.error.confirmPassword" | translate }}</span>
                    <span class="error text-small block" *ngIf="pwmatch(registerUser)">{{ "register.error.sameConfirmPassword" | translate }}</span>
                </div>
                <div class="form-actions">
                    <button type="submit" class="btn btn-red btn-block" [disabled]="loading">
                    {{"auth.view.submit" | translate}}
                    <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    </button>

                </div>
                <p class="text-center margin-top-15">
                    <span>{{"auth.view.alreadyAccount" | translate}}</span>
                    <a [routerLink]="[AUTH_CONSTANTS.URL.LOGIN]">{{"auth.view.logIn" | translate }}</a>
                </p>
            </form>
        </div>
        <!-- end: REGISTER BOX -->
    </div>
</div>
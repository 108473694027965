<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>Mobile Development Technologies</h1>
            <span>Our Engagement model is according to customer preferences like :</span>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-5 img-col"><img src="assets/images/mobile-technologies/android.png"></div>
                <div class="col-md-7 text-center text-md-left content-col">
                    <div class="mb-5">
                        <h4>Native</h4>
                        <span>Develop Native Mobile Applications for iOS, Android and Tizen.</span>
                    </div>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero quod consequuntur quibusdam, enim expedita sed quia nesciunt incidunt accusamus necessitatibus modi adipisci officia libero accusantium esse hic, obcaecati, ullam, laboriosam!</p>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti vero, animi suscipit id facere officia. Aspernatur, quo, quos nisi dolorum aperiam fugiat deserunt velit rerum laudantium cum magnam excepturi quod.</p>
                    <a (click)="callNative();" class="btn btn-outline-dark">Learn more</a>
                </div>
            </div>
            <div class="vertical-line"></div>
            <div class="row align-items-center">
                <div class="col-md-5 img-col d-block d-md-none">
                    <div class="row xamarin-images">
                        <div class="col-sm-12"><img class="obj-contain" src="assets/images/mobile-technologies/swift.png"></div>
                        <div class="col-sm-12"><img class="obj-contain" src="assets/images/mobile-technologies/ios.png"></div>
                    </div>
                </div>
                <div class="col-md-7 text-center text-md-left content-col">
                    <div class="mb-5">
                        <h4>Xamarin</h4>
                        <span>Native Cross Platform Mobile Application developed using Microsoft Xamarin Framework.</span>
                    </div>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero quod consequuntur quibusdam, enim expedita sed quia nesciunt incidunt accusamus necessitatibus modi adipisci officia libero accusantium esse hic, obcaecati, ullam, laboriosam!</p>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti vero, animi suscipit id facere officia. Aspernatur, quo, quos nisi dolorum aperiam fugiat deserunt velit rerum laudantium cum magnam excepturi quod.</p>
                    <a (click)="callXamarin();" class="btn btn-outline-dark">Learn more</a>
                </div>
                <div class="col-md-5 img-col d-none d-md-block">
                    <div class="row xamarin-images">
                        <div class="col-sm-12"><img class="obj-contain" src="assets/images/mobile-technologies/swift.png"></div>
                        <div class="col-sm-12"><img class="obj-contain" src="assets/images/mobile-technologies/ios.png"></div>
                    </div>
                </div>
            </div>
            <div class="vertical-line"></div>
            <div class="row align-items-center">
                <div class="col-md-5 img-col"><img src="assets/images/mobile-technologies/titanium.png"></div>
                <div class="col-md-7 text-center text-md-left content-col">
                    <div class="mb-5">
                        <h4>Titanium Hybrid</h4>
                        <span>Reports let you easily track and analyze your product sales, orders, and payments.</span>
                    </div>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero quod consequuntur quibusdam, enim expedita sed quia nesciunt incidunt accusamus necessitatibus modi adipisci officia libero accusantium esse hic, obcaecati, ullam, laboriosam!</p>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti vero, animi suscipit id facere officia. Aspernatur, quo, quos nisi dolorum aperiam fugiat deserunt velit rerum laudantium cum magnam excepturi quod.</p>
                    <a (click)="callTitanium();" class="btn btn-outline-dark">Learn more</a>
                </div>
            </div>
            <div class="vertical-line"></div>
            <div class="row align-items-center">
                <div class="col-md-5 img-col d-block d-md-none"><img src="assets/images/mobile-technologies/ionic.png"></div>
                <div class="col-md-7 text-center text-md-left content-col">
                    <div class="mb-5">
                        <h4>Ionic (Hybrid)</h4>
                        <span>Hybrid Cross Platform Mobile Application developed using Ionic + PhoneGap.</span>
                    </div>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero quod consequuntur quibusdam, enim expedita sed quia nesciunt incidunt accusamus necessitatibus modi adipisci officia libero accusantium esse hic, obcaecati, ullam, laboriosam!</p>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti vero, animi suscipit id facere officia. Aspernatur, quo, quos nisi dolorum aperiam fugiat deserunt velit rerum laudantium cum magnam excepturi quod.</p>
                    <a (click)="callIonic();" class="btn btn-outline-dark">Learn more</a>
                </div>
                <div class="col-md-5 img-col d-none d-md-block"><img src="assets/images/mobile-technologies/ionic.png"></div>
            </div>
            <div class="vertical-line"></div>
            <div class="row align-items-center">
                <div class="col-md-5 img-col"><img src="assets/images/mobile-technologies/react-native.png"></div>
                <div class="col-md-7 text-center text-md-left content-col">
                    <div class="mb-5">
                        <h4>React Native</h4>
                        <span>Reports let you easily track and analyze your product sales, orders, and payments.</span>
                    </div>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero quod consequuntur quibusdam, enim expedita sed quia nesciunt incidunt accusamus necessitatibus modi adipisci officia libero accusantium esse hic, obcaecati, ullam, laboriosam!</p>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti vero, animi suscipit id facere officia. Aspernatur, quo, quos nisi dolorum aperiam fugiat deserunt velit rerum laudantium cum magnam excepturi quod.</p>
                    <a (click)="callReactNative();" class="btn btn-outline-dark">Learn more</a>
                </div>
            </div>
            <div class="vertical-line"></div>
            <div class="row align-items-center">
                <div class="col-md-5 img-col d-block d-md-none"><img class="obj-contain" src="assets/images/mobile-technologies/framework7.jpeg"></div>
                <div class="col-md-7 text-center text-md-left content-col">
                    <div class="mb-5">
                        <h4>Framework7 Hybrid</h4>
                        <span>Reports let you easily track and analyze your product sales, orders, and payments.</span>
                    </div>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero quod consequuntur quibusdam, enim expedita sed quia nesciunt incidunt accusamus necessitatibus modi adipisci officia libero accusantium esse hic, obcaecati, ullam, laboriosam!</p>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti vero, animi suscipit id facere officia. Aspernatur, quo, quos nisi dolorum aperiam fugiat deserunt velit rerum laudantium cum magnam excepturi quod.</p>
                    <a (click)="callFramework7();" class="btn btn-outline-dark">Learn more</a>
                </div>
                <div class="col-md-5 img-col d-none d-md-block"><img class="obj-contain" src="assets/images/mobile-technologies/framework7.jpeg"></div>
            </div>
        </div>
    </div>
</div>
import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { APP_CONFIG } from '../../constants';

@Component({
  selector: 'template-footer',
  templateUrl: './footer.component.html',
  styleUrls: []
})
export class FooterComponent {

    CONFIG=APP_CONFIG;
    constructor(translate: TranslateService) {
        this.CONFIG=APP_CONFIG;
    }

    title = 'Conglutinate Dreams';
}

<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>Our Services</h1>
            <span>Indian Technologies Dreams  are providing services in the following fields in optimum Cost :</span>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-5 img-col"><img src="assets/images/our-services/software-technology-consulting.png"></div>
                <div class="col-md-7 text-center text-md-left content-col">
                    <div class="mb-5">
                        <h4>Software Technologies Consulting</h4>
                        <span>Reports let you easily track and analyze your product sales, orders, and payments.</span>
                    </div>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero quod consequuntur quibusdam, enim expedita sed quia nesciunt incidunt accusamus necessitatibus modi adipisci officia libero accusantium esse hic, obcaecati, ullam, laboriosam!</p>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti vero, animi suscipit id facere officia. Aspernatur, quo, quos nisi dolorum aperiam fugiat deserunt velit rerum laudantium cum magnam excepturi quod.</p>
                    <a (click)="callSoftwareTechnologiesConsulting();" class="btn btn-outline-dark">Learn more</a>
                </div>
            </div>
            <div class="vertical-line"></div>
            <div class="row align-items-center">
                <div class="col-md-5 img-col d-block d-md-none"><img src="assets/images/our-services/manage-it-services.png"></div>
                <div class="col-md-7 text-center text-md-left content-col">
                    <div class="mb-5">
                        <h4>Manage IT Services</h4>
                        <span>Reports let you easily track and analyze your product sales, orders, and payments.</span>
                    </div>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero quod consequuntur quibusdam, enim expedita sed quia nesciunt incidunt accusamus necessitatibus modi adipisci officia libero accusantium esse hic, obcaecati, ullam, laboriosam!</p>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti vero, animi suscipit id facere officia. Aspernatur, quo, quos nisi dolorum aperiam fugiat deserunt velit rerum laudantium cum magnam excepturi quod.</p>
                    <a (click)="callManageItServices();" class="btn btn-outline-dark">Learn more</a>
                </div>
                <div class="col-md-5 img-col d-none d-md-block"><img src="assets/images/our-services/manage-it-services.png"></div>
            </div>
            <div class="vertical-line"></div>
            <div class="row align-items-center">
                <div class="col-md-5 img-col"><img src="assets/images/our-services/software-product-development.png"></div>
                <div class="col-md-7 text-center text-md-left content-col">
                    <div class="mb-5">
                        <h4>Software Product Development</h4>
                        <span>Reports let you easily track and analyze your product sales, orders, and payments.</span>
                    </div>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero quod consequuntur quibusdam, enim expedita sed quia nesciunt incidunt accusamus necessitatibus modi adipisci officia libero accusantium esse hic, obcaecati, ullam, laboriosam!</p>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti vero, animi suscipit id facere officia. Aspernatur, quo, quos nisi dolorum aperiam fugiat deserunt velit rerum laudantium cum magnam excepturi quod.</p>
                    <a (click)="callSoftwareProductDevelopment();" class="btn btn-outline-dark">Learn more</a>
                </div>
            </div>
            <div class="vertical-line"></div>
            <div class="row align-items-center">
                <div class="col-md-5 img-col d-block d-md-none"><img src="assets/images/our-services/product-maintenance.png"></div>
                <div class="col-md-7 text-center text-md-left content-col">
                    <div class="mb-5">
                        <h4>Product Maintenance & Support</h4>
                        <span>Reports let you easily track and analyze your product sales, orders, and payments.</span>
                    </div>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero quod consequuntur quibusdam, enim expedita sed quia nesciunt incidunt accusamus necessitatibus modi adipisci officia libero accusantium esse hic, obcaecati, ullam, laboriosam!</p>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti vero, animi suscipit id facere officia. Aspernatur, quo, quos nisi dolorum aperiam fugiat deserunt velit rerum laudantium cum magnam excepturi quod.</p>
                    <a (click)="callProductMaintenance();" class="btn btn-outline-dark">Learn more</a>
                </div>
                <div class="col-md-5 img-col d-none d-md-block"><img src="assets/images/our-services/product-maintenance.png"></div>
            </div>
            <div class="vertical-line"></div>
            <div class="row align-items-center">
                <div class="col-md-5 img-col"><img src="assets/images/our-services/quality-assurance.png"></div>
                <div class="col-md-7 text-center text-md-left content-col">
                    <div class="mb-5">
                        <h4>Quality Assurance Services</h4>
                        <span>Reports let you easily track and analyze your product sales, orders, and payments.</span>
                    </div>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero quod consequuntur quibusdam, enim expedita sed quia nesciunt incidunt accusamus necessitatibus modi adipisci officia libero accusantium esse hic, obcaecati, ullam, laboriosam!</p>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti vero, animi suscipit id facere officia. Aspernatur, quo, quos nisi dolorum aperiam fugiat deserunt velit rerum laudantium cum magnam excepturi quod.</p>
                    <a (click)="callQualityAssurance();" class="btn btn-outline-dark">Learn more</a>
                </div>
            </div>
            <div class="vertical-line"></div>
            <div class="row align-items-center">
                <div class="col-md-5 img-col d-block d-md-none"><img src="assets/images/our-services/mobility-solution.png"></div>
                <div class="col-md-7 text-center text-md-left content-col">
                    <div class="mb-5">
                        <h4>Enterprise Mobility Solutions</h4>
                        <span>Reports let you easily track and analyze your product sales, orders, and payments.</span>
                    </div>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero quod consequuntur quibusdam, enim expedita sed quia nesciunt incidunt accusamus necessitatibus modi adipisci officia libero accusantium esse hic, obcaecati, ullam, laboriosam!</p>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti vero, animi suscipit id facere officia. Aspernatur, quo, quos nisi dolorum aperiam fugiat deserunt velit rerum laudantium cum magnam excepturi quod.</p>
                    <a (click)="callMobilitySolution();" class="btn btn-outline-dark">Learn more</a>
                </div>
                <div class="col-md-5 img-col d-none d-md-block"><img src="assets/images/our-services/mobility-solution.png"></div>
            </div>
        </div>
    </div>
</div>
import { NgModule }            from '@angular/core';
import { CommonModule }        from '@angular/common';
import {TranslateModule}       from '@ngx-translate/core';
import {HttpClientModule,HttpClient} from '@angular/common/http';
import { FormsModule }   from '@angular/forms';
import { TemplateModule} from '../template/template.module';
import { OurServicesRoutingModule }   from './our-services-routing.module';
import { ManageItServicesComponent } from './views/manage-it-services/manage-it-services.component';
import { SoftwareTechnologiesConsultingComponent } from './views/software-technologies-consulting/software-technologies-consulting.component';
import { SoftwareProductDevelopmentComponent } from './views/software-product-development/software-product-development.component';
import { ProductMaintenanceComponent } from './views/product-maintenance/product-maintenance.component';
import { QualityAssuranceComponent } from './views/quality-assurance/quality-assurance.component';
import { MobilitySolutionComponent } from './views/mobility-solution/mobility-solution.component';

@NgModule({
    declarations: [
        ManageItServicesComponent,
        SoftwareTechnologiesConsultingComponent,
        SoftwareProductDevelopmentComponent,
        ProductMaintenanceComponent,
        QualityAssuranceComponent,
        MobilitySolutionComponent,
    ],
    imports: [
        TranslateModule,
        HttpClientModule,
        CommonModule,
        FormsModule,
        TemplateModule,
        OurServicesRoutingModule,
    ],
    exports: [ManageItServicesComponent,SoftwareTechnologiesConsultingComponent,SoftwareProductDevelopmentComponent,ProductMaintenanceComponent,QualityAssuranceComponent,MobilitySolutionComponent,],
    providers: [],
    bootstrap: [ManageItServicesComponent,SoftwareTechnologiesConsultingComponent,SoftwareProductDevelopmentComponent,ProductMaintenanceComponent,QualityAssuranceComponent,MobilitySolutionComponent,]
})
export class OurServicesModule { }

    <div class="meet-our-team">
        <div class="container">
            <div class="heading-block text-center">
                <h3 class="heading-title">Meet Our Team</h3>
            </div>
            <div class="row">
                <div class="col-md-6 col-lg-3 mb-5">
                    <div class="team">
                        <div class="team-image">
                            <img src="assets/images/Ahmer.png" />
                        </div>
                        <div class="team-desc">
                            <div class="team-title"><h4>Ahmer Munir</h4><span>CEO</span></div>
                            <a href="#" class="social-icons-mini">
                                <div class="background-facebook-mini"><i class="fa fa-facebook" aria-hidden="true"></i></div>
                            </a>
                            <a href="#" class="social-icons-mini">
                                <div class="background-twitter-mini"><i class="fa fa-twitter" aria-hidden="true"></i></div>
                            </a>
                            <a href="#" class="social-icons-mini">
                                <div class="background-google-plus-mini"><i class="fa fa-google-plus" aria-hidden="true"></i></div>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 mb-5">
                    <div class="team">
                        <div class="team-image">
                            <img src="assets/images/aamir2.png" />
                        </div>
                        <div class="team-desc">
                            <div class="team-title"><h4>Aamir Munir</h4><span>Co-Founder</span></div>
                            <a href="#" class="social-icons-mini">
                                <div class="background-facebook-mini"><i class="fa fa-facebook" aria-hidden="true"></i></div>
                            </a>
                            <a href="#" class="social-icons-mini">
                                <div class="background-twitter-mini"><i class="fa fa-twitter" aria-hidden="true"></i></div>
                            </a>
                            <a href="#" class="social-icons-mini">
                                <div class="background-google-plus-mini"><i class="fa fa-google-plus" aria-hidden="true"></i></div>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 mb-5">
                    <div class="team">
                        <div class="team-image">
                            <img src="assets/images/Tajwer.jpeg" />
                        </div>
                        <div class="team-desc">
                            <div class="team-title"><h4>Tajwer Aftab</h4><span>Designer</span></div>
                            <a href="#" class="social-icons-mini">
                                <div class="background-facebook-mini"><i class="fa fa-facebook" aria-hidden="true"></i></div>
                            </a>
                            <a href="#" class="social-icons-mini">
                                <div class="background-twitter-mini"><i class="fa fa-twitter" aria-hidden="true"></i></div>
                            </a>
                            <a href="#" class="social-icons-mini">
                                <div class="background-google-plus-mini"><i class="fa fa-google-plus" aria-hidden="true"></i></div>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 mb-5">
                    <div class="team">
                        <div class="team-image">
                            <img src="assets/images/Amrin.jpg" />
                        </div>
                        <div class="team-desc">
                            <div class="team-title"><h4>Amrin Shaikh</h4><span>Quality Assurance</span></div>
                            <a href="#" class="social-icons-mini">
                                <div class="background-facebook-mini"><i class="fa fa-facebook" aria-hidden="true"></i></div>
                            </a>
                            <a href="#" class="social-icons-mini">
                                <div class="background-twitter-mini"><i class="fa fa-twitter" aria-hidden="true"></i></div>
                            </a>
                            <a href="#" class="social-icons-mini">
                                <div class="background-google-plus-mini"><i class="fa fa-google-plus" aria-hidden="true"></i></div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

import { NgModule } from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { SharedModule} from '../shared/shared.module';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule }   from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AdminComponent } from './admin/admin.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { TopNavbarComponent } from './topnavbar/topnavbar.component';
import { PageTitleComponent } from './pagetitle/page-title.component';
import { LetterIconComponent } from './letter-icon/letter-icon.component';
import { ListHeaderComponent } from './list-header/list-header.component';
import { PaginationComponent } from './pagination/pagination.component';
import { ContentMainComponent } from './content-main/content-main.component';
import { FormBottomComponent } from './form-bottom/form-bottom.component';

@NgModule({
  declarations: [
      HeaderComponent,
      FooterComponent,
      AdminComponent,
      SidebarComponent,
      NavbarComponent,
      TopNavbarComponent,
      PageTitleComponent,
      LetterIconComponent,
      ListHeaderComponent,
      PaginationComponent,
      ContentMainComponent,
      FormBottomComponent
  ],
  imports: [
        TranslateModule,
        HttpClientModule,
      CommonModule,
      SharedModule,
      RouterModule,
      FormsModule
  ],
  exports:[
      HeaderComponent,
      FooterComponent,
      AdminComponent,
      SidebarComponent,
      NavbarComponent,
      TopNavbarComponent,
      PageTitleComponent,
      LetterIconComponent,
      ListHeaderComponent,
      PaginationComponent,
      ContentMainComponent,
      FormBottomComponent
  ],
  providers: [],
  bootstrap: [
      HeaderComponent,
      FooterComponent,
      AdminComponent,
      SidebarComponent,
      NavbarComponent,
      TopNavbarComponent,
      PageTitleComponent,
      LetterIconComponent,
      ListHeaderComponent,
      PaginationComponent,
      ContentMainComponent,
      FormBottomComponent
  ]
})
export class TemplateModule { }

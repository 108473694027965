<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>Product Development</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <p class="mb-4">We have the expertise to develop a new product from ground level. We work with companies who are in different stages - from having just a vision of having detailed product architecture - and transform their work into finished products. We can design and build quality software products, whether your software need is a concept, or you have a fully documented design specification. We build products that incorporate all phases of well-designed, manageable software solutions including product definition, planning, design, coding, release management, quality assurance testing, training, deployment and support. Our offshore product development model delivers high quality, reliable and cost-effective Software Product Life cycle services.</p>
                    <h4 class="mb-4">We Offer Various Project Development Models</h4>
                    <p class="mb-4>"><b>On Project Basis :</b> If the Software requirements are clearly visible to client and scope is well defined where project duration is calculable, we provide comprehensive end-to-end software development services for the entire duration of the project.</p>
                    <p class="mb-4"><b>Offshore Development Center :</b> Projects that may continue for a period of 2-3 years with updates required periodically, we provide an Offshore Development Center (ODC) for the duration of the project.</p>
                    <p class="mb-4"><b>Offshore-Onshore Model :</b> Usually for technological software development, there is a need to keep in touch with the clients engineering team. In such cases our software development team is spread over regions: onshore at client's technology center, offshore at Indian Technologies Dreams development center.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row" >
    <div class="main-login col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2 col-md-4 col-md-offset-4 col-lg-4 col-lg-offset-4">
        <div class="logo text-center">
            <img src="{{CONFIG.IMAGE_URL}}/images/logo.png" alt="{{CONFIG.APP_NAME}}" class="img-responsive" />
        </div>
        <p class="text-center text-dark text-bold text-extra-large margin-top-15">{{"auth.view.welcomeMessage" | translate }}</p>
        <p class="text-center">{{"auth.view.passwordChanged" | translate }}</p>
        <p class="text-center">
            <span>{{"auth.view.useAccount" | translate }}</span>
            <a [routerLink]="[AUTH_CONSTANTS.URL.LOGIN]">{{"auth.view.logIn" | translate }}</a>
        </p>
    </div>
</div>
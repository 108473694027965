import { NgModule }             from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { OverviewComponent } from './views/overview/overview.component';
import { TechnologyComponent } from './views/technology/technology.component';

import { AuthGuard } from '../auth/services/auth.guard';

import { TECHNOLOGIES_CONSTANTS } from './constants';


const technologiesRoutes: Routes = [
    { path: TECHNOLOGIES_CONSTANTS.LINK.OVERVIEW, component: OverviewComponent },
    { path: TECHNOLOGIES_CONSTANTS.LINK.TECHNOLOGY, component: TechnologyComponent },
];

@NgModule({
    imports: [RouterModule.forChild(technologiesRoutes)],
    exports: [RouterModule]
})
export class TechnologiesRoutingModule {}

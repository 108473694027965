<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>Services Overview</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <p class="mb-4">Indian Technologies Dreams has a unique blend of web site programmers web site designers that ensures you quality work in quick time. Our web site design and development services guarantee hassle-free software development from conception to conceptualization at throw-away budget. As a result we have been blessed with a great base of clients that involve us in some exciting projects. Our growing list of clientele and proud portfolio suggest the same and vouch safe authentic service from expert and qualified professionals.</p>
                    <p class="mb-4">We, at Indian Technologies Dreams offer an array of web design services like <b>Web site Design, Software, Custom Web site development & Graphic Design Services</b> in order to enhance the corporate image of our clients. A strong web site must be developed with careful planning, which should be visually appealing spreading out its distinctive flavors and colors across the world. We believes in creations that not just only stand out due to its amiable look and matchless splendor, but can also effectively draw the target audience.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>Online Market Solution</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <p class="mb-4">Indian Technologies Dreams has designed, developed and deployed various B2B and B2C portals and market places. The contribution by the team at Indian Technologies Dreams is not restricted to choosing the technical stack and selecting various open source components to increase the efficacy of delivery. The business analysts at Indian Technologies Dreams lay a strong focus on providing consultancy around the selection of design elements to ensure maximum end user satisfaction. All the applications are designed keeping in mind the customer scalability and user experience needs.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>Interanet Solution</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <p class="mb-4">Intranet Solutions are offered in a variety of formats catering to individual requirements. We can integrate our solution into your existing Intranet, content management system or knowledge management portal, An effective intranet solution includes professional email, document management, project management, calendaring, and contact management tools.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>Desktop Solution</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <p class="mb-4">Applications running in a desktop (or laptop) computer in contrast to Web-based applications over the Internet. Since the advent of the Web, developers have been trying to make Web applications as interactive and responsive as applications running within the client locally or in a client/server environment over the local network</p>
                </div>
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>Mobile Solution</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <p class="mb-4">Indian Technologies Dreams has wide experience in Mobile Application Development. We provide Mobile Application Development platform including Windows Mobile Application, Mobile enabled website development, J2ME based Mobile Application Development on Nokia, Samsung, LG phones, Android - Google mobile hosted solution, iPhone Mobile Website Development, iPhone Mobile Application Development, we helps clients to realize the benefits of Enterprise Mobile Application Development Platform.</p>
                    <p class="mb-4">Indian Technologies Dreams offshore cost and scalability advantage to significantly reduce development cost across various mobile Application Developments. We provide high quality, time bound, cost effective outsourcing IT services through our offshore facilities in India.</p>
                    <p class="mb-4">As the businesses are growing far and wide, the need for proper Communication with the field executives has increased by many folds. The need to connect to people at all times is rapidly growing now. Deals click in an instant provided if right information is available at the right place, no matter where this place is.</p>
                    <p class="mb-4">That means Information needs to be omnipresent today which is possible now through instant access to real-time data using Wireless/ Mobile Solutions. Semaphore has developed expertise in Mobile Application Development that have helped companies improve workflow, customer order taking, and record keeping in the front office.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>CMS Based Websites</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <p class="mb-4">All of the aforementioned sites host a database and a Content Management System (CMS) to manage the contents i.e. text and images of these website just the way you would want wish to manage various sections on your website. We have designed and continually maintain several corporate websites and can surely look at designing and developing one for you.</p>
                    <h4 class="mb-4">Benefits of CMS</h4>
                    <ul>
                        <li>Our customized CMS solutions result in better performance, save your precious time and money. You don't need to have extra staff for making regular changes and management of your website - and editing of content will become just simple as typing a document and saving it - similar to the MS word.</li>
                        <li>Our tailored made CMS systems keep your website up-to-date that results in competitive advantage and you can gain leadership in your Internet presence that in turns give prosperity in your business.</li>
                        <li>Our well coupled CMS solutions help you to manage website navigation, website structure, dynamic elements, content, tables and web-forms.</li>
                    </ul>
                    <p class="mb-4">The Content Management System is a web based software application that helps you to manage your website content with great ease through a web based browser application. We at Indian Technologies Dreams, offer you a choice between our Standard Open Source CMS Solution and Professional CMS Solution. We have the expertise and knowledge in various CMS like Drupal, Jhoomla etc, which gives us the flexibility to provide you with solutions and a professional user interface according to your requirements.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>Web Frontend Development Technologies</h1>
            <span>Our Engagement model is according to customer preferences like :</span>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-5 img-col"><img class="obj-contain" src="assets/images/frontend-technologies/angular.png"></div>
                <div class="col-md-7 text-center text-md-left content-col">
                    <div class="mb-5">
                        <h4>Angular</h4>
                        <span>Reports let you easily track and analyze your product sales, orders, and payments.</span>
                    </div>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero quod consequuntur quibusdam, enim expedita sed quia nesciunt incidunt accusamus necessitatibus modi adipisci officia libero accusantium esse hic, obcaecati, ullam, laboriosam!</p>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti vero, animi suscipit id facere officia. Aspernatur, quo, quos nisi dolorum aperiam fugiat deserunt velit rerum laudantium cum magnam excepturi quod.</p>
                    <a (click)="callAngular();" class="btn btn-outline-dark">Learn more</a>
                </div>
            </div>
            <div class="vertical-line"></div>
            <div class="row align-items-center">
                <div class="col-md-5 img-col d-block d-md-none"><img class="obj-contain" src="assets/images/frontend-technologies/jquery.jpeg"></div>
                <div class="col-md-7 text-center text-md-left content-col">
                    <div class="mb-5">
                        <h4>JQuery</h4>
                        <span>Reports let you easily track and analyze your product sales, orders, and payments.</span>
                    </div>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero quod consequuntur quibusdam, enim expedita sed quia nesciunt incidunt accusamus necessitatibus modi adipisci officia libero accusantium esse hic, obcaecati, ullam, laboriosam!</p>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti vero, animi suscipit id facere officia. Aspernatur, quo, quos nisi dolorum aperiam fugiat deserunt velit rerum laudantium cum magnam excepturi quod.</p>
                    <a (click)="callJquery();" class="btn btn-outline-dark">Learn more</a>
                </div>
                <div class="col-md-5 img-col d-none d-md-block"><img class="obj-contain" src="assets/images/frontend-technologies/jquery.jpeg"></div>
            </div>
            <div class="vertical-line"></div>
            <div class="row align-items-center">
                <div class="col-md-5 img-col"><img class="obj-contain" src="assets/images/frontend-technologies/bootstrap.jpeg"></div>
                <div class="col-md-7 text-center text-md-left content-col">
                    <div class="mb-5">
                        <h4>Bootstrap</h4>
                        <span>Reports let you easily track and analyze your product sales, orders, and payments.</span>
                    </div>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero quod consequuntur quibusdam, enim expedita sed quia nesciunt incidunt accusamus necessitatibus modi adipisci officia libero accusantium esse hic, obcaecati, ullam, laboriosam!</p>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti vero, animi suscipit id facere officia. Aspernatur, quo, quos nisi dolorum aperiam fugiat deserunt velit rerum laudantium cum magnam excepturi quod.</p>
                    <a (click)="callBootstrap();" class="btn btn-outline-dark">Learn more</a>
                </div>
            </div>
            <div class="vertical-line"></div>
            <div class="row align-items-center">
                <div class="col-md-5 img-col d-block d-md-none"><img class="obj-contain" src="assets/images/frontend-technologies/css-html.png"></div>
                <div class="col-md-7 text-center text-md-left content-col">
                    <div class="mb-5">
                        <h4>CSS & html</h4>
                        <span>Reports let you easily track and analyze your product sales, orders, and payments.</span>
                    </div>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero quod consequuntur quibusdam, enim expedita sed quia nesciunt incidunt accusamus necessitatibus modi adipisci officia libero accusantium esse hic, obcaecati, ullam, laboriosam!</p>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti vero, animi suscipit id facere officia. Aspernatur, quo, quos nisi dolorum aperiam fugiat deserunt velit rerum laudantium cum magnam excepturi quod.</p>
                    <a (click)="callCssHtml();" class="btn btn-outline-dark">Learn more</a>
                </div>
                <div class="col-md-5 img-col d-none d-md-block"><img class="obj-contain" src="assets/images/frontend-technologies/css-html.png"></div>
            </div>
            <div class="vertical-line"></div>
            <div class="row align-items-center">
                <div class="col-md-5 img-col"><img class="obj-cover" src="assets/images/frontend-technologies/vue.jpeg"></div>
                <div class="col-md-7 text-center text-md-left content-col">
                    <div class="mb-5">
                        <h4>Vue & NUXT</h4>
                        <span>Reports let you easily track and analyze your product sales, orders, and payments.</span>
                    </div>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero quod consequuntur quibusdam, enim expedita sed quia nesciunt incidunt accusamus necessitatibus modi adipisci officia libero accusantium esse hic, obcaecati, ullam, laboriosam!</p>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti vero, animi suscipit id facere officia. Aspernatur, quo, quos nisi dolorum aperiam fugiat deserunt velit rerum laudantium cum magnam excepturi quod.</p>
                    <a (click)="callVueNuxt();" class="btn btn-outline-dark">Learn more</a>
                </div>
            </div>
            <div class="vertical-line"></div>
            <div class="row align-items-center">
                <div class="col-md-5 img-col d-block d-md-none"><img class="obj-contain" src="assets/images/frontend-technologies/react.png"></div>
                <div class="col-md-7 text-center text-md-left content-col">
                    <div class="mb-5">
                        <h4>React</h4>
                        <span>Reports let you easily track and analyze your product sales, orders, and payments.</span>
                    </div>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero quod consequuntur quibusdam, enim expedita sed quia nesciunt incidunt accusamus necessitatibus modi adipisci officia libero accusantium esse hic, obcaecati, ullam, laboriosam!</p>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti vero, animi suscipit id facere officia. Aspernatur, quo, quos nisi dolorum aperiam fugiat deserunt velit rerum laudantium cum magnam excepturi quod.</p>
                    <a (click)="callReact();" class="btn btn-outline-dark">Learn more</a>
                </div>
                <div class="col-md-5 img-col d-none d-md-block"><img class="obj-contain" src="assets/images/frontend-technologies/react.png"></div>
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>Engagement Model</h1>
            <span>Our Engagement model is according to customer preferences like :</span>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-5 img-col"><img src="assets/images/engagement-model/fixed-price.png"></div>
                <div class="col-md-7 text-center text-md-left content-col">
                    <div class="mb-5">
                        <h4>Fixed Price</h4>
                        <span>Reports let you easily track and analyze your product sales, orders, and payments.</span>
                    </div>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero quod consequuntur quibusdam, enim expedita sed quia nesciunt incidunt accusamus necessitatibus modi adipisci officia libero accusantium esse hic, obcaecati, ullam, laboriosam!</p>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti vero, animi suscipit id facere officia. Aspernatur, quo, quos nisi dolorum aperiam fugiat deserunt velit rerum laudantium cum magnam excepturi quod.</p>
                    <a (click)="callFixedPrice();" class="btn btn-outline-dark">Learn more</a>
                </div>
            </div>
            <div class="vertical-line"></div>
            <div class="row align-items-center">
                <div class="col-md-5 img-col d-block d-md-none"><img src="assets/images/engagement-model/time-material.png"></div>
                <div class="col-md-7 text-center text-md-left content-col">
                    <div class="mb-5">
                        <h4>Time & Material</h4>
                        <span>Reports let you easily track and analyze your product sales, orders, and payments.</span>
                    </div>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero quod consequuntur quibusdam, enim expedita sed quia nesciunt incidunt accusamus necessitatibus modi adipisci officia libero accusantium esse hic, obcaecati, ullam, laboriosam!</p>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti vero, animi suscipit id facere officia. Aspernatur, quo, quos nisi dolorum aperiam fugiat deserunt velit rerum laudantium cum magnam excepturi quod.</p>
                    <a (click)="callTimeMaterial();" class="btn btn-outline-dark">Learn more</a>
                </div>
                <div class="col-md-5 img-col d-none d-md-block"><img src="assets/images/engagement-model/time-material.png"></div>
            </div>
            <div class="vertical-line"></div>
            <div class="row align-items-center">
                <div class="col-md-5 img-col"><img src="assets/images/engagement-model/hybrid.png"></div>
                <div class="col-md-7 text-center text-md-left content-col">
                    <div class="mb-5">
                        <h4>Hybrid</h4>
                        <span>Reports let you easily track and analyze your product sales, orders, and payments.</span>
                    </div>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero quod consequuntur quibusdam, enim expedita sed quia nesciunt incidunt accusamus necessitatibus modi adipisci officia libero accusantium esse hic, obcaecati, ullam, laboriosam!</p>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti vero, animi suscipit id facere officia. Aspernatur, quo, quos nisi dolorum aperiam fugiat deserunt velit rerum laudantium cum magnam excepturi quod.</p>
                    <a (click)="callHybrid();" class="btn btn-outline-dark">Learn more</a>
                </div>
            </div>
            <div class="vertical-line"></div>
            <div class="row align-items-center">
                <div class="col-md-5 img-col d-block d-md-none">
                    <div class="row bot-images">
                        <div class="col-sm-12"><img src="assets/images/engagement-model/build.png"></div>
                        <div class="col-sm-12"><img src="assets/images/engagement-model/operate.png"></div>
                        <div class="col-sm-12"><img src="assets/images/engagement-model/transfer.png"></div>
                    </div>
                </div>
                <div class="col-md-7 text-center text-md-left content-col">
                    <div class="mb-5">
                        <h4>BOT</h4>
                        <span>Reports let you easily track and analyze your product sales, orders, and payments.</span>
                    </div>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero quod consequuntur quibusdam, enim expedita sed quia nesciunt incidunt accusamus necessitatibus modi adipisci officia libero accusantium esse hic, obcaecati, ullam, laboriosam!</p>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti vero, animi suscipit id facere officia. Aspernatur, quo, quos nisi dolorum aperiam fugiat deserunt velit rerum laudantium cum magnam excepturi quod.</p>
                    <a (click)="callBot();" class="btn btn-outline-dark">Learn more</a>
                </div>
                <div class="col-md-5 img-col d-none d-md-block">
                    <div class="row bot-images">
                        <div class="col-sm-12 text-left"><img src="assets/images/engagement-model/build.png"></div>
                        <div class="col-sm-12"><img src="assets/images/engagement-model/operate.png"></div>
                        <div class="col-sm-12 text-right"><img src="assets/images/engagement-model/transfer.png"></div>
                    </div>
                </div>
            </div>
            <div class="vertical-line"></div>
            <div class="row align-items-center">
                <div class="col-md-5 img-col"><img src="assets/images/engagement-model/co-work.png"></div>
                <div class="col-md-7 text-center text-md-left content-col">
                    <div class="mb-5">
                        <h4>Co-work</h4>
                        <span>Reports let you easily track and analyze your product sales, orders, and payments.</span>
                    </div>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero quod consequuntur quibusdam, enim expedita sed quia nesciunt incidunt accusamus necessitatibus modi adipisci officia libero accusantium esse hic, obcaecati, ullam, laboriosam!</p>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti vero, animi suscipit id facere officia. Aspernatur, quo, quos nisi dolorum aperiam fugiat deserunt velit rerum laudantium cum magnam excepturi quod.</p>
                    <a (click)="callCoWork();" class="btn btn-outline-dark">Learn more</a>
                </div>
            </div>
            <div class="vertical-line"></div>
            <div class="row align-items-center">
                <div class="col-md-5 img-col d-block d-md-none"><img src="assets/images/engagement-model/agile.png"></div>
                <div class="col-md-7 text-center text-md-left content-col">
                    <div class="mb-5">
                        <h4>Agile</h4>
                        <span>Reports let you easily track and analyze your product sales, orders, and payments.</span>
                    </div>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero quod consequuntur quibusdam, enim expedita sed quia nesciunt incidunt accusamus necessitatibus modi adipisci officia libero accusantium esse hic, obcaecati, ullam, laboriosam!</p>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti vero, animi suscipit id facere officia. Aspernatur, quo, quos nisi dolorum aperiam fugiat deserunt velit rerum laudantium cum magnam excepturi quod.</p>
                    <a (click)="callAgile();" class="btn btn-outline-dark">Learn more</a>
                </div>
                <div class="col-md-5 img-col d-none d-md-block"><img src="assets/images/engagement-model/agile.png"></div>
            </div>
        </div>
    </div>
</div>
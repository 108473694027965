export const SERVICES_CONSTANTS = {
    URL: {
        SERVICES_OVERVIEW: '/services/overview',
        SERVICES_ONLINE_MARKET: '/services/online-market',
        SERVICES_INTERANET_SOLUTION: '/services/interanet-solution',
        SERVICES_TESTING_SERVICES: '/services/testing-services',
        SERVICES_DESKTOP_SOLUTION: '/services/desktop-solution',
        SERVICES_CMS_IMPLEMENT: '/services/cms-implement',
        SERVICES_CMS_WEBSITE: '/services/cms-website',
        SERVICES_CODE_IMPROVEMENT: '/services/code-improvement',
        SERVICES_CODE_DOCUMENTATION: '/services/code-documentation',
        SERVICES_PRODUCT_DEVELOPMENT: '/services/product-development',
        SERVICES_NETWORKING_COMMUNITY: '/services/networking-community',
        SERVICES_MOBILE_SOLUTION: '/services/mobile-solution',
        SERVICES_DATA_PROCESSING: '/services/data-processing',
    },
    LINK: {
        OVERVIEW: 'overview',
        ONLINE_MARKET: 'online-market',
        INTERANET_SOLUTION: 'interanet-solution',
        TESTING_SERVICES: 'testing-services',
        DESKTOP_SOLUTION: 'desktop-solution',
        CMS_IMPLEMENT: 'cms-implement',
        CMS_WEBSITE: 'cms-website',
        CODE_IMPROVEMENT: 'code-improvement',
        CODE_DOCUMENTATION: 'code-documentation',
        PRODUCT_DEVELOPMENT: 'product-development',
        NETWORKING_COMMUNITY: 'networking-community',
        MOBILE_SOLUTION: 'mobile-solution',
        DATA_PROCESSING: 'data-processing',
    },
    LABEL:{
        OVERVIEW: 'Overview',
        ONLINE_MARKET: 'Online Market Solution',
        INTERANET_SOLUTION: 'Interanet Solution',
        TESTING_SERVICES: 'Testing Services',
        DESKTOP_SOLUTION: 'Desktop Solution',
        CMS_IMPLEMENT: 'CMS Implementation',
        CMS_WEBSITE: 'CMS Based Websites',
        CODE_IMPROVEMENT: 'Code Improvement',
        CODE_DOCUMENTATION: 'Code Documentation',
        PRODUCT_DEVELOPMENT: 'Product Development',
        NETWORKING_COMMUNITY: 'Networking & Community',
        MOBILE_SOLUTION: 'Mobile Solution',
        DATA_PROCESSING: 'Data Processing',
    },
    API:{

    }
};
<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>Python</h1>
            <span>Python Application.</span>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 img-col"><img src="assets/images/web-technologies/python.png"></div>
                <div class="col-md-12 text-center text-md-left content-col pt-0">
                    <p class="mb-4">A powerful programming technology which we use to deliver rich front-end solutions, robust backends, and accelerated computing.</p>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti vero, animi suscipit id facere officia. Aspernatur, quo, quos nisi dolorum aperiam fugiat deserunt velit rerum laudantium cum magnam excepturi quod.</p>
                </div>
            </div>
        </div>
    </div>
</div>
export const ABOUT_US_CONSTANTS = {
    URL: {
        ABOUT_US_OVERVIEW: '/about-us/overview',
        ABOUT_US_VISION_MISSION: '/about-us/vision-mission',
        ABOUT_US_MANAGEMENT_TEAM: '/about-us/management-team',
    },
    LINK: {
        OVERVIEW: 'overview',
        VISION_MISSION: 'vision-mission',
        MANAGEMENT_TEAM: 'management-team',
    },
    LABEL:{
        OVERVIEW: 'Overview',
        VISION_MISSION: 'Vision & Mission',
        MANAGEMENT_TEAM: 'Management Team',
    },
    API:{

    }
};
import { NgModule }            from '@angular/core';
import { CommonModule }        from '@angular/common';
import {TranslateModule}       from '@ngx-translate/core';
import {HttpClientModule,HttpClient} from '@angular/common/http';
import { FormsModule }   from '@angular/forms';

import { TemplateModule} from '../template/template.module';
import { ServicesRoutingModule }   from './services-routing.module';

import { OverviewComponent } from './views/overview/overview.component';
import { OnlineMarketComponent } from './views/online-market/online-market.component';
import { InteranetSolutionComponent } from './views/interanet-solution/interanet-solution.component';
import { TestingServicesComponent } from './views/testing-services/testing-services.component';
import { DesktopSolutionComponent } from './views/desktop-solution/desktop-solution.component';
import { CmsImplementComponent } from './views/cms-implement/cms-implement.component';
import { CmsWebsiteComponent } from './views/cms-website/cms-website.component';
import { CodeImprovementComponent } from './views/code-improvement/code-improvement.component';
import { CodeDocumentationComponent } from './views/code-documentation/code-documentation.component';
import { ProductDevelopmentComponent } from './views/product-development/product-development.component';
import { NetworkingCommunityComponent } from './views/networking-community/networking-community.component';
import { MobileSolutionComponent } from './views/mobile-solution/mobile-solution.component';
import { DataProcessingComponent } from './views/data-processing/data-processing.component';


@NgModule({
    declarations: [
        OverviewComponent,
        OnlineMarketComponent,
        InteranetSolutionComponent,
        TestingServicesComponent,
        DesktopSolutionComponent,
        CmsImplementComponent,
        CmsWebsiteComponent,
        CodeImprovementComponent,
        CodeDocumentationComponent,
        ProductDevelopmentComponent,
        NetworkingCommunityComponent,
        MobileSolutionComponent,
        DataProcessingComponent,
    ],
    imports: [
        TranslateModule,
        HttpClientModule,
        CommonModule,
        FormsModule,
        TemplateModule,
        ServicesRoutingModule,
    ],
    exports: [OverviewComponent,OnlineMarketComponent,InteranetSolutionComponent,TestingServicesComponent,DesktopSolutionComponent,CmsImplementComponent,CmsWebsiteComponent,CodeImprovementComponent,CodeDocumentationComponent,ProductDevelopmentComponent,NetworkingCommunityComponent,MobileSolutionComponent,DataProcessingComponent,],
    providers: [],
    bootstrap: [OverviewComponent,OnlineMarketComponent,InteranetSolutionComponent,TestingServicesComponent,DesktopSolutionComponent,CmsImplementComponent,CmsWebsiteComponent,CodeImprovementComponent,CodeDocumentationComponent,ProductDevelopmentComponent,NetworkingCommunityComponent,MobileSolutionComponent,DataProcessingComponent,]
})
export class ServicesModule { }

import { NgModule }            from '@angular/core';
import { CommonModule }        from '@angular/common';
import {TranslateModule}       from '@ngx-translate/core';
import {HttpClientModule,HttpClient} from '@angular/common/http';
import { FormsModule }   from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { ScreenLockComponent } from './views/screen-lock/screen-lock.component';
import { NotConfirmedComponent } from './views/not-confirmed/not-confirmed.component';
import { ConfirmedComponent } from './views/confirmed/confirmed.component';
import { ForgotPasswordConfirmedComponent } from './views/forgot-password-confirmed/forgot-password-confirmed.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { RegisteredComponent } from './views/registered/registered.component';
import { PasswordResetComponent } from './views/password-reset/password-reset.component';
import { ForgotHelpModalComponent } from './views/forgot-help-modal/forgot-help-modal.component';
import { PrivacyContentModalComponent } from './views/privacy-content-modal/privacy-content-modal.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';

import { AuthRoutingModule }   from './auth-routing.module';
import { SharedModule }   from '../shared/shared.module';

import { UserFeatureService }   from './services/user-features.service';
import { AuthGuard }   from './services/auth.guard';




@NgModule({
  declarations: [
      LoginComponent,
      RegisterComponent,
      ScreenLockComponent,
      NotConfirmedComponent,
      ConfirmedComponent,
      ForgotPasswordConfirmedComponent,
      RegisteredComponent,
      PasswordResetComponent,
      ForgotPasswordComponent,
      ForgotHelpModalComponent,
      PrivacyContentModalComponent,
      ResetPasswordComponent
  ],
  imports: [
      TranslateModule,
      HttpClientModule,
      CommonModule,
      FormsModule,
      AuthRoutingModule,
      SharedModule
  ],
  exports:[
      LoginComponent,
      RegisterComponent,
      ScreenLockComponent,
      NotConfirmedComponent,
      ConfirmedComponent,
      ForgotPasswordConfirmedComponent,
      RegisteredComponent,
      PasswordResetComponent,
      ForgotPasswordComponent,
      ForgotHelpModalComponent,
      PrivacyContentModalComponent,
      ResetPasswordComponent
  ],
  providers: [AuthGuard,UserFeatureService],
  bootstrap: [
      LoginComponent,
      RegisterComponent,
      ScreenLockComponent,
      NotConfirmedComponent,
      ConfirmedComponent,
      ForgotPasswordConfirmedComponent,
      RegisteredComponent,
      PasswordResetComponent,
      ForgotPasswordComponent,
      ForgotHelpModalComponent,
      PrivacyContentModalComponent,
      ResetPasswordComponent
  ]
})
export class AuthModule { }

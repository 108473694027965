
    <div class="home-page-bottom-carousel">
        <div class="container">
            <div class="row">
                <div id="owl-carousel-homepage" class="owl-carousel owl-theme">
                    <div class="col">
                        <div class="item img-block">
                            <img class="bottom-logo-img-carousel" src="assets/images/products/Logo-amaal-e-momineen.png" alt="amaal-e-momineen">
                        </div>
                    </div>
                    <div class="col">
                        <div class="item img-block">
                            <img class="bottom-logo-img-carousel" src="assets/images/products/Logo-conglu-payroll.png" alt="conglu-payroll">
                        </div>
                    </div>
                    <div class="col">
                        <div class="item img-block">
                            <img class="bottom-logo-img-carousel" src="assets/images/products/Logo-conglutinate-dreams.png" alt="conglutinate-dreams">
                        </div>
                    </div>
                    <div class="col">
                        <div class="item img-block">
                            <img class="bottom-logo-img-carousel" src="assets/images/products/Logo-dreams-online-test.png" alt="dreams-online-test">
                        </div>
                    </div>
                    <div class="col">
                        <div class="item img-block">
                            <img class="bottom-logo-img-carousel" src="assets/images/products/Logo-occations-dreams.png" alt="occations-dreams">
                        </div>
                    </div>
                    <div class="col">
                        <div class="item img-block">
                            <img class="bottom-logo-img-carousel" src="assets/images/products/Logo-pack-move-dreams.png" alt="pack-move-dreams">
                        </div>
                    </div>
                    <div class="col">
                        <div class="item img-block">
                            <img class="bottom-logo-img-carousel" src="assets/images/products/Logo-shape-education.png" alt="shape-education">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

import { NgModule }            from '@angular/core';
import { CommonModule }        from '@angular/common';
import {TranslateModule}       from '@ngx-translate/core';
import {HttpClientModule,HttpClient} from '@angular/common/http';
import { FormsModule }   from '@angular/forms';
import { TemplateModule} from '../template/template.module';
import { WebTechnologiesRoutingModule }   from './web-technologies-routing.module';
import { JavaComponent } from './views/java/java.component';
import { PhpComponent } from './views/php/php.component';
import { RubyComponent } from './views/ruby/ruby.component';
import { CSharpComponent } from './views/c-sharp/c-sharp.component';
import { PythonComponent } from './views/python/python.component';
import { NodeJsComponent } from './views/node-js/node-js.component';


@NgModule({
    declarations: [
        JavaComponent,
        PhpComponent,
        RubyComponent,
        CSharpComponent,
        PythonComponent,
        NodeJsComponent,
    ],
    imports: [
        TranslateModule,
        HttpClientModule,
        CommonModule,
        FormsModule,
        TemplateModule,
        WebTechnologiesRoutingModule,
    ],
    exports: [JavaComponent,PhpComponent,RubyComponent,CSharpComponent,PythonComponent,NodeJsComponent,],
    providers: [],
    bootstrap: [JavaComponent,PhpComponent,RubyComponent,CSharpComponent,PythonComponent,NodeJsComponent,]
})
export class WebTechnologiesModule { }

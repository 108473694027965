<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>Code Improvement</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <p class="mb-4">Running PMD against a code base is quite easy . Upon examining the code, PMD will produce a report in various forms, such as XML and HTML, that describes the various customizable infractions found.</p>
                    <h4 class="my-4">Code Improvement Project will:</h4>
                    <ul>
                        <li>Improve the Code's effectiveness;</li>
                        <li>Reduce uncertainty in reading the code; and</li>
                        <li>Increase consistent interpretations and applications of the code</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
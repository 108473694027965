<router-outlet></router-outlet>
<div class="home-section">
    <home-carousel></home-carousel>
    <homeHowItWorks></homeHowItWorks>
    <homeOurWork></homeOurWork>
    <homeMoreRich></homeMoreRich>
    <homeFeaturesOptimization></homeFeaturesOptimization>
    <homeClientSay></homeClientSay>
    <homeBlog></homeBlog>
    <homeMeetTeam></homeMeetTeam>
    <homeBottomCarousel></homeBottomCarousel>
</div>
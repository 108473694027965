<div class="row">
    <div class="lock-screen">
        <div class="box-ls">
            <img alt="" src="/images/avatar-1-xl.jpg" class="img-rounded" />
            <div class="user-info">
                <h4>{{currentUser.firstName}} {{currentUser.lastName}}</h4>
                <span>{{currentUser.email}}</span>
                <form class="form-login" name="loginForm" role="form" (ngSubmit)="f.form.valid && unlock()" #f="ngForm" novalidate>
                    <div class="input-group" >
                        <input autocomplete="false" type="password" placeholder="{{'auth.view.screenLock' | translate}}" class="form-control password" name="password" [(ngModel)]="user.password" #password="ngModel" required>
                        <span class="input-group-btn">
                            <button class="btn btn-red" type="submit">
                                <i class="fa fa-chevron-right"></i>
                            </button>
                        </span>
                    </div>
                    <div class="input-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
                        <span class="error text-small block" *ngIf="f.submitted && !password.valid">{{"login.error.password" | translate}}</span>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import { Title }     from '@angular/platform-browser';
import {Router} from '@angular/router';
import { APP_CONFIG } from '../../../constants';
import { MAIN_CONSTANTS } from '../../constants';
import { MOBILE_TECHNOLOGIES_CONSTANTS } from '../../../mobile-technologies/constants';

@Component({
  selector: 'mobile-technologies-root',
  templateUrl: './mobile-technologies.component.html',
  styleUrls: []
})
export class MobileTechnologiesComponent {
    CONFIG=APP_CONFIG;
    MAIN_CONSTANTS=MAIN_CONSTANTS;
    constructor(private titleService: Title, private router: Router) {
        this.CONFIG=APP_CONFIG;
        this.MAIN_CONSTANTS=MAIN_CONSTANTS;
        this.titleService.setTitle( APP_CONFIG.APP_NAME+ " :: "+ this.MAIN_CONSTANTS.LABEL.MOBILE_TECHNOLOGIES);
    }

    callNative() {
        this.router.navigate([MOBILE_TECHNOLOGIES_CONSTANTS.URL.MOBILE_TECHNOLOGIES_NATIVE]);
    }
    callXamarin() {
        this.router.navigate([MOBILE_TECHNOLOGIES_CONSTANTS.URL.MOBILE_TECHNOLOGIES_XAMARIN]);
    }
    callTitanium() {
        this.router.navigate([MOBILE_TECHNOLOGIES_CONSTANTS.URL.MOBILE_TECHNOLOGIES_TITANIUM_HYBRID]);
    }
    callIonic() {
        this.router.navigate([MOBILE_TECHNOLOGIES_CONSTANTS.URL.MOBILE_TECHNOLOGIES_IONIC]);
    }
    callReactNative() {
        this.router.navigate([MOBILE_TECHNOLOGIES_CONSTANTS.URL.MOBILE_TECHNOLOGIES_REACT_NATIVE]);
    }
    callFramework7() {
        this.router.navigate([MOBILE_TECHNOLOGIES_CONSTANTS.URL.MOBILE_TECHNOLOGIES_FRAMEWORK_7]);
    }

}
import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import { Title }     from '@angular/platform-browser';
import {Router} from '@angular/router';
import { APP_CONFIG } from '../../../constants';
import { MAIN_CONSTANTS } from '../../constants';
import { FRONTEND_TECHNOLOGIES_CONSTANTS } from '../../../frontend-technologies/constants';

@Component({
  selector: 'frontend-technologies-root',
  templateUrl: './frontend-technologies.component.html',
  styleUrls: []
})
export class FrontendTechnologiesComponent {
    CONFIG=APP_CONFIG;
    MAIN_CONSTANTS=MAIN_CONSTANTS;

    constructor(private titleService: Title, private router: Router) {
        this.CONFIG=APP_CONFIG;
        this.MAIN_CONSTANTS=MAIN_CONSTANTS;
        this.titleService.setTitle( APP_CONFIG.APP_NAME+ " :: "+ this.MAIN_CONSTANTS.LABEL.FRONTEND_TECHNOLOGIES);
    }

    callAngular() {
        this.router.navigate([FRONTEND_TECHNOLOGIES_CONSTANTS.URL.FRONTEND_TECHNOLOGIES_ANGULAR]);
    }
    callJquery() {
        this.router.navigate([FRONTEND_TECHNOLOGIES_CONSTANTS.URL.FRONTEND_TECHNOLOGIES_JQUERY]);
    }
    callBootstrap() {
        this.router.navigate([FRONTEND_TECHNOLOGIES_CONSTANTS.URL.FRONTEND_TECHNOLOGIES_BOOTSTRAP]);
    }
    callCssHtml() {
        this.router.navigate([FRONTEND_TECHNOLOGIES_CONSTANTS.URL.FRONTEND_TECHNOLOGIES_CSS_HTML]);
    }
    callVueNuxt() {
        this.router.navigate([FRONTEND_TECHNOLOGIES_CONSTANTS.URL.FRONTEND_TECHNOLOGIES_VUE_NUXT]);
    }
    callReact() {
        this.router.navigate([FRONTEND_TECHNOLOGIES_CONSTANTS.URL.FRONTEND_TECHNOLOGIES_REACT]);
    }

}
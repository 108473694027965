import { NgModule }            from '@angular/core';
import { CommonModule }        from '@angular/common';
import {TranslateModule}       from '@ngx-translate/core';
import {HttpClientModule,HttpClient} from '@angular/common/http';
import { FormsModule }   from '@angular/forms';
import { TemplateModule} from '../template/template.module';
import { MobileTechnologiesRoutingModule }   from './mobile-technologies-routing.module';
import { NativeComponent } from './views/native/native.component';
import { XamarinComponent } from './views/xamarin/xamarin.component';
import { TitaniumHybridComponent } from './views/titanium-hybrid/titanium-hybrid.component';
import { IonicComponent } from './views/ionic/ionic.component';
import { ReactNativeComponent } from './views/react-native/react-native.component';
import { Framework7Component } from './views/framework-7/framework-7.component';

@NgModule({
    declarations: [
        NativeComponent,
        XamarinComponent,
        TitaniumHybridComponent,
        IonicComponent,
        ReactNativeComponent,
        Framework7Component,
    ],
    imports: [
        TranslateModule,
        HttpClientModule,
        CommonModule,
        FormsModule,
        TemplateModule,
        MobileTechnologiesRoutingModule,
    ],
    exports: [NativeComponent,XamarinComponent,TitaniumHybridComponent,IonicComponent,ReactNativeComponent,Framework7Component,],
    providers: [],
    bootstrap: [NativeComponent,XamarinComponent,TitaniumHybridComponent,IonicComponent,ReactNativeComponent,Framework7Component,]
})
export class MobileTechnologiesModule { }

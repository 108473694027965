<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>Quality</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <p class="mb-4">Quality, like integrity, is not negotiable. These were the words of Mr. Ajmal Pasha when Indian Technologies Dreams. launched Feb 2009. It re-iterated the ruthless and uncompromising approach that the organization follows towards quality.</p>
                    <p class="mb-4">Our Quality Assurance Processes combine the best of standard software engineering processes to deliver predictable quality to the client. Our engineering processes are constantly improving so that we produce quality software, faster. We constantly implement best practices that are adopted or recognized by the industry so as to ensure maximum value addition to our client engagements and Lighting comes under an umbrella called "Mission Quality". The word "Mission" is consciously introduced and repeatedly stressed upon, to communicate the missionary zeal that was required to achieve a paradigm shift in our approach to quality. The philosophy was to make quality everybody's business - not just in products but in every aspect of work.</p>
                    <p class="mb-4">We have grown both organically and through acquisitions, customer centric. Our Quality is embedded at all manufacturing and distribution facilities. At the operational level, building in a culture of Quality consciousness is realized through ISO certification for processes and product certification for critical products.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- start: TOP NAVBAR -->
<header class="navbar navbar-default navbar-static-top hidden-print">
    <!-- start: NAVBAR HEADER -->
    <div class="navbar-header">
        <a class="navbar-brand" [routerLink]="[MAIN_CONSTANTS.URL.DASHBOARD]"> <img src="{{CONFIG.IMAGE_URL}}/images/logo.png" alt="{{CONFIG.APP_NAME}}"  width="120"/> </a>
    </div>
    <!-- end: NAVBAR HEADER -->
    <!-- start: NAVBAR COLLAPSE -->
    <div class="navbar-collapse collapse" uib-collapse="navbarCollapsed" ng-init="navbarCollapsed = true" off-click="navbarCollapsed = true" off-click-if='!navbarCollapsed' off-click-filter="'#menu-toggler'">
        <ul class="nav navbar-left hidden-sm hidden-xs">
            <li class="sidebar-toggler-wrapper">
                <div>
                    <button href="javascript:void(0)" class="btn sidebar-toggler visible-md visible-lg" v-pressable>
                        <i class="fa fa-bars"></i>
                    </button>
                </div>
            </li>
            <li>
                <a ng-click="goFullscreen()"> <i class="fa fa-expand"></i> <!--<i class="fa fa-compress" [hidden]="!isFullscreen"></i>--></a>
            </li>
            <li>
                <form role="search" class="navbar-form main-search">
                    <div class="form-group">
                        <input type="text" placeholder="{{ 'search.PLACEHOLDER' | translate }}" class="form-control">
                        <button class="btn search-button" type="submit">
                            <i class="fa fa-search"></i>
                        </button>
                    </div>
                </form>
            </li>
        </ul>
        <ul class="nav navbar-right" ct-fullheight="window" data-ct-fullheight-exclusion="header" data-ct-fullheight-if="isSmallDevice">
            <!-- start: MESSAGES DROPDOWN -->
            <li class="dropdown"  ng-controller="MainCtrl">
                <a href class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <!--<notification-icon count='scopeVariable'>
                        <i class="fa fa-envelope"></i>
                    </notification-icon>-->
                </a>
                <ul class="dropdown-menu dropdown-light dropdown-messages dropdown-large animated fadeInUpShort">
                    <li>
                        <span class="dropdown-header" translate="topbar.messages.HEADER"> Unread messages</span>
                    </li>
                    <li>
                        <div class="drop-down-wrapper ps-container">
                            <ul>
                                <li class="unread" ng-repeat="message in messages | orderBy: 'date':true | limitTo:3 ">
                                    <a href="javascript:;" ng-class="{ unread: !message.read }">
                                        <div class="clearfix">
                                            <div class="thread-image">
                                                <!--<img src="{{ message.avatar && message.avatar || noAvatarImg }}" alt="" class="img-responsive img-rounded">-->
                                            </div>
                                            <div class="thread-content">
                                                <!--<span class="author">{{message.from}}</span>
                                                <span class="preview">{{ message.subject }}</span>
                                                <span class="time"> {{ message.date | date: "MM/dd/yyyy 'at' h:mma" }}</span>-->
                                            </div>
                                        </div>
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </li>
                    <li class="view-all">
                        <a href="javascript:void(0)" translate="topbar.messages.SEEALL"> See All </a>
                    </li>
                </ul>
            </li>
            <!-- end: MESSAGES DROPDOWN -->
            <!-- start: ACTIVITIES DROPDOWN -->
            <li class="dropdown" >
                <a href class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fa fa-bell"></i></a>
                <ul class="dropdown-menu dropdown-light dropdown-messages dropdown-large animated fadeInUpShort">
                    <li>
                        <span class="dropdown-header" translate="topbar.activities.HEADER"> You have new notifications</span>
                    </li>
                    <li>
                        <div class="drop-down-wrapper ps-container">
                            <div class="list-group no-margin">
                                <a class="media list-group-item" href=""> <img class="img-circle" alt="..." src="assets/images/avatar-1.jpg"> <span class="media-body block no-margin"> Use awesome animate.css <small class="block text-grey">10 minutes ago</small> </span> </a>
                                <a class="media list-group-item" href=""> <span class="media-body block no-margin"> 1.0 initial released <small class="block text-grey">1 hour ago</small> </span> </a>
                            </div>
                        </div>
                    </li>
                    <li class="view-all">
                        <a href="javascript:void(0)" translate="topbar.activities.SEEALL"> See All </a>
                    </li>
                </ul>
            </li>
            <!-- end: ACTIVITIES DROPDOWN -->
            <!-- start: LANGUAGE SWITCHER -->
            <li class="dropdown last" >
                <language-switcher></language-switcher>
            </li>
            <!-- end: LANGUAGE SWITCHER -->
        </ul>
    </div>
    <!-- end: NAVBAR COLLAPSE -->
</header>
<!-- end: TOP NAVBAR -->

<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>Working Domain</h1>
            <span>Expertise of Indian Technologies Dreams enable to cater the following Industries :</span>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-5 img-col"><img src="assets/images/working-domain/health-care.png"></div>
                <div class="col-md-7 text-center text-md-left content-col">
                    <div class="mb-5">
                        <h4>Health Care</h4>
                        <span>Reports let you easily track and analyze your product sales, orders, and payments.</span>
                    </div>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero quod consequuntur quibusdam, enim expedita sed quia nesciunt incidunt accusamus necessitatibus modi adipisci officia libero accusantium esse hic, obcaecati, ullam, laboriosam!</p>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti vero, animi suscipit id facere officia. Aspernatur, quo, quos nisi dolorum aperiam fugiat deserunt velit rerum laudantium cum magnam excepturi quod.</p>
                    <a (click)="callHealthCare();" class="btn btn-outline-dark">Learn more</a>
                </div>
            </div>
            <div class="vertical-line"></div>
            <div class="row align-items-center">
                <div class="col-md-5 img-col d-block d-md-none"><img src="assets/images/working-domain/education.png"></div>
                <div class="col-md-7 text-center text-md-left content-col">
                    <div class="mb-5">
                        <h4>Education</h4>
                        <span>Reports let you easily track and analyze your product sales, orders, and payments.</span>
                    </div>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero quod consequuntur quibusdam, enim expedita sed quia nesciunt incidunt accusamus necessitatibus modi adipisci officia libero accusantium esse hic, obcaecati, ullam, laboriosam!</p>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti vero, animi suscipit id facere officia. Aspernatur, quo, quos nisi dolorum aperiam fugiat deserunt velit rerum laudantium cum magnam excepturi quod.</p>
                    <a (click)="callEducation();" class="btn btn-outline-dark">Learn more</a>
                </div>
                <div class="col-md-5 img-col d-none d-md-block"><img src="assets/images/working-domain/education.png"></div>
            </div>
            <div class="vertical-line"></div>
            <div class="row align-items-center">
                <div class="col-md-5 img-col"><img src="assets/images/working-domain/ad-campaign.png"></div>
                <div class="col-md-7 text-center text-md-left content-col">
                    <div class="mb-5">
                        <h4>Ad & Campaign</h4>
                        <span>Reports let you easily track and analyze your product sales, orders, and payments.</span>
                    </div>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero quod consequuntur quibusdam, enim expedita sed quia nesciunt incidunt accusamus necessitatibus modi adipisci officia libero accusantium esse hic, obcaecati, ullam, laboriosam!</p>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti vero, animi suscipit id facere officia. Aspernatur, quo, quos nisi dolorum aperiam fugiat deserunt velit rerum laudantium cum magnam excepturi quod.</p>
                    <a (click)="callAdCampaign();" class="btn btn-outline-dark">Learn more</a>
                </div>
            </div>
            <div class="vertical-line"></div>
            <div class="row align-items-center">
                <div class="col-md-5 img-col d-block d-md-none"><img src="assets/images/working-domain/software-information.png"></div>
                <div class="col-md-7 text-center text-md-left content-col">
                    <div class="mb-5">
                        <h4>Software and Information Technologies</h4>
                        <span>Reports let you easily track and analyze your product sales, orders, and payments.</span>
                    </div>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero quod consequuntur quibusdam, enim expedita sed quia nesciunt incidunt accusamus necessitatibus modi adipisci officia libero accusantium esse hic, obcaecati, ullam, laboriosam!</p>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti vero, animi suscipit id facere officia. Aspernatur, quo, quos nisi dolorum aperiam fugiat deserunt velit rerum laudantium cum magnam excepturi quod.</p>
                    <a (click)="callSoftwareInformation();" class="btn btn-outline-dark">Learn more</a>
                </div>
                <div class="col-md-5 img-col d-none d-md-block"><img src="assets/images/working-domain/software-information.png"></div>
            </div>
            <div class="vertical-line"></div>
            <div class="row align-items-center">
                <div class="col-md-5 img-col"><img src="assets/images/working-domain/logistics-transport.png"></div>
                <div class="col-md-7 text-center text-md-left content-col">
                    <div class="mb-5">
                        <h4>Logistics & Transport</h4>
                        <span>Reports let you easily track and analyze your product sales, orders, and payments.</span>
                    </div>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero quod consequuntur quibusdam, enim expedita sed quia nesciunt incidunt accusamus necessitatibus modi adipisci officia libero accusantium esse hic, obcaecati, ullam, laboriosam!</p>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti vero, animi suscipit id facere officia. Aspernatur, quo, quos nisi dolorum aperiam fugiat deserunt velit rerum laudantium cum magnam excepturi quod.</p>
                    <a (click)="callLogistics();" class="btn btn-outline-dark">Learn more</a>
                </div>
            </div>
            <div class="vertical-line"></div>
            <div class="row align-items-center">
                <div class="col-md-5 img-col d-block d-md-none"><img src="assets/images/working-domain/event-management.png"></div>
                <div class="col-md-7 text-center text-md-left content-col">
                    <div class="mb-5">
                        <h4>Event Management</h4>
                        <span>Reports let you easily track and analyze your product sales, orders, and payments.</span>
                    </div>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero quod consequuntur quibusdam, enim expedita sed quia nesciunt incidunt accusamus necessitatibus modi adipisci officia libero accusantium esse hic, obcaecati, ullam, laboriosam!</p>
                    <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti vero, animi suscipit id facere officia. Aspernatur, quo, quos nisi dolorum aperiam fugiat deserunt velit rerum laudantium cum magnam excepturi quod.</p>
                    <a (click)="callEventManagement();" class="btn btn-outline-dark">Learn more</a>
                </div>
                <div class="col-md-5 img-col d-none d-md-block"><img src="assets/images/working-domain/event-management.png"></div>
            </div>
        </div>
    </div>
</div>
    <div class="walk-through">
        <div class="row">
            <div class="col-lg-6 p-0 walk-through-picture p-5"></div>
            <div class="col-lg-6 p-0 text-center p-5">
                <div class="mb-5 header-walk-through">
                    <span class="">Easily Understandable & Customizable.</span>
                    <h3>Walkthrough Videos & Demos</h3>
                </div>
                <div class="mb-5">
                    <iframe class="walkthrough-youtube-video" src="https://www.youtube.com/embed/wDIQ17T3sRk" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p class="video-desc mb-0">Democracy inspire breakthroughs, Rosa Parks; inspiration raise awareness natural resources. Governance impact; transformative donation philanthropy, respect reproductive.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-lg-3 p-0 walk-through-col lines-of-codes">
                <i class="fa fa-compass" aria-hidden="true"></i>
                <div>846K</div>
                <h5>Lines of Codes</h5>
            </div>
            <div class="col-md-6 col-lg-3 p-0 walk-through-col html-files">
                <i class="fa fa-line-chart" aria-hidden="true"></i>
                <div>21500</div>
                <h5>KBS of html files</h5>
            </div>
            <div class="col-md-6 col-lg-3 p-0 walk-through-col html-templates">
                <i class="fa fa-book" aria-hidden="true"></i>
                <div>408</div>
                <h5>No. of Templates</h5>
            </div>
            <div class="col-md-6 col-lg-3 p-0 walk-through-col hrs-coding">
                <i class="fa fa-clock-o" aria-hidden="true"></i>
                <div>1400</div>
                <h5>Hours of Coding</h5>
            </div>
        </div>
    </div>

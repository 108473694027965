import { Component,Output,EventEmitter } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'list-header',
  templateUrl: './list-header.component.html',
  styleUrls: []
})
export class ListHeaderComponent {
    pageSizeList = ['1','5', '10', '15', '20', '50', '100'];
    pageSizeSelect=10;
    @Output() pageSizeChanged = new EventEmitter<number>();
    @Output() addNewEvent = new EventEmitter<number>();
    constructor() {
    }

    changePageSize(pageChange){
        this.pageSizeSelect=pageChange;
        this.pageSizeChanged.emit(this.pageSizeSelect);
    }

    addNew(){
        this.addNewEvent.emit(1);
    }
}

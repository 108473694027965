import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import { Title }     from '@angular/platform-browser';

import { APP_CONFIG } from '../../../constants';
import { WHY_US_CONSTANTS } from '../../constants';

@Component({
  selector: 'quality-root',
  templateUrl: './quality.component.html',
  styleUrls: []
})
export class QualityComponent {
    CONFIG=APP_CONFIG;
    WHY_US_CONSTANTS=WHY_US_CONSTANTS;
    constructor(private titleService: Title) {
        this.CONFIG=APP_CONFIG;
        this.WHY_US_CONSTANTS=WHY_US_CONSTANTS;
        this.titleService.setTitle( APP_CONFIG.APP_NAME+ " :: "+ this.WHY_US_CONSTANTS.LABEL.QUALITY);
    }
}
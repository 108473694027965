<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>Overview</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <p class="mb-4">Indian Technologies Dreams is a leading software development company in India providing Offshore Software Development Services & solutions with services such as outsourcing software development India, Web Development India Product Development, E-strategy Consulting, Offshore software development,Web-Enablement,Offshore Application development, .Net Development India, PHP Development,Software Development, and Design Solutions.</p>
                    <p class="mb-4">Do you need an offshore software development company to cut the cost of your business software solution? Indian Technologies dreams provides you a low cost software development solution and makes you happy with a very high quality work. This makes your purpose complete to contact an offshore vendor.</p>
                    <p class="mb-4">Indian Technologies Dreams provides best poke software development services using web Technologies . We work in areas such as E-commerce website development, web enablement, product development, product migration, web applications etc.</p>
                    <p class="mb-4">Our solutions enable businesses to leverage leading-edge technology to gain sustainable competitive advantage in today's marketplace. Indian Technologies Dreams brings to their clients a credible and experienced global leadership team driving service delivery through the next generation global delivery model. Indian Technologies Dreams engages with clients to understand their unique business and process need. We develop solution based on the client's business requirements and our experience across multiple projects. We architect and design models incorporating industry-wide best practices.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>Vision & Mission</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <p class="mb-4">Our Vision is to be a software and knowledge services leader that creates value for stakeholders through integrity, mutual respect, and an enduring commitment to preserve the environment.</p>
                    <ul>
                        <li>Be voted as one of the most preferred working places.</li>
                        <li>Build creditable IP in area of RIA and web portals.</li>
                        <li>have a sustainable social arm.</li>
                    </ul>
                    <p class="mb-4">Our Mission is to become a software and knowledge services leader that enables our clients to transform business processes and achieve lasting competitive advantage. We will be an inimitable software and knowledge services provider that makes it possible for our customers to win through innovative business solutions.</p>
                    <p class="mb-4">We will create a culture that encourages our employees to excel and execute with passion for our customers.</p>
                    <p class="mb-4">Do you need an offshore software development company to cut the cost of your business software solution? Indian Technologies dreams provides you a low cost software development solution and makes you happy with a very high quality work. This makes your purpose complete to contact an offshore vendor</p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="meet-our-team">
    <div class="container">
        <div class="heading-block text-center">
            <h3 class="heading-title">Meet Our Team</h3>
        </div>
        <div class="row">
            <div class="col-md-6 col-lg-3 mb-5">
                <div class="team">
                    <div class="team-image">
                        <img src="assets/images/download1.jpg" />
                    </div>
                    <div class="team-desc">
                        <div class="team-title"><h4>John Doe</h4><span>CEO</span></div>
                        <a href="#" class="social-icons-mini">
                            <div class="background-facebook-mini"><i class="fa fa-facebook" aria-hidden="true"></i></div>
                        </a>
                        <a href="#" class="social-icons-mini">
                            <div class="background-twitter-mini"><i class="fa fa-twitter" aria-hidden="true"></i></div>
                        </a>
                        <a href="#" class="social-icons-mini">
                            <div class="background-google-plus-mini"><i class="fa fa-google-plus" aria-hidden="true"></i></div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-3 mb-5">
                <div class="team">
                    <div class="team-image">
                        <img src="assets/images/download3.jpg" />
                    </div>
                    <div class="team-desc">
                        <div class="team-title"><h4>Josh Clark</h4><span>Co-Founder</span></div>
                        <a href="#" class="social-icons-mini">
                            <div class="background-facebook-mini"><i class="fa fa-facebook" aria-hidden="true"></i></div>
                        </a>
                        <a href="#" class="social-icons-mini">
                            <div class="background-twitter-mini"><i class="fa fa-twitter" aria-hidden="true"></i></div>
                        </a>
                        <a href="#" class="social-icons-mini">
                            <div class="background-google-plus-mini"><i class="fa fa-google-plus" aria-hidden="true"></i></div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-3 mb-5">
                <div class="team">
                    <div class="team-image">
                        <img src="assets/images/download4.png" />
                    </div>
                    <div class="team-desc">
                        <div class="team-title"><h4>Mary Jane</h4><span>Sales</span></div>
                        <a href="#" class="social-icons-mini">
                            <div class="background-facebook-mini"><i class="fa fa-facebook" aria-hidden="true"></i></div>
                        </a>
                        <a href="#" class="social-icons-mini">
                            <div class="background-twitter-mini"><i class="fa fa-twitter" aria-hidden="true"></i></div>
                        </a>
                        <a href="#" class="social-icons-mini">
                            <div class="background-google-plus-mini"><i class="fa fa-google-plus" aria-hidden="true"></i></div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-3 mb-5">
                <div class="team">
                    <div class="team-image">
                        <img src="assets/images/download5.jpg" />
                    </div>
                    <div class="team-desc">
                        <div class="team-title"><h4>Nix Maxwell</h4><span>Support</span></div>
                        <a href="#" class="social-icons-mini">
                            <div class="background-facebook-mini"><i class="fa fa-facebook" aria-hidden="true"></i></div>
                        </a>
                        <a href="#" class="social-icons-mini">
                            <div class="background-twitter-mini"><i class="fa fa-twitter" aria-hidden="true"></i></div>
                        </a>
                        <a href="#" class="social-icons-mini">
                            <div class="background-google-plus-mini"><i class="fa fa-google-plus" aria-hidden="true"></i></div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
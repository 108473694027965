    <div class="what-client-say">
        <div class="bg-opacity">
            <div class="heading-block-white text-center">
                <h3 class="heading-title-white">Why choose us?</h3>
            </div>
            <div class="container mw-800px">
                <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                    <ol class="carousel-indicators">
                        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                    </ol>
                    <div class="carousel-inner pb-75px">
                        <div class="carousel-item active">
                            <div class="client-says">
                                <div class="client-image"><a href="#"><img src="assets/images/Ahmer.png"></a></div>
                                <div class="client-content">
                                    <p>"Faster and more affordable to purchase an off-the-shelf solution"</p>
                                    <div class="client-meta">-Ahmer Munir <br/><span>ITDPL</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="client-says">
                                <div class="client-image"><a href="#"><img src="assets/images/aamir2.png"></a></div>
                                <div class="client-content">
                                    <p>"The programming language (Java, C++), the programming framework (.NET, J2EE), or a particular kind of software system (Business Intelligence, Customer Relationship Management, or CRM). In most cases the architecture is defined by the development requirements."</p>
                                    <div class="client-meta">-Aamir Munir<br/><span>ITDPL</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="client-says">
                                <div class="client-image"><a href="#"><img src="assets/images/Tajwer.jpeg"></a></div>
                                <div class="client-content">
                                    <p>"Configuration management (CM) refers to documentation of code, as well as errors and requested changes."</p>
                                    <div class="client-meta">Tajwer Aftab<br/><span>ITDPL</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="client-says">
                                <div class="client-image"><a href="#"><img src="assets/images/Amrin.jpg"></a></div>
                                <div class="client-content">
                                    <p>"Installing and configuring software into the system environment, as well as training end users and IS or IT to support and maintain the software moving forward."</p>
                                    <div class="client-meta">-Amrin Shaikh<br/><span>ITDPL</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

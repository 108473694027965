import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import { Title }     from '@angular/platform-browser';

import { APP_CONFIG } from '../../../constants';
import { OUR_SERVICES_CONSTANTS } from '../../constants';

@Component({
  selector: 'manage-it-services-root',
  templateUrl: './manage-it-services.component.html',
  styleUrls: []
})
export class ManageItServicesComponent {
    CONFIG=APP_CONFIG;
    OUR_SERVICES_CONSTANTS=OUR_SERVICES_CONSTANTS;
    constructor(private titleService: Title) {
        this.CONFIG=APP_CONFIG;
        this.OUR_SERVICES_CONSTANTS=OUR_SERVICES_CONSTANTS;
        this.titleService.setTitle( APP_CONFIG.APP_NAME+ " :: "+ this.OUR_SERVICES_CONSTANTS.LABEL.MANAGE_IT_SERVICES);
    }
}
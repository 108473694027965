
export const COMMON_CONSTANTS = {
    "MESSAGES": {
        SUCCESS: 0,
        ERROR: 1,
        INFO: 2
    }
};

export const LOCALES= {
    'locales':[
        {'localeId': 'en','name':'English','code':'flag-icon-us'},
        {'localeId': 'it', 'name':'Italiano','code':'flag-icon-it'},
        {'localeId': 'de', 'name':'Deutsch','code':'flag-icon-de'}
    ],
    'preferredLocale': 'en'
};

export const ERROR_CODE={
    "code_1": 1,
    "code_2": 2,
    "code_3": 3,
    "code_4": 4,
    "code_5": 5,
    "code_6": 6,
    "code_7": 7,
    "code_8": 8,
    "code_9": 9,
    "code_10": 10,
    "code_11": 11,
    "code_12": 12,
    "code_13": 13,
    "code_14": 14,
    "code_15": 15,
    "code_16": 16,
    "code_17": 17,
    "code_18": 18,
    "code_19": 19,
    "code_20": 20
};

export const SUCCESS_CODE={
    "code_1": 1,
    "code_2": 2,
    "code_3": 3,
    "code_4": 4,
    "code_5": 5,
    "code_6": 6,
    "code_7": 7,
    "code_8": 8,
    "code_9": 9,
    "code_10": 10,
    "code_11": 11,
    "code_12": 12,
    "code_13": 13,
    "code_14": 14,
    "code_15": 15,
    "code_16": 16,
    "code_17": 17,
    "code_18": 18,
    "code_19": 19,
    "code_20": 20
};
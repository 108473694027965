import { NgModule }             from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { OverviewComponent } from './views/overview/overview.component';
import { ClientEngagementsComponent } from './views/client-engagements/client-engagements.component';
import { DevelopmentCycleComponent } from './views/development-cycle/development-cycle.component';
import { FlexibleTermsComponent } from './views/flexible-terms/flexible-terms.component';
import { TestimonialsComponent } from './views/testimonials/testimonials.component';

import { AuthGuard } from '../auth/services/auth.guard';

import { CLIENTS_CONSTANTS } from './constants';


const clientsRoutes: Routes = [
    { path: CLIENTS_CONSTANTS.LINK.OVERVIEW, component: OverviewComponent },
    { path: CLIENTS_CONSTANTS.LINK.CLIENT_ENGAGEMENTS, component: ClientEngagementsComponent },
    { path: CLIENTS_CONSTANTS.LINK.DEVELOPMENT_CYCLE, component: DevelopmentCycleComponent },
    { path: CLIENTS_CONSTANTS.LINK.FLEXIBLE_TERMS, component: FlexibleTermsComponent },
    { path: CLIENTS_CONSTANTS.LINK.TESTIMONIALS, component: TestimonialsComponent },
];

@NgModule({
    imports: [RouterModule.forChild(clientsRoutes)],
    exports: [RouterModule]
})
export class ClientsRoutingModule {}

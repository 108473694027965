import { NgModule }            from '@angular/core';
import { CommonModule }        from '@angular/common';
import {TranslateModule}       from '@ngx-translate/core';
import {HttpClientModule,HttpClient} from '@angular/common/http';
import { FormsModule }   from '@angular/forms';
import { TemplateModule} from '../template/template.module';
import { WorkingDomainRoutingModule }   from './working-domain-routing.module';
import { HealthCareComponent } from './views/health-care/health-care.component';
import { EducationComponent } from './views/education/education.component';
import { AdCampaignComponent } from './views/ad-campaign/ad-campaign.component';
import { SoftwareInformationComponent } from './views/software-information/software-information.component';
import { LogisticsComponent } from './views/logistics/logistics.component';
import { EventManagementComponent } from './views/event-management/event-management.component';


@NgModule({
    declarations: [
        HealthCareComponent,
        EducationComponent,
        AdCampaignComponent,
        SoftwareInformationComponent,
        LogisticsComponent,
        EventManagementComponent,
    ],
    imports: [
        TranslateModule,
        HttpClientModule,
        CommonModule,
        FormsModule,
        TemplateModule,
        WorkingDomainRoutingModule,
    ],
    exports: [HealthCareComponent,EducationComponent,AdCampaignComponent,SoftwareInformationComponent,LogisticsComponent,EventManagementComponent,],
    providers: [],
    bootstrap: [HealthCareComponent,EducationComponent,AdCampaignComponent,SoftwareInformationComponent,LogisticsComponent,EventManagementComponent,]
})
export class WorkingDomainModule { }

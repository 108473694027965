import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import { Title }     from '@angular/platform-browser';
import {Router} from '@angular/router';
import { APP_CONFIG } from '../../../constants';
import { MAIN_CONSTANTS } from '../../constants';
import { ENGAGEMENT_CONSTANTS } from '../../../engagement/constants';

@Component({
  selector: 'engagement-model-root',
  templateUrl: './engagement-model.component.html',
  styleUrls: []
})
export class EngagementModelComponent {
    CONFIG=APP_CONFIG;
    MAIN_CONSTANTS=MAIN_CONSTANTS;
    constructor(private titleService: Title, private router: Router) {
        this.CONFIG=APP_CONFIG;
        this.MAIN_CONSTANTS=MAIN_CONSTANTS;
        this.titleService.setTitle( APP_CONFIG.APP_NAME+ " :: "+ this.MAIN_CONSTANTS.LABEL.ENGAGEMENTMODEL);
    }

    callFixedPrice() {
        this.router.navigate([ENGAGEMENT_CONSTANTS.URL.ENGAGEMENT_FIXED_PRICE]);
    }
    callTimeMaterial() {
        this.router.navigate([ENGAGEMENT_CONSTANTS.URL.ENGAGEMENT_TIME_MATERIAL]);
    }
    callHybrid() {
        this.router.navigate([ENGAGEMENT_CONSTANTS.URL.ENGAGEMENT_HYBRID]);
    }
    callBot() {
        this.router.navigate([ENGAGEMENT_CONSTANTS.URL.ENGAGEMENT_BOT]);
    }
    callCoWork() {
        this.router.navigate([ENGAGEMENT_CONSTANTS.URL.ENGAGEMENT_CO_WORK]);
    }
    callAgile() {
        this.router.navigate([ENGAGEMENT_CONSTANTS.URL.ENGAGEMENT_AGILE]);
    }

}
<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>Careers</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <h4 class="mb-4">Send your resume to hr@indiantechdreams.com</h4>
                    <h4 class="mb-4">Marketing Executive: -</h4>
                    <ul><li>Knowledge of MS Office.</li></ul>
                    <h4 class="mb-4">DTP Designer: -</h4>
                    <ul>
                        <li>Knowledge of Photoshop.</li>
                        <li>Knowledge of Macromedia Flash.</li>
                        <li>Knowledge of Corel Draw.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

    <div class="more-rich-feature">
        <div class="container">
            <div class="heading-block text-center mt-0 pt-5">
                <h3 class="heading-title">Our Services in brief</h3>
                <span class="mx-auto brief-info">ITDPL provides the following services in optimum cost but with the best quality</span>
            </div>
            <div class="row feature-box p-0">
                <div class="col-lg-4 col-md-6 pb-5">
                    <div class="inside-fb">
                        <div class="d-md-none d-block fb-icon mr-3"><a href="#"><i class="orange-background-icon fa fa-desktop size23" aria-hidden="true"></i></a></div>
                        <div class="fb-content text-md-right text-sm-left p-0">
                            <h3>Software Technologies Consulting</h3>
                            <p>ITDPL consults the client for best Software Technologies.</p>
                        </div>
                        <div class="d-md-block d-none fb-icon ml-3"><a href="#"><i class="orange-background-icon fa fa-desktop size23" aria-hidden="true"></i></a></div>
                    </div>
                    <div class="inside-fb mt-5">
                        <div class="d-md-none d-block fb-icon mr-3"><a href="#"><i class="orange-background-icon fa fa-database size20" aria-hidden="true"></i></a></div>
                        <div class="fb-content text-md-right text-sm-left p-0">
                            <h3>Manage IT Services</h3>
                            <p>ITDPL manages IT Services in optimum cost with quality.</p>
                        </div>
                        <div class="d-md-block d-none fb-icon ml-3"><a href="#"><i class="orange-background-icon fa fa-database size20" aria-hidden="true"></i></a></div>
                    </div>
                    <div class="inside-fb mt-5">
                        <div class="d-md-none d-block fb-icon mr-3"><a href="#"><i class="orange-background-icon fa fa-sliders" aria-hidden="true"></i></a></div>
                        <div class="fb-content text-md-right text-sm-left p-0">
                            <h3>Software Product Development</h3>
                            <p>ITDPL develops high quality products which satisfy the clients.</p>
                        </div>
                        <div class="d-md-block d-none fb-icon ml-3"><a href="#"><i class="orange-background-icon fa fa-sliders" aria-hidden="true"></i></a></div>
                    </div>
                </div>
                <div class="col-lg-4 d-md-none d-lg-block text-center pb-5">
                    <img class="w-100" src="assets/images/desktop.png" />
                </div>
                <div class="col-lg-4 col-md-6 pb-5">
                    <div class="inside-fb">
                        <div class="fb-icon mr-3"><a href="#"><i class="orange-background-icon fa fa-wrench" aria-hidden="true"></i></a></div>
                        <div class="fb-content p-0">
                            <h3>Product Maintenance & Support</h3>
                            <p>ITDPL provides best Product Maintenance & Support.</p>
                        </div>
                    </div>
                    <div class="inside-fb mt-5">
                        <div class="fb-icon mr-3"><a href="#"><i class="orange-background-icon fa fa-check-circle-o" aria-hidden="true"></i></a></div>
                        <div class="fb-content p-0">
                            <h3>Quality Assurance Services</h3>
                            <p>TTDPL provides all services with the full Quality Assurance.</p>
                        </div>
                    </div>
                    <div class="inside-fb mt-5">
                        <div class="fb-icon mr-3"><div><a href="#"><i class="orange-background-icon fa fa-laptop" aria-hidden="true"></i></a></div></div>
                        <div class="fb-content p-0">
                            <h3>Enterprise Mobility Solutions</h3>
                            <p>ITDPL provides the best Enterprise Mobility Solutions.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

import { NgModule }             from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AngularComponent } from './views/angular/angular.component';
import { JqueryComponent } from './views/jquery/jquery.component';
import { BootstrapComponent } from './views/bootstrap/bootstrap.component';
import { CssHtmlComponent } from './views/css-html/css-html.component';
import { VueNuxtComponent } from './views/vue-nuxt/vue-nuxt.component';
import { ReactComponent } from './views/react/react.component';

import { AuthGuard } from '../auth/services/auth.guard';

import { FRONTEND_TECHNOLOGIES_CONSTANTS } from './constants';


const frontendTechnologiesRoutes: Routes = [
    { path: FRONTEND_TECHNOLOGIES_CONSTANTS.LINK.ANGULAR, component: AngularComponent },
    { path: FRONTEND_TECHNOLOGIES_CONSTANTS.LINK.JQUERY, component: JqueryComponent },
    { path: FRONTEND_TECHNOLOGIES_CONSTANTS.LINK.BOOTSTRAP, component: BootstrapComponent },
    { path: FRONTEND_TECHNOLOGIES_CONSTANTS.LINK.CSS_HTML, component: CssHtmlComponent },
    { path: FRONTEND_TECHNOLOGIES_CONSTANTS.LINK.VUE_NUXT, component: VueNuxtComponent },
    { path: FRONTEND_TECHNOLOGIES_CONSTANTS.LINK.REACT, component: ReactComponent },
];

@NgModule({
    imports: [RouterModule.forChild(frontendTechnologiesRoutes)],
    exports: [RouterModule]
})
export class FrontendTechnologiesRoutingModule {}

import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

@Injectable()
export class BreadCrumService {
    steps=[];
    constructor() {

    }

    pushStep(title,state,isTop) {
        var step={
            "title": title,
            "state": state
        };
        if(isTop){
            this.steps=[];
        }
        this.steps.push(step);
    }

    getSteps(){
        return this.steps;
    }
}
import { NgModule }             from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { OverviewComponent } from './views/overview/overview.component';
import { VisionMissionComponent } from './views/vision-mission/vision-mission.component';
import { ManagementTeamComponent } from './views/management-team/management-team.component';

import { AuthGuard } from '../auth/services/auth.guard';

import { ABOUT_US_CONSTANTS } from './constants';


const aboutUsRoutes: Routes = [
    { path: ABOUT_US_CONSTANTS.LINK.OVERVIEW, component: OverviewComponent },
    { path: ABOUT_US_CONSTANTS.LINK.VISION_MISSION, component: VisionMissionComponent },
    { path: ABOUT_US_CONSTANTS.LINK.MANAGEMENT_TEAM, component: ManagementTeamComponent },
];

@NgModule({
    imports: [RouterModule.forChild(aboutUsRoutes)],
    exports: [RouterModule]
})
export class AboutUsRoutingModule {}

<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>Data Processing</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <p class="mb-4">Indian Technologies Dreams is a leading software development company in India providing Offshore Software Development Services & solutions with services such as outsourcing software development India, Web Development India Product Development, E-strategy Consulting, Offshore software development,Web-Enablement,Offshore Application development, .Net Development India, PHP Development,Software Development, and Design Solutions.</p>
                    <p class="mb-4">Do you need an offshore software development company to cut the cost of your business software solution? Indian Technologies dreams provides you a low cost software development solution and makes you happy with a very high quality work. This makes your purpose complete to contact an offshore vendor.</p>
                    <p class="mb-4">Indian Technologies Dreams provides best poke software development services using web Technologies . We work in areas such as E-commerce website development, web enablement, product development, product migration, web applications etc.</p>
                    <p class="mb-4">Our solutions enable businesses to leverage leading-edge technology to gain sustainable competitive advantage in today's marketplace. Indian Technologies Dreams brings to their clients a credible and experienced global leadership team driving service delivery through the next generation global delivery model. Indian Technologies Dreams engages with clients to understand their unique business and process need. We develop solution based on the client's business requirements and our experience across multiple projects. We architect and design models incorporating industry-wide best practices.</p>
                </div>
            </div>
        </div>
    </div>
</div>
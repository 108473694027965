export const WORKING_DOMAIN_CONSTANTS = {
    URL: {
        WORKING_DOMAIN_HEALTH_CARE: '/working-domain/health-care',
        WORKING_DOMAIN_EDUCATION: '/working-domain/education',
        WORKING_DOMAIN_AD_CAMPAIGN: '/working-domain/ad-campaign',
        WORKING_DOMAIN_SOFTWARE_INFORMATION: '/working-domain/software-information',
        WORKING_DOMAIN_LOGISTICS: '/working-domain/logistics',
        WORKING_DOMAIN_EVENT_MANAGEMENT: '/working-domain/event-management',
    },
    LINK: {
        HEALTH_CARE: 'health-care',
        EDUCATION: 'education',
        AD_CAMPAIGN: 'ad-campaign',
        SOFTWARE_INFORMATION: 'software-information',
        LOGISTICS: 'logistics',
        EVENT_MANAGEMENT: 'event-management',
    },
    LABEL:{
        HEALTH_CARE: 'Health Care',
        EDUCATION: 'Education',
        AD_CAMPAIGN: 'Ad Campaign',
        SOFTWARE_INFORMATION: 'Software and Information Technologies',
        LOGISTICS: 'Logistics & Transport',
        EVENT_MANAGEMENT: 'Event Management',
    },
    API:{

    }
};
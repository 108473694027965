import { NgModule }             from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ManageItServicesComponent } from './views/manage-it-services/manage-it-services.component';
import { SoftwareTechnologiesConsultingComponent } from './views/software-technologies-consulting/software-technologies-consulting.component';
import { SoftwareProductDevelopmentComponent } from './views/software-product-development/software-product-development.component';
import { ProductMaintenanceComponent } from './views/product-maintenance/product-maintenance.component';
import { QualityAssuranceComponent } from './views/quality-assurance/quality-assurance.component';
import { MobilitySolutionComponent } from './views/mobility-solution/mobility-solution.component';

import { AuthGuard } from '../auth/services/auth.guard';

import { OUR_SERVICES_CONSTANTS } from './constants';


const ourServicesRoutes: Routes = [
    { path: OUR_SERVICES_CONSTANTS.LINK.MANAGE_IT_SERVICES, component: ManageItServicesComponent },
    { path: OUR_SERVICES_CONSTANTS.LINK.SOFTWARE_TECHNOLOGIES_CONSULTING, component: SoftwareTechnologiesConsultingComponent },
    { path: OUR_SERVICES_CONSTANTS.LINK.SOFTWARE_PRODUCT_DEVELOPMENT, component: SoftwareProductDevelopmentComponent },
    { path: OUR_SERVICES_CONSTANTS.LINK.PRODUCT_MAINTENANCE, component: ProductMaintenanceComponent },
    { path: OUR_SERVICES_CONSTANTS.LINK.QUALITY_ASSURANCE, component: QualityAssuranceComponent },
    { path: OUR_SERVICES_CONSTANTS.LINK.MOBILITY_SOLUTION, component: MobilitySolutionComponent },
];

@NgModule({
    imports: [RouterModule.forChild(ourServicesRoutes)],
    exports: [RouterModule]
})
export class OurServicesRoutingModule {}

export const AUTH_CONSTANTS = {
    "URL": {
        DASHBOARD: '/dashboard',
        LOGIN: '/login',
        REGISTER: '/register',
        REGISTERED: '/registered',
        CONFIRMED: '/confirmed',
        NOT_CONFIRMED: '/not-confirmed',
        FORGOT_PASSWORD: '/forgot-password',
        FORGOT_PASSWORD_CONFIRMATION: '/forgot-password-confirmation',
        RESET_PASSWORD: '/reset-password',
        PASSWORD_RESET: '/password-reset',
        SCREEN_LOCK: '/screen-lock'
    },
    LINK:{
        LOGIN: 'login',
        REGISTER: 'register',
        REGISTERED: 'registered',
        CONFIRMED: 'confirmed',
        NOT_CONFIRMED: 'not-confirmed',
        FORGOT_PASSWORD: 'forgot-password',
        FORGOT_PASSWORD_CONFIRMATION: 'forgot-password-confirmation',
        RESET_PASSWORD: 'resetPassword/:alias/:token',
        PASSWORD_RESET: 'password-reset',
        SCREEN_LOCK: 'screen-lock'
    },
    "LABEL":{
        LOGIN: 'Login',
        REGISTER: 'Register',
        REGISTERED: 'Registered',
        CONFIRMED: 'Confirmed',
        NOT_CONFIRMED: 'Not Confirmed',
        FORGOT_PASSWORD: 'Forgot Password',
        FORGOT_PASSWORD_CONFIRMATION: 'Forgot Password Confirmation',
        RESET_PASSWORD: 'Reset Password',
        PASSWORD_RESET: 'Password Reset',
        SCREEN_LOCK: 'Screen Lock'
    },
    API:{
        AUTHENTICATE: '/authenticate',
        REGISTER: '/register',
        RESET: '/resetPassword',
        FORGOT: '/forgotPassword',
        LOGOUT: '/logout',
        ABOUT: '/me',
        DASHBOARD: '/permission/dashboard/:userId',
        FEATURES: '/permission/features/:userId'
    }
};
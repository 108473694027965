export const OUR_SERVICES_CONSTANTS = {
    URL: {
        OUR_SERVICES_MANAGE_IT_SERVICES: '/our-services/manage-it-services',
        OUR_SERVICES_SOFTWARE_TECHNOLOGIES_CONSULTING: '/our-services/software-technologies-consulting',
        OUR_SERVICES_SOFTWARE_PRODUCT_DEVELOPMENT: '/our-services/software-product-development',
        OUR_SERVICES_PRODUCT_MAINTENANCE: '/our-services/product-maintenance',
        OUR_SERVICES_QUALITY_ASSURANCE: '/our-services/quality-assurance',
        OUR_SERVICES_MOBILITY_SOLUTION: '/our-services/mobility-solution',
    },
    LINK: {
        MANAGE_IT_SERVICES: 'manage-it-services',
        SOFTWARE_TECHNOLOGIES_CONSULTING: 'software-technologies-consulting',
        SOFTWARE_PRODUCT_DEVELOPMENT: 'software-product-development',
        PRODUCT_MAINTENANCE: 'product-maintenance',
        QUALITY_ASSURANCE: 'quality-assurance',
        MOBILITY_SOLUTION: 'mobility-solution',
    },
    LABEL:{
        MANAGE_IT_SERVICES: 'Manage IT Services',
        SOFTWARE_TECHNOLOGIES_CONSULTING: 'Software Technologies Consulting',
        SOFTWARE_PRODUCT_DEVELOPMENT: 'Software Product Development',
        PRODUCT_MAINTENANCE: 'Product Maintenance & Support',
        QUALITY_ASSURANCE: 'Quality Assurance Services',
        MOBILITY_SOLUTION: 'Enterprise Mobility Solutions',
    },
    API:{

    }
};
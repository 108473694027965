<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>Networking & Community</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <p class="mb-4">Networking software allows you to create an instant community that members can join and participate in. Our community software makes the process of starting your own site site fast and easy. The look of the site can be fully customized to your needs, whether it be a minor change or a completely different design. Take comfort in our company's strong reputation for great quality and support, knowing that you'll be in good hands!</p>
                </div>
            </div>
        </div>
    </div>
</div>
import { NgModule }            from '@angular/core';
import { CommonModule }        from '@angular/common';
import {TranslateModule}       from '@ngx-translate/core';
import {HttpClientModule,HttpClient} from '@angular/common/http';
import { FormsModule }   from '@angular/forms';
import { TemplateModule} from '../template/template.module';
import { EngagementRoutingModule }   from './engagement-routing.module';
import { FixedPriceComponent } from './views/fixed-price/fixed-price.component';
import { TimeMaterialComponent } from './views/time-material/time-material.component';
import { HybridComponent } from './views/hybrid/hybrid.component';
import { BotComponent } from './views/bot/bot.component';
import { CoWorkComponent } from './views/co-work/co-work.component';
import { AgileComponent } from './views/agile/agile.component';

@NgModule({
    declarations: [
        FixedPriceComponent,
        TimeMaterialComponent,
        HybridComponent,
        BotComponent,
        CoWorkComponent,
        AgileComponent,
    ],
    imports: [
        TranslateModule,
        HttpClientModule,
        CommonModule,
        FormsModule,
        TemplateModule,
        EngagementRoutingModule,
    ],
    exports: [FixedPriceComponent,TimeMaterialComponent,HybridComponent,BotComponent,CoWorkComponent,AgileComponent,],
    providers: [],
    bootstrap: [FixedPriceComponent,TimeMaterialComponent,HybridComponent,BotComponent,CoWorkComponent,AgileComponent,]
})
export class EngagementModule { }

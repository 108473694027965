import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Title }     from '@angular/platform-browser';

import { APP_CONFIG } from '../../../constants';
import { MAIN_CONSTANTS } from '../../constants';

import { AdminComponent} from '../../../template/admin/admin.component';
import { PageTitleComponent} from '../../../template/pagetitle/page-title.component';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: [],
})
export class DashboardComponent {
    setting:any = {
        pageTitle: "dashboard.pageTitle",
        pageDesc: "dashboard.pageDesc"
    };
    steps:any=[];
    CONFIG=APP_CONFIG;
    MAIN_CONSTANTS=MAIN_CONSTANTS;
    constructor(private titleService: Title) {
        this.CONFIG=APP_CONFIG;
        this.MAIN_CONSTANTS=MAIN_CONSTANTS;
        this.titleService.setTitle( APP_CONFIG.APP_NAME+ " :: "+ this.MAIN_CONSTANTS.LABEL.DASHBOARD);

    }
}

export const APP_CONSTANTS = {

    LINK: {
        HOME: '',
        AUTH: '',
        MAIN: '',
        USER: 'user',
        PERMISSION: 'permission',
        ENGAGEMENT: 'engagement',
        OUR_SERVICES: 'our-services',
        WORKING_DOMAIN: 'working-domain',
        WEB_TECHNOLOGIES: 'web-technologies',
        MOBILE_TECHNOLOGIES: 'mobile-technologies',
        FRONTEND_TECHNOLOGIES: 'frontend-technologies',
        ABOUT_US: 'about-us',
        TECHNOLOGIES: 'technologies',
        NEWS_EVENTS: 'news-events',
        CLIENTS: 'clients',
        WHY_US: 'why-us',
        SERVICES: 'services',
        ISLAM: '',
        PAGE404: "/page-not-found-404-error",
        OTHERS: '**'
    },
    URL: {
        HOME: '',
        AUTH: '',
        MAIN: '',
        PAGE404: "/page-not-found-404-error",
        OTHERS: '**',
        ENGAGEMENT: '',
        OUR_SERVICES: '',
        WORKING_DOMAIN: '',
        WEB_TECHNOLOGIES: '',
        MOBILE_TECHNOLOGIES: '',
        FRONTEND_TECHNOLOGIES: '',
        ABOUT_US: '',
        NEWS_EVENTS: '',
        TECHNOLOGIES: '',
        CLIENTS: '',
        WHY_US: '',
        SERVICES: '',
    }

};

export const APP_CONFIG = {
    'APP_NAME': 'Conglutinater',
    'APP_VERSION': '0.0.0',
    'GOOGLE_ANALYTICS_ID': '',
    'BASE_URL': 'http://dev.indiantechdreams.com',
    'CONTEXT': "http://dev.indiantechdreams.com/indiantechdreams",
    'API_URL': "/apis/",
    'API_VERSION': "v1",
    "IMAGE_URL": "http://indiantechdreams.s3.amazonaws.com",
    'SYSTEM_LANGUAGE': '',
    'PAGE_SIZE': 10
};
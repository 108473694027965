import { NgModule }             from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { OverviewComponent } from './views/overview/overview.component';
import { CompetenciesComponent } from './views/competencies/competencies.component';
import { QualityComponent } from './views/quality/quality.component';
import { SystemAnalysisComponent } from './views/system-analysis/system-analysis.component';
import { MaintenanceSupportComponent } from './views/maintenance-support/maintenance-support.component';

import { AuthGuard } from '../auth/services/auth.guard';

import { WHY_US_CONSTANTS } from './constants';


const whyUsRoutes: Routes = [
    { path: WHY_US_CONSTANTS.LINK.OVERVIEW, component: OverviewComponent },
    { path: WHY_US_CONSTANTS.LINK.COMPETENCIES, component: CompetenciesComponent },
    { path: WHY_US_CONSTANTS.LINK.QUALITY, component: QualityComponent },
    { path: WHY_US_CONSTANTS.LINK.SYSTEM_ANALYSIS, component: SystemAnalysisComponent },
    { path: WHY_US_CONSTANTS.LINK.MAINTENANCE_SUPPORT, component: MaintenanceSupportComponent },
];

@NgModule({
    imports: [RouterModule.forChild(whyUsRoutes)],
    exports: [RouterModule]
})
export class WhyUsRoutingModule {}

<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>Testing services</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <p class="mb-4">Managing and implementing structured software testing practices is essential to the successful delivery of your business and technical strategies.</p>
                    <p class="mb-4">At Indian Technologies Dreams, we make that happen by giving you a wide range of software testing solutions to improve all aspects of your software development life cycle, giving you confidence in your IT.</p>
                    <p class="mb-4">Everything we do Indian Technologies Dreams is focused on helping you effectively test your software systems to manage your risks, reduce project timescale and costs.</p>
                </div>
            </div>
        </div>
    </div>
</div>
import { NgModule }             from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NewsComponent } from './views/news/news.component';
import { EventsComponent } from './views/events/events.component';

import { AuthGuard } from '../auth/services/auth.guard';

import { NEWS_EVENTS_CONSTANTS } from './constants';


const newsEventsRoutes: Routes = [
    { path: NEWS_EVENTS_CONSTANTS.LINK.NEWS, component: NewsComponent },
    { path: NEWS_EVENTS_CONSTANTS.LINK.EVENTS, component: EventsComponent },
];

@NgModule({
    imports: [RouterModule.forChild(newsEventsRoutes)],
    exports: [RouterModule]
})
export class NewsEventsRoutingModule {}

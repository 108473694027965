<!-- start: REGISTRATION -->
<div class="row" >
    <div class="main-login col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2 col-md-4 col-md-offset-4 col-lg-4 col-lg-offset-4">
        <div class="logo text-center">
            <img src="{{CONFIG.IMAGE_URL}}/images/logo.png" alt="{{CONFIG.APP_NAME}}" class="img-responsive" />
        </div>
        <p class="text-center text-dark text-bold text-extra-large margin-top-15">{{"auth.view.signup" | translate}}</p>
        <p class="text-center">{{"auth.view.personalDetail" | translate}}</p>
        <!-- start: REGISTER BOX -->
        <div class="box-register">
            <form class="form-register" name="registerForm" role="form" (ngSubmit)="f.form.valid && mustAgree(registerUser) && register(registerUser,f)" #f="ngForm" novalidate>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !firstName.valid }">
                    <input type="text" class="form-control" name="firstName" placeholder="{{ 'register.placeholder.firstName' | translate }}" [(ngModel)]="registerUser.firstName" #firstName="ngModel" required />
                    <span *ngIf="f.submitted && !firstName.valid"  class="error text-small block" >{{ "register.error.firstName" | translate }}</span>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !lastName.valid }">
                    <input type="text" class="form-control" name="lastName" placeholder="{{ 'register.placeholder.lastName' | translate }}" [(ngModel)]="registerUser.lastName" #lastName="ngModel" required />
                    <span *ngIf="f.submitted && !lastName.valid"  class="error text-small block" >{{ "register.error.lastName" | translate }}</span>
                </div>

                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !email.valid }">
                    <input type="text" class="form-control" name="email" placeholder="{{ 'register.placeholder.email' | translate }}" [(ngModel)]="registerUser.email" #email="ngModel" required  />
                    <span class="error text-small block" *ngIf="f.submitted && !email.valid" >{{ "register.error.email" | translate }}</span>
                    <span class="error text-small block" *ngIf="f.submitted && validateEmail(registerUser);">{{ "register.error.emailValid" | translate }}</span>
                    <span class="error text-small block" *ngIf="emailAlreadyRegistered">{{ "register.error.emailUsed" | translate }}</span>
                </div>
                <p>{{"auth.view.passwordDetails" | translate}}</p>
                <div class="form-group"  [ngClass]="{ 'has-error': f.submitted && !password.valid }">
                    <input autocomplete="false" type="password" class="form-control" id="password" name="password" placeholder="{{ 'register.placeholder.password' | translate }}" [(ngModel)]="registerUser.password" #password="ngModel" required>
                    <span class="error text-small block" *ngIf="f.submitted && !password.valid">{{ "register.error.password" | translate }}</span>
                </div>
                <div class="form-group"  [ngClass]="{ 'has-error': f.submitted && !confirmPassword.valid }">
                    <input autocomplete="false" type="password" class="form-control" name="confirmPassword" placeholder="{{ 'register.placeholder.confirmPassword' | translate }}" [(ngModel)]="registerUser.confirmPassword" #confirmPassword="ngModel" required/>
                    <span class="error text-small block" *ngIf="f.submitted && !confirmPassword.valid">{{ "register.error.confirmPassword" | translate }}</span>
                    <span class="error text-small block" *ngIf="pwmatch(registerUser)">{{ "register.error.sameConfirmPassword" | translate }}</span>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !agree }">
                    <div class="checkbox clip-check check-primary">
                        <input type="checkbox" id="agree" name="agree" value="true" [(ngModel)]="registerUser.agree" #agree="ngModel" required>
                        <label for="agree">{{"auth.view.agreeDetail" | translate}}</label>
                        <span class="privacy"><a (click)="privacy();"><i class="fa fa-question fa-fw" aria-hidden="true"></i></a></span>
                        <span class="error text-small block" *ngIf="f.submitted && !mustAgree(registerUser)">{{"register.error.mustAgree" | translate}}</span>
                    </div>
                </div>
                <div class="form-actions">
                    <button type="submit" class="btn btn-red btn-block" [disabled]="loading">
                    {{"auth.view.submit" | translate}}
                    <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    </button>

                </div>
                <p class="text-center margin-top-15">
                    <span>{{"auth.view.alreadyAccount" | translate}}</span>
                    <a [routerLink]="[AUTH_CONSTANTS.URL.LOGIN]">{{"auth.view.logIn" | translate }}</a>
                </p>
                <hr />
                <p class="margin-top-10 text-center">{{"auth.view.social" | translate }}</p>
                <div class="social-icons text-center">
                    <ul>
                        <li class="social-twitter" uib-tooltip="Twitter" tooltip-placement="top">
                            <a target="_blank" href="#">{{"auth.view.twitter" | translate }}</a>
                        </li>
                        <li class="social-google" uib-tooltip="Google" tooltip-placement="top">
                            <a target="_blank" href="#">{{"auth.view.google" | translate }}</a>
                        </li>
                        <li class="social-facebook" uib-tooltip="Facebook" tooltip-placement="top">
                            <a target="_blank" href="#">{{"auth.view.facebook" | translate }}</a>
                        </li>
                    </ul>
                </div>
            </form>
        </div>
        <!-- end: REGISTER BOX -->
    </div>
</div>
<privacy-content-modal></privacy-content-modal>
import { NgModule }            from '@angular/core';
import { CommonModule }        from '@angular/common';
import {TranslateModule}       from '@ngx-translate/core';
import {HttpClientModule,HttpClient} from '@angular/common/http';
import { FormsModule }   from '@angular/forms';
import { TemplateModule} from '../template/template.module';
import { FrontendTechnologiesRoutingModule }   from './frontend-technologies-routing.module';
import { AngularComponent } from './views/angular/angular.component';
import { JqueryComponent } from './views/jquery/jquery.component';
import { BootstrapComponent } from './views/bootstrap/bootstrap.component';
import { CssHtmlComponent } from './views/css-html/css-html.component';
import { VueNuxtComponent } from './views/vue-nuxt/vue-nuxt.component';
import { ReactComponent } from './views/react/react.component';

@NgModule({
    declarations: [
        AngularComponent,
        JqueryComponent,
        BootstrapComponent,
        CssHtmlComponent,
        VueNuxtComponent,
        ReactComponent,
    ],
    imports: [
        TranslateModule,
        HttpClientModule,
        CommonModule,
        FormsModule,
        TemplateModule,
        FrontendTechnologiesRoutingModule,
    ],
    exports: [AngularComponent,JqueryComponent,BootstrapComponent,CssHtmlComponent,VueNuxtComponent,ReactComponent],
    providers: [],
    bootstrap: [AngularComponent,JqueryComponent,BootstrapComponent,CssHtmlComponent,VueNuxtComponent,ReactComponent,]
})
export class FrontendTechnologiesModule { }

import { Component, OnInit } from '@angular/core';
import { FormsModule }   from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Title }     from '@angular/platform-browser';

import { APP_CONFIG } from '../../../constants';
import { AUTH_CONSTANTS } from '../../constants';

import { AuthenticationService } from '../../services/authentication.service';
import { AlertService } from '../../../shared/services/alert.service';
import { AlertComponent } from '../../../shared/directives/alert/alert.component';

@Component({
    moduleId: module.id.toString(),
    templateUrl: 'reset-password.component.html'
})

export class ResetPasswordComponent implements OnInit {
    registerUser: any = {};
    CONFIG=APP_CONFIG;
    AUTH_CONSTANTS=AUTH_CONSTANTS;
    loading = false;
    token="";
    alias="";
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private titleService: Title,
        private authenticationService: AuthenticationService,
        private alertService: AlertService
        ) {
        this.CONFIG=APP_CONFIG;
        this.AUTH_CONSTANTS=AUTH_CONSTANTS;
        this.titleService.setTitle( APP_CONFIG.APP_NAME+ " :: "+ this.AUTH_CONSTANTS.LABEL.REGISTER);
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.alias = params['alias'];
            this.token = params['token'];
        });
    }

    pwmatch(registerUser){
        return !(registerUser.password === registerUser.confirmPassword);
    }

    resetPassword(registerUser,f) {
        this.loading = true;
        var registerUserCopy=JSON.parse(JSON.stringify(registerUser));
        delete registerUserCopy.confirmPassword;
        registerUserCopy.alias=this.alias;
        registerUserCopy.token=this.token;
        this.authenticationService.resetPassword(registerUserCopy)
            .subscribe(
            data => {
                if(!data['hasError']){
                    this.router.navigate([this.AUTH_CONSTANTS.URL.PASSWORD_RESET]);
                }else{
                    this.alertService.error(data['error'].errorMessageCode);
                    this.loading = false;
                }
            },
            error => {
                this.alertService.error(error);
                this.loading = false;
            });
    }

}

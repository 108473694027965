export const TECHNOLOGIES_CONSTANTS = {
    URL: {
        TECHNOLOGIES_OVERVIEW: '/technologies/overview',
        TECHNOLOGIES_TECHNOLOGY: 'technologies/technology',
    },
    LINK: {
        OVERVIEW: 'overview',
        TECHNOLOGY: 'technology',
    },
    LABEL:{
        OVERVIEW: 'Overview',
        TECHNOLOGY: 'Technology',
    },
    API:{

    }
};
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormsModule }   from '@angular/forms';
import { Title }     from '@angular/platform-browser';

import { APP_CONFIG } from '../../../constants';
import { AUTH_CONSTANTS } from '../../constants';

import { AuthenticationService } from '../../services/authentication.service';
import { AlertService } from '../../../shared/services/alert.service';
import { AlertComponent } from '../../../shared/directives/alert/alert.component';

@Component({
    moduleId: module.id.toString(),
    templateUrl: 'login.component.html'
})

export class LoginComponent implements OnInit {
    user: any = {};
    loading = false;
    CONFIG=APP_CONFIG;
    AUTH_CONSTANTS=AUTH_CONSTANTS;
    returnUrl: string;
    currentUser=undefined;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private titleService: Title) {
        this.CONFIG=APP_CONFIG;
        this.AUTH_CONSTANTS=AUTH_CONSTANTS;
        this.titleService.setTitle( APP_CONFIG.APP_NAME+ " :: "+ this.AUTH_CONSTANTS.LABEL.LOGIN);
    }

    ngOnInit() {
        this.currentUser=this.authenticationService.getCurrentUser();
        if(this.currentUser !=null){
            this.router.navigate(['dashboard']);
        }
        // reset login status
        this.authenticationService.logout();

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
    }

    login() {
        this.loading = true;
        this.authenticationService.login(this.user.username, this.user.password)
            .subscribe(
            data => {
                this.currentUser=data["data"][0];
                if (this.currentUser && this.currentUser.authToken) {
                    this.authenticationService.setCurrentUser(this.currentUser);
                }
                this.router.navigate([this.returnUrl]);
            },
            error => {
                this.alertService.error(error.message);
                this.loading = false;
            });
    }
}

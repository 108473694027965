<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>Online Market Solution</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <p class="mb-4">Indian Technologies Dreams has designed, developed and deployed various B2B and B2C portals and market places. The contribution by the team at Indian Technologies Dreams is not restricted to choosing the technical stack and selecting various open source components to increase the efficacy of delivery. The business analysts at Indian Technologies Dreams lay a strong focus on providing consultancy around the selection of design elements to ensure maximum end user satisfaction. All the applications are designed keeping in mind the customer scalability and user experience needs.</p>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import { APP_CONFIG } from '../../../constants';
import { MAIN_CONSTANTS } from '../../constants';

@Component({
  selector: 'home-carousel',
  templateUrl: './home-carousel.component.html',
  styleUrls: []
})
export class HomeCarouselComponent {
    CONFIG=APP_CONFIG;
    MAIN_CONSTANTS=MAIN_CONSTANTS;
    constructor() {
        this.CONFIG=APP_CONFIG;
        this.MAIN_CONSTANTS=MAIN_CONSTANTS;
    }
    ngOnInit() {

    }
}

import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { APP_CONFIG } from '../../constants';
import { MAIN_CONSTANTS } from '../../main/constants';

import { LanguageSwitcherComponent } from '../../shared/directives/language-switcher/language-switcher.component';

@Component({
  selector: 'top-navbar',
  templateUrl: './topnavbar.component.html',
  styleUrls: []
})
export class TopNavbarComponent {
    CONFIG=APP_CONFIG;
    MAIN_CONSTANTS=MAIN_CONSTANTS;
    constructor() {
        this.CONFIG=APP_CONFIG;
        this.MAIN_CONSTANTS=MAIN_CONSTANTS;
    }

    toggleSideBar(){
        if($('#app').hasClass('app-sidebar-closed')){
            $('#app').removeClass('app-sidebar-closed');
        }else{
            $('#app').addClass('app-sidebar-closed');
        }
    }
}

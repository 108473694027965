import { NgModule }            from '@angular/core';
import { CommonModule }        from '@angular/common';
import {TranslateModule}       from '@ngx-translate/core';
import {HttpClientModule,HttpClient} from '@angular/common/http';
import { FormsModule }   from '@angular/forms';
import { TemplateModule} from '../template/template.module';
import { NewsEventsRoutingModule }   from './news-events-routing.module';
import { NewsComponent } from './views/news/news.component';
import { EventsComponent } from './views/events/events.component';


@NgModule({
    declarations: [
        NewsComponent,
        EventsComponent,
    ],
    imports: [
        TranslateModule,
        HttpClientModule,
        CommonModule,
        FormsModule,
        TemplateModule,
        NewsEventsRoutingModule,
    ],
    exports: [NewsComponent,EventsComponent,],
    providers: [],
    bootstrap: [NewsComponent,EventsComponent,]
})
export class NewsEventsModule { }

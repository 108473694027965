    <div>
    <!-- start: USER OPTIONS -->
    <div class="nav-user-wrapper">
        <div class="media">
            <div class="media-left">
                <a class="profile-card-photo" href="#"><img alt="" src="/assets/images/avatar-1.jpg" /></a>
            </div>
            <div class="media-body">
                <span class="media-heading text-white">{{currentUser.username}}</span>
                <div class="text-small text-white-transparent">
                    {{currentUser.designation}}({{currentUser.gender}})
                </div>
            </div>

            <div class="media-right media-middle">
                <a href class="dropdown-toggle btn btn-transparent text-white"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fa fa-caret-down"></i> </a>
                <ul class="dropdown-menu pull-right animated fadeInRight">
                    <li><a href="app.pages.user" ><i class="fa fa-user" aria-hidden="true"></i>&nbsp;<span translate="topbar.user.PROFILE">My Profile</span></a></li>
                    <li><a href="app.pages.calendar" ><i class="fa fa-calendar" aria-hidden="true"></i>&nbsp;<span translate="topbar.user.CALENDAR">My Calendar</span></a></li>
                    <li><a href="app.pages.messages"><i class="fa fa-envelope" aria-hidden="true"></i>&nbsp;<span translate="topbar.user.MESSAGES">My Messages</span> (3)</a></li>
                    <li><a [routerLink]="[AUTH_CONSTANTS.URL.SCREEN_LOCK]"><i class="fa fa-unlock-alt" aria-hidden="true"></i>&nbsp;<span>{{"topbar.user.LOCKSCREEN" | translate}}</span></a></li>
                    <li class="divider"></li>
                    <li><a (click)="logout();"><i class="fa fa-sign-out" aria-hidden="true"></i>&nbsp;<span>{{ "topbar.user.LOGOUT" | translate}}</span></a></li>
                </ul>
            </div>
        </div>
    </div>
    <!-- end: USER OPTIONS -->
    <!-- start: SIDEBAR -->
    <navbar [featureCategories]='featureCategories' [features]='features'></navbar>
    <!-- end: SIDEBAR -->
</div>
<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>Interanet Solution</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <p class="mb-4">Intranet Solutions are offered in a variety of formats catering to individual requirements. We can integrate our solution into your existing Intranet, content management system or knowledge management portal, An effective intranet solution includes professional email, document management, project management, calendaring, and contact management tools.</p>
                </div>
            </div>
        </div>
    </div>
</div>
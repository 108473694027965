import { Component, OnInit } from '@angular/core';
import { FormsModule }   from '@angular/forms';
import { Title }     from '@angular/platform-browser';

import { APP_CONFIG } from '../../../constants';
import { AUTH_CONSTANTS } from '../../constants';


@Component({
    moduleId: module.id.toString(),
    templateUrl: 'registered.component.html'
})

export class RegisteredComponent implements OnInit {
    CONFIG=APP_CONFIG;
    AUTH_CONSTANTS=AUTH_CONSTANTS;
    currentUser=undefined;
    constructor(
        private titleService: Title) {
        this.CONFIG=APP_CONFIG;
        this.AUTH_CONSTANTS=AUTH_CONSTANTS;
        this.titleService.setTitle( APP_CONFIG.APP_NAME+ " :: "+ this.AUTH_CONSTANTS.LABEL.REGISTERED);
    }

    ngOnInit() {
    }
}

import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { APP_CONFIG } from '../../constants';
import { AuthenticationService } from '../../auth/services/authentication.service';
import { AUTH_CONSTANTS } from '../../auth/constants';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: []
})
export class SidebarComponent {
    currentUser = undefined;
    featureCategories = [];
    features=[];
    CONFIG=APP_CONFIG;
    AUTH_CONSTANTS=AUTH_CONSTANTS;
    constructor(private authenticationService: AuthenticationService,private router: Router) {
        this.CONFIG=APP_CONFIG;
        this.AUTH_CONSTANTS=AUTH_CONSTANTS;
        this.authenticationService.sessionChange$.subscribe(
            value => {
                this.currentUser= authenticationService.getCurrentUser();
                this.featureCategories=authenticationService.getFeatureCategories();
                if(this.featureCategories){
                    this.generateMenu(this.featureCategories["cores"]);
                    this.generateMenu(this.featureCategories["others"]);
                }
                this.features=authenticationService.getFeatures();
            }
        );
    }

    generateMenu(categories){
        if(categories){
            for(var i=0;i<categories.length;i++){
                if(categories[i].features.length>0){
                    categories[i].menus=[];
                    for(var j=0;j<categories[i].features.length;j++){
                        var menu=JSON.parse(JSON.stringify(categories[i].features[j]));
                        var menuCreate=JSON.parse(JSON.stringify(menu));
                        if(menu.crud){
                            menuCreate.translation=menu.translation+".create";
                            menuCreate.state=menu.state+"/create";
                        }
                        menu.translation=menu.translation+".main";
                        menu.state=menu.state+"/list";
                        categories[i].menus.push(menu);
                        if(menu.crud){
                            categories[i].menus.push(menuCreate);
                        }
                    }
                }
            }
        }
    }

    logout(){
        this.authenticationService.logout().subscribe(
            data => {
                if(!data["hasError"]){
                    // remove user from local storage to log user out
                }
            },
            error => {
                console.log(error);
            });
        localStorage.removeItem('currentUser');
        this.authenticationService.setCurrentUserNull();
        this.router.navigate([AUTH_CONSTANTS.URL.LOGIN]);
    }
}

export const WHY_US_CONSTANTS = {
    URL: {
        WHY_US_OVERVIEW: '/why-us/overview',
        WHY_US_COMPETENCIES: '/why-us/competencies',
        WHY_US_QUALITY: '/why-us/quality',
        WHY_US_SYSTEM_ANALYSIS: '/why-us/system-analysis',
        WHY_US_MAINTENANCE_SUPPORT: '/why-us/maintenance-support',
    },
    LINK: {
        OVERVIEW: 'overview',
        COMPETENCIES: 'competencies',
        QUALITY: 'quality',
        SYSTEM_ANALYSIS: 'system-analysis',
        MAINTENANCE_SUPPORT: 'maintenance-support',
    },
    LABEL:{
        OVERVIEW: 'Overview',
        COMPETENCIES: 'Competencies',
        QUALITY: 'Quality',
        SYSTEM_ANALYSIS: 'System analysis & Design',
        MAINTENANCE_SUPPORT: 'Maintenance and Support',
    },
    API:{

    }
};
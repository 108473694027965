import { NgModule }            from '@angular/core';
import { CommonModule }        from '@angular/common';
import {TranslateModule}       from '@ngx-translate/core';
import {HttpClientModule,HttpClient} from '@angular/common/http';
import { FormsModule }   from '@angular/forms';
import { TemplateModule} from '../template/template.module';
import { HomeComponent } from './views/home/home.component';
import { HomeCarouselComponent } from './views/home-carousel/home-carousel.component';
import { HomeHowItWorksComponent } from './views/homeHowItWorks/homeHowItWorks.component';
import { HomeOurWorkComponent } from './views/homeOurWork/homeOurWork.component';
import { HomeMoreRichComponent } from './views/homeMoreRich/homeMoreRich.component';
import { HomeWalkThroughComponent } from './views/homeWalkThrough/homeWalkThrough.component';
import { HomeFeaturesOptimizationComponent } from './views/homeFeaturesOptimization/homeFeaturesOptimization.component';
import { HomeClientSayComponent } from './views/homeClientSay/homeClientSay.component';
import { HomeMeetTeamComponent } from './views/homeMeetTeam/homeMeetTeam.component';
import { HomeBlogComponent } from './views/homeBlog/homeBlog.component';
import { HomeBottomCarouselComponent } from './views/homeBottomCarousel/homeBottomCarousel.component';
import { ProductComponent } from './views/product/product.component';
import { Page404Component } from './views/page404/page404.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { MainRoutingModule }   from './main-routing.module';
import { EngagementModelComponent } from './views/engagement-model/engagement-model.component';
import { WorkingDomainComponent } from './views/working-domain/working-domain.component';
import { OurServicesComponent } from './views/our-services/our-services.component';
import { WebTechnologiesComponent } from './views/web-technologies/web-technologies.component';
import { FrontendTechnologiesComponent } from './views/frontend-technologies/frontend-technologies.component';
import { MobileTechnologiesComponent } from './views/mobile-technologies/mobile-technologies.component';
import { CareersComponent } from './views/careers/careers.component';


@NgModule({
    declarations: [
        HomeComponent,
        HomeCarouselComponent,
        HomeHowItWorksComponent,
        HomeOurWorkComponent,
        HomeMoreRichComponent,
        HomeWalkThroughComponent,
        HomeFeaturesOptimizationComponent,
        HomeClientSayComponent,
        HomeBlogComponent,
        HomeMeetTeamComponent,
        HomeBottomCarouselComponent,
        ProductComponent,
        DashboardComponent,
        Page404Component,
        EngagementModelComponent,
        WorkingDomainComponent,
        OurServicesComponent,
        WebTechnologiesComponent,
        FrontendTechnologiesComponent,
        MobileTechnologiesComponent,
        CareersComponent,
    ],
    imports: [
        TranslateModule,
        HttpClientModule,
        CommonModule,
        FormsModule,
        TemplateModule,
        MainRoutingModule,
    ],
    exports: [HomeComponent,HomeCarouselComponent,HomeHowItWorksComponent,HomeOurWorkComponent, HomeMoreRichComponent,HomeWalkThroughComponent,HomeFeaturesOptimizationComponent,HomeClientSayComponent,HomeBlogComponent,HomeMeetTeamComponent,HomeBottomCarouselComponent,ProductComponent,DashboardComponent,Page404Component,EngagementModelComponent,WorkingDomainComponent,OurServicesComponent,WebTechnologiesComponent,FrontendTechnologiesComponent,MobileTechnologiesComponent,CareersComponent,],
    providers: [],
    bootstrap: [HomeComponent,HomeCarouselComponent,HomeHowItWorksComponent,HomeOurWorkComponent, HomeMoreRichComponent,HomeWalkThroughComponent,HomeFeaturesOptimizationComponent,HomeClientSayComponent,HomeBlogComponent,HomeMeetTeamComponent,HomeBottomCarouselComponent,ProductComponent,DashboardComponent,Page404Component,EngagementModelComponent,WorkingDomainComponent,OurServicesComponent,WebTechnologiesComponent,FrontendTechnologiesComponent,MobileTechnologiesComponent,CareersComponent,]
})
export class MainModule { }


import { NgModule }             from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { OverviewComponent } from './views/overview/overview.component';
import { OnlineMarketComponent } from './views/online-market/online-market.component';
import { InteranetSolutionComponent } from './views/interanet-solution/interanet-solution.component';
import { TestingServicesComponent } from './views/testing-services/testing-services.component';
import { DesktopSolutionComponent } from './views/desktop-solution/desktop-solution.component';
import { CmsImplementComponent } from './views/cms-implement/cms-implement.component';
import { CmsWebsiteComponent } from './views/cms-website/cms-website.component';
import { CodeImprovementComponent } from './views/code-improvement/code-improvement.component';
import { CodeDocumentationComponent } from './views/code-documentation/code-documentation.component';
import { ProductDevelopmentComponent } from './views/product-development/product-development.component';
import { NetworkingCommunityComponent } from './views/networking-community/networking-community.component';
import { MobileSolutionComponent } from './views/mobile-solution/mobile-solution.component';
import { DataProcessingComponent } from './views/data-processing/data-processing.component';

import { AuthGuard } from '../auth/services/auth.guard';

import { SERVICES_CONSTANTS } from './constants';


const servicesRoutes: Routes = [
    { path: SERVICES_CONSTANTS.LINK.OVERVIEW, component: OverviewComponent },
    { path: SERVICES_CONSTANTS.LINK.ONLINE_MARKET, component: OnlineMarketComponent },
    { path: SERVICES_CONSTANTS.LINK.INTERANET_SOLUTION, component: InteranetSolutionComponent },
    { path: SERVICES_CONSTANTS.LINK.TESTING_SERVICES, component: TestingServicesComponent },
    { path: SERVICES_CONSTANTS.LINK.DESKTOP_SOLUTION, component: DesktopSolutionComponent },
    { path: SERVICES_CONSTANTS.LINK.CMS_IMPLEMENT, component: CmsImplementComponent },
    { path: SERVICES_CONSTANTS.LINK.CMS_WEBSITE, component: CmsWebsiteComponent },
    { path: SERVICES_CONSTANTS.LINK.CODE_IMPROVEMENT, component: CodeImprovementComponent },
    { path: SERVICES_CONSTANTS.LINK.CODE_DOCUMENTATION, component: CodeDocumentationComponent },
    { path: SERVICES_CONSTANTS.LINK.PRODUCT_DEVELOPMENT, component: ProductDevelopmentComponent },
    { path: SERVICES_CONSTANTS.LINK.NETWORKING_COMMUNITY, component: NetworkingCommunityComponent },
    { path: SERVICES_CONSTANTS.LINK.MOBILE_SOLUTION, component: MobileSolutionComponent },
    { path: SERVICES_CONSTANTS.LINK.DATA_PROCESSING, component: DataProcessingComponent },
];

@NgModule({
    imports: [RouterModule.forChild(servicesRoutes)],
    exports: [RouterModule]
})
export class ServicesRoutingModule {}

import { NgModule }             from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FixedPriceComponent } from './views/fixed-price/fixed-price.component';
import { TimeMaterialComponent } from './views/time-material/time-material.component';
import { HybridComponent } from './views/hybrid/hybrid.component';
import { BotComponent } from './views/bot/bot.component';
import { CoWorkComponent } from './views/co-work/co-work.component';
import { AgileComponent } from './views/agile/agile.component';

import { AuthGuard } from '../auth/services/auth.guard';

import { ENGAGEMENT_CONSTANTS } from './constants';


const engagementRoutes: Routes = [
    { path: ENGAGEMENT_CONSTANTS.LINK.FIXED_PRICE, component: FixedPriceComponent },
    { path: ENGAGEMENT_CONSTANTS.LINK.TIME_MATERIAL, component: TimeMaterialComponent },
    { path: ENGAGEMENT_CONSTANTS.LINK.HYBRID, component: HybridComponent },
    { path: ENGAGEMENT_CONSTANTS.LINK.BOT, component: BotComponent },
    { path: ENGAGEMENT_CONSTANTS.LINK.CO_WORK, component: CoWorkComponent },
    { path: ENGAGEMENT_CONSTANTS.LINK.AGILE, component: AgileComponent },
];

@NgModule({
    imports: [RouterModule.forChild(engagementRoutes)],
    exports: [RouterModule]
})
export class EngagementRoutingModule {}

import { NgModule }            from '@angular/core';
import { CommonModule }        from '@angular/common';
import {TranslateModule}       from '@ngx-translate/core';
import {HttpClientModule,HttpClient} from '@angular/common/http';
import { FormsModule }   from '@angular/forms';
import { TemplateModule} from '../template/template.module';
import { ClientsRoutingModule }   from './clients-routing.module';
import { OverviewComponent } from './views/overview/overview.component';
import { ClientEngagementsComponent } from './views/client-engagements/client-engagements.component';
import { DevelopmentCycleComponent } from './views/development-cycle/development-cycle.component';
import { FlexibleTermsComponent } from './views/flexible-terms/flexible-terms.component';
import { TestimonialsComponent } from './views/testimonials/testimonials.component';

@NgModule({
    declarations: [
        OverviewComponent,
        ClientEngagementsComponent,
        DevelopmentCycleComponent,
        FlexibleTermsComponent,
        TestimonialsComponent,
    ],
    imports: [
        TranslateModule,
        HttpClientModule,
        CommonModule,
        FormsModule,
        TemplateModule,
        ClientsRoutingModule,
    ],
    exports: [OverviewComponent,ClientEngagementsComponent,DevelopmentCycleComponent,FlexibleTermsComponent,TestimonialsComponent,],
    providers: [],
    bootstrap: [OverviewComponent,ClientEngagementsComponent,DevelopmentCycleComponent,FlexibleTermsComponent,TestimonialsComponent,]
})
export class ClientsModule { }

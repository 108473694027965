<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>CMS Implementation</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <p class="mb-4">Indian Technologies Dreams claims a strong expertise in the web Content Management System space. The company has deployed several portals on its homegrown CMS called Activesite (Java) and has also carried out implementations using open source CMS like Alfresco (Java) & Joomla (PHP) and. Clients from different domains have expressed faith in the CMS solution provided by Indian Technologies Dreams viz. content rich community portals, news agency portals, e-governance portal applications etc.</p>
                </div>
            </div>
        </div>
    </div>
</div>
export const NEWS_EVENTS_CONSTANTS = {
    URL: {
        NEWS_EVENTS_NEWS: '/news-events/news',
        NEWS_EVENTS_EVENTS: '/news-events/events',
    },
    LINK: {
        NEWS: 'news',
        EVENTS: 'events',
    },
    LABEL:{
        NEWS: 'News',
        EVENTS: 'Events',
    },
    API:{

    }
};
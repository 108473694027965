<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>Why Us Overview</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <p class="mb-4">The Indian Technologies Dreams is stringent engineering processes and quality standards to ensure maximum value add to the end product. We provide the following value proposition to our client:</p>
                    <ul>
                        <li>Faster and more affordable to purchase an off-the-shelf solution</li>
                        <li>The programming language (Java, C++), the programming framework (.NET, J2EE), or a particular kind of software system (Business Intelligence, Customer Relationship Management, or CRM). In most cases the architecture is defined by the development requirements</li>
                        <li>Installing and configuring software into the system environment, as well as training end users and IS or IT to support and maintain the software moving forward.</li>
                        <li>Configuration management (CM) refers to documentation of code, as well as errors and requested changes.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>Competencies</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <h4 class="mb-4">Our core competencies are:</h4>
                    <ul>
                        <li>Outsourced custom software development in open source and enterprise Java,.Net,Flex.</li>
                        <li>Driven towards bringing the rigor,quality and ownership of product development to a services engagement.</li>
                        <li>Excellent competency in web Technologies like AJAX,DHTML and Java Script.</li>
                        <li>Web 2.0 + RIA and concepts.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>Quality</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <p class="mb-4">Quality, like integrity, is not negotiable. These were the words of Mr. Ajmal Pasha when Indian Technologies Dreams. launched Feb 2009. It re-iterated the ruthless and uncompromising approach that the organization follows towards quality.</p>
                    <p class="mb-4">Our Quality Assurance Processes combine the best of standard software engineering processes to deliver predictable quality to the client. Our engineering processes are constantly improving so that we produce quality software, faster. We constantly implement best practices that are adopted or recognized by the industry so as to ensure maximum value addition to our client engagements and Lighting comes under an umbrella called "Mission Quality". The word "Mission" is consciously introduced and repeatedly stressed upon, to communicate the missionary zeal that was required to achieve a paradigm shift in our approach to quality. The philosophy was to make quality everybody's business - not just in products but in every aspect of work.</p>
                    <p class="mb-4">We have grown both organically and through acquisitions, customer centric. Our Quality is embedded at all manufacturing and distribution facilities. At the operational level, building in a culture of Quality consciousness is realized through ISO certification for processes and product certification for critical products.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>System analysis & Design</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <p class="mb-4">Systems analysis is effective when all sides of the problem are reviewed. Systems design is most effective when more than one solution can be proposed. The plans for the care and feeding of a new system are as important as the problems they solve.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>Maintenance and Support</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <p class="mb-4">Indian Technologies Dreams has a unique blend of web site programmers web site designers that ensures you quality work in quick time. Our web site design and development services guarantee hassle-free software development from conception to conceptualization at throw-away budget. As a result we have been blessed with a great base of clients that involve us in some exciting projects. Our growing list of clientele and proud portfolio suggest the same and vouch safes authentic service from expert and qualified professionals.</p>
                    <p class="mb-4">We, at Indian Technologies Dreams offer an array of web design services like Web site Design, Software, Custom Web site development & Graphic Design Services in order to enhance the corporate image of our clients. A strong web site must be developed with careful planning, which should be visually appealing spreading out its distinctive flavors and colors across the world. We believes in creations that not just only stand out due to its amiable look and matchless splendor, but can also effectively draw the target audience.</p>
                </div>
            </div>
        </div>
    </div>
</div>
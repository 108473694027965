import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import { Title }     from '@angular/platform-browser';

import { APP_CONFIG } from '../../../constants';
import { CLIENTS_CONSTANTS } from '../../constants';

@Component({
  selector: 'flexible-terms-root',
  templateUrl: './flexible-terms.component.html',
  styleUrls: []
})
export class FlexibleTermsComponent {
    CONFIG=APP_CONFIG;
    CLIENTS_CONSTANTS=CLIENTS_CONSTANTS;
    constructor(private titleService: Title) {
        this.CONFIG=APP_CONFIG;
        this.CLIENTS_CONSTANTS=CLIENTS_CONSTANTS;
        this.titleService.setTitle( APP_CONFIG.APP_NAME+ " :: "+ this.CLIENTS_CONSTANTS.LABEL.FLEXIBLE_TERMS);
    }
}
export const MOBILE_TECHNOLOGIES_CONSTANTS = {
    URL: {
        MOBILE_TECHNOLOGIES_NATIVE: '/mobile-technologies/native',
        MOBILE_TECHNOLOGIES_XAMARIN: '/mobile-technologies/xamarin',
        MOBILE_TECHNOLOGIES_TITANIUM_HYBRID: '/mobile-technologies/titanium-hybrid',
        MOBILE_TECHNOLOGIES_IONIC: '/mobile-technologies/ionic',
        MOBILE_TECHNOLOGIES_REACT_NATIVE: '/mobile-technologies/react-native',
        MOBILE_TECHNOLOGIES_FRAMEWORK_7: '/mobile-technologies/framework-7',
    },
    LINK: {
        NATIVE: 'native',
        XAMARIN: 'xamarin',
        TITANIUM_HYBRID: 'titanium-hybrid',
        IONIC: 'ionic',
        REACT_NATIVE: 'react-native',
        FRAMEWORK_7: 'framework-7',
    },
    LABEL:{
        NATIVE: 'Native',
        XAMARIN: 'Xamarin',
        TITANIUM_HYBRID: 'Titanium Hybrid',
        IONIC: 'Ionic (Hybrid)',
        REACT_NATIVE: 'React Native',
        FRAMEWORK_7: 'Framework7 Hybrid',
    },
    API:{

    }
};
import { NgModule }            from '@angular/core';
import { CommonModule }        from '@angular/common';
import {TranslateModule}       from '@ngx-translate/core';
import {HttpClientModule,HttpClient} from '@angular/common/http';
import { FormsModule }   from '@angular/forms';
import { TemplateModule} from '../template/template.module';
import { TechnologiesRoutingModule }   from './technologies-routing.module';
import { OverviewComponent } from './views/overview/overview.component';
import { TechnologyComponent } from './views/technology/technology.component';


@NgModule({
    declarations: [
        OverviewComponent,
        TechnologyComponent,
    ],
    imports: [
        TranslateModule,
        HttpClientModule,
        CommonModule,
        FormsModule,
        TemplateModule,
        TechnologiesRoutingModule,
    ],
    exports: [OverviewComponent,TechnologyComponent,],
    providers: [],
    bootstrap: [OverviewComponent,TechnologyComponent,]
})
export class TechnologiesModule { }

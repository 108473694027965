    <div class="latest-from-the-blog">
        <div class="heading-block text-center">
            <h3 class="heading-title">Highlights</h3>
        </div>
    </div>
    <div class="latest-blog-data">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-lg-3 pb-5">
                    <div class="entry">
                        <div class="entry-image">
                            <a href="#"><img src="assets/images/computer.jpg"></a>
                        </div>
                        <div class="entry-title">
                            <h3 class="size14 mb-0">
                                <a href="#">Core competencies </a>
                            </h3>
                        </div>
                        <div class="entry-meta">
                            <div class="row">
                                <div class="col-7"><i class="fa fa-calendar" aria-hidden="true"></i> 13th Jun 2021</div>
                                <div class="col-5"><a href="#"><i class="fa fa-comments" aria-hidden="true"></i> 53</a></div>
                            </div>
                        </div>
                        <div class="entry-content">
                            <p>Outsourced custom software development in open source and enterprise Java,.Net,Flex.</p>
                            <p>Driven towards bringing the rigor,quality and ownership of product development to a services engagement.</p>
                            <p>Excellent competency in web Technologies like AJAX,DHTML and JavaScript.</p>
                            <p>Web 2.0 + RIA and concepts.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 pb-5">
                    <div class="entry">
                        <div class="entry-image">
                            <a href="#"><img src="assets/images/computer1.jpg"></a>
                        </div>
                        <div class="entry-title">
                            <h3 class="size14 mb-0">
                                <a href="#">Quality, like integrity, is not negotiable.</a>
                            </h3>
                        </div>
                        <div class="entry-meta">
                            <div class="row">
                                <div class="col-7"><i class="fa fa-calendar" aria-hidden="true"></i> 24th Mar 2022</div>
                                <div class="col-5"><a href="#"><i class="fa fa-comments" aria-hidden="true"></i> 17</a></div>
                            </div>
                        </div>
                        <div class="entry-content">
                            <p>Our Quality Assurance Processes combine the best of standard software engineering processes to deliver predictable quality to the client. Our engineering processes are constantly improving so that we produce quality software, faster. We constantly implement best practices that are adopted or recognized by the industry so as to ensure maximum value addition to our client engagements and Lighting comes under an umbrella called "Mission Quality".</p>

                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 pb-5">
                    <div class="entry">
                        <div class="entry-image">
                            <a href="#"><img src="assets/images/computer2.jpg"></a>
                        </div>
                        <div class="entry-title">
                            <h3 class="size14 mb-0">
                                <a href="#">System analysis & Design</a>
                            </h3>
                        </div>
                        <div class="entry-meta">
                            <div class="row">
                                <div class="col-7"><i class="fa fa-calendar" aria-hidden="true"></i> 30th Dec 2021</div>
                                <div class="col-5"><a href="#"><i class="fa fa-comments" aria-hidden="true"></i> 13</a></div>
                            </div>
                        </div>
                        <div class="entry-content">
                            <p>Systems analysis is effective when all sides of the problem are reviewed. Systems design is most effective when more than one solution can be proposed. The plans for the care and feeding of a new system are as important as the problems they solve.</p>

                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 pb-5">
                    <div class="entry">
                        <div class="entry-image">
                            <a href="#"><img src="assets/images/computer3.jpg"></a>
                        </div>
                        <div class="entry-title">
                            <h3 class="size14 mb-0">
                                <a href="#">Maintenance and Support</a>
                            </h3>
                        </div>
                        <div class="entry-meta">
                            <div class="row">
                                <div class="col-7"><i class="fa fa-calendar" aria-hidden="true"></i> 18th Jan 2022</div>
                                <div class="col-5"><a href="#"><i class="fa fa-comments" aria-hidden="true"></i> 54</a></div>
                            </div>
                        </div>
                        <div class="entry-content">
                            <p>Indian Technologies Dreams has a unique blend of web site programmers web site designers that ensures you quality work in quick time. Our web site design and development services guarantee hassle-free software development from conception to conceptualization at throw-away budget. As a result we have been blessed with a great base of clients that involve us in some exciting projects. Our growing list of clientele and proud portfolio suggest the same and vouch safes authentic service from expert and qualified professionals.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

<header *ngIf="!currentUser || !isAdmin">

    <nav class="navbar navbar-expand-lg navbar-light bg-light header-navbar">
        <a class="navbar-brand brand-logo" href="#"><img src="assets/images/itd-logo.png"></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse background-unset-header options-for-navbar" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto mt-2 mt-lg-0 h-100 align-items-center complete-list-header">
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle text-uppercase header-part-link" (click)="callOverview();" id="navbarDropdownAboutUs" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        About Us
                    </a>
                    <div class="dropdown-menu header-dropdown-lists" aria-labelledby="navbarDropdownAboutUs">
                        <a class="dropdown-item header-dropdown-list" (click)="callOverview();">Overview</a>
                        <a class="dropdown-item header-dropdown-list" (click)="callVisionMission();">Vision & Mission</a>
                        <a class="dropdown-item header-dropdown-list" (click)="callManagementTeam();">Management Team</a>
                    </div>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle text-uppercase header-part-link" (click)="callServicesOverview();" id="navbarDropdownServices" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Services
                    </a>
                    <div class="dropdown-menu header-dropdown-lists services-dropdown-lists" aria-labelledby="navbarDropdownServices">
                        <div class="container p-0">
                            <div class="row m-0">
                                <div class="service-list">
                                    <a class="dropdown-item header-dropdown-list" (click)="callServicesOverview();">Overview</a>
                                    <a class="dropdown-item header-dropdown-list" (click)="callOnlineMarket();">Online Market Solutions</a>
                                    <a class="dropdown-item header-dropdown-list" (click)="callInteranetSolution();">Interanet Solution</a>
                                    <a class="dropdown-item header-dropdown-list" (click)="callTestingServices();">Testing Services</a>
                                    <a class="dropdown-item header-dropdown-list" (click)="callDesktopSolution();">Desktop Solution</a>
                                </div>
                                <div class="service-list">
                                    <a class="dropdown-item header-dropdown-list" (click)="callCmsImplement();">CMS Implementation</a>
                                    <a class="dropdown-item header-dropdown-list" (click)="callCmsWebsite();">CMS Based Websites</a>
                                    <a class="dropdown-item header-dropdown-list" (click)="callCodeImprovement();">Code Improvement</a>
                                    <a class="dropdown-item header-dropdown-list" (click)="callCodeDocumentation();">Code Documentation</a>
                                    <a class="dropdown-item header-dropdown-list" (click)="callProductDevelopment();">Product Development</a>
                                </div>
                                <div class="service-list">
                                    <a class="dropdown-item header-dropdown-list" (click)="callNetworkingCommunity();">Networking & Community</a>
                                    <a class="dropdown-item header-dropdown-list" (click)="callMobileSolution();">Mobile Solution</a>
                                    <a class="dropdown-item header-dropdown-list" (click)="callDataProcessing();">Data Processing</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle text-uppercase header-part-link" (click)="callWhyUsOverview();" id="navbarDropdownWhyUs" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Why Us
                    </a>
                    <div class="dropdown-menu header-dropdown-lists" aria-labelledby="navbarDropdownWhyUs">
                        <a class="dropdown-item header-dropdown-list" (click)="callWhyUsOverview();">Overview</a>
                        <a class="dropdown-item header-dropdown-list" (click)="callCompetencies();">Competencies</a>
                        <a class="dropdown-item header-dropdown-list" (click)="callQuality();">Quality</a>
                        <a class="dropdown-item header-dropdown-list" (click)="callSystemAnalysis();">System analysis & Design</a>
                        <a class="dropdown-item header-dropdown-list" (click)="callMaintenanceSupport();">Maintenance & Support</a>
                    </div>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle text-uppercase header-part-link" (click)="callClientsOverview();" id="navbarDropdownClients" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Clients
                    </a>
                    <div class="dropdown-menu header-dropdown-lists" aria-labelledby="navbarDropdownClients">
                        <a class="dropdown-item header-dropdown-list" (click)="callClientsOverview();">Overview</a>
                        <a class="dropdown-item header-dropdown-list" (click)="callClientEngagements();">Client Engagements</a>
                        <a class="dropdown-item header-dropdown-list" (click)="callDevelopmentCycle();">Development Cycle</a>
                        <a class="dropdown-item header-dropdown-list" (click)="callFlexibleTerms();">Flexible terms</a>
                        <a class="dropdown-item header-dropdown-list" (click)="callTestimonials();">Testimonials</a>
                    </div>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle text-uppercase header-part-link" (click)="callTechnologiesOverview();" id="navbarDropdownTech" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Technologies
                    </a>
                    <div class="dropdown-menu header-dropdown-lists" aria-labelledby="navbarDropdownTech">
                        <a class="dropdown-item header-dropdown-list" (click)="callTechnologiesOverview();">Overview</a>
                        <a class="dropdown-item header-dropdown-list" (click)="callTechnologiesTechnology();">Technology</a>
                    </div>
                </li>
                <li class="nav-item">
                    <a class="nav-link text-uppercase header-part-link" (click)="callCareers();" role="button">Careers</a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle text-uppercase header-part-link" (click)="callNews();" id="navbarDropdownNews" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        News & Events
                    </a>
                    <div class="dropdown-menu header-dropdown-lists news-dropdown-lists" aria-labelledby="navbarDropdownNews">
                        <a class="dropdown-item header-dropdown-list" (click)="callNews();">News</a>
                        <a class="dropdown-item header-dropdown-list" (click)="callEvents();">Events</a>
                    </div>
                </li>
            </ul>
        </div>
    </nav>

</header>

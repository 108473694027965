<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>CMS Based Websites</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <p class="mb-4">All of the aforementioned sites host a database and a Content Management System (CMS) to manage the contents i.e. text and images of these website just the way you would want wish to manage various sections on your website. We have designed and continually maintain several corporate websites and can surely look at designing and developing one for you.</p>
                    <h4 class="mb-4">Benefits of CMS</h4>
                    <ul>
                        <li>Our customized CMS solutions result in better performance, save your precious time and money. You don't need to have extra staff for making regular changes and management of your website - and editing of content will become just simple as typing a document and saving it - similar to the MS word.</li>
                        <li>Our tailored made CMS systems keep your website up-to-date that results in competitive advantage and you can gain leadership in your Internet presence that in turns give prosperity in your business.</li>
                        <li>Our well coupled CMS solutions help you to manage website navigation, website structure, dynamic elements, content, tables and web-forms.</li>
                    </ul>
                    <p class="mb-4">The Content Management System is a web based software application that helps you to manage your website content with great ease through a web based browser application. We at Indian Technologies Dreams, offer you a choice between our Standard Open Source CMS Solution and Professional CMS Solution. We have the expertise and knowledge in various CMS like Drupal, Jhoomla etc, which gives us the flexibility to provide you with solutions and a professional user interface according to your requirements.</p>
                </div>
            </div>
        </div>
    </div>
</div>
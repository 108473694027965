<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>Mobile Solution</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <p class="mb-4">Indian Technologies Dreams has wide experience in Mobile Application Development. We provide Mobile Application Development platform including Windows Mobile Application, Mobile enabled website development, J2ME based Mobile Application Development on Nokia, Samsung, LG phones, Android - Google mobile hosted solution, iPhone Mobile Website Development, iPhone Mobile Application Development, we helps clients to realize the benefits of Enterprise Mobile Application Development Platform.</p>
                    <p class="mb-4">Indian Technologies Dreams offshore cost and scalability advantage to significantly reduce development cost across various mobile Application Developments. We provide high quality, time bound, cost effective outsourcing IT services through our offshore facilities in India.</p>
                    <p class="mb-4">As the businesses are growing far and wide, the need for proper Communication with the field executives has increased by many folds. The need to connect to people at all times is rapidly growing now. Deals click in an instant provided if right information is available at the right place, no matter where this place is.</p>
                    <p class="mb-4">That means Information needs to be omnipresent today which is possible now through instant access to real-time data using Wireless/ Mobile Solutions. Semaphore has developed expertise in Mobile Application Development that have helped companies improve workflow, customer order taking, and record keeping in the front office.</p>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import { Title }     from '@angular/platform-browser';

import { APP_CONFIG } from '../../../constants';
import { SERVICES_CONSTANTS } from '../../constants';

@Component({
  selector: 'product-development-root',
  templateUrl: './product-development.component.html',
  styleUrls: []
})
export class ProductDevelopmentComponent {
    CONFIG=APP_CONFIG;
    SERVICES_CONSTANTS=SERVICES_CONSTANTS;
    constructor(private titleService: Title) {
        this.CONFIG=APP_CONFIG;
        this.SERVICES_CONSTANTS=SERVICES_CONSTANTS;
        this.titleService.setTitle( APP_CONFIG.APP_NAME+ " :: "+ this.SERVICES_CONSTANTS.LABEL.PRODUCT_DEVELOPMENT);
    }
}
export const ENGAGEMENT_CONSTANTS = {
    URL: {
        ENGAGEMENT_FIXED_PRICE: '/engagement/fixed-price',
        ENGAGEMENT_TIME_MATERIAL: '/engagement/time-material',
        ENGAGEMENT_HYBRID: '/engagement/hybrid',
        ENGAGEMENT_BOT: '/engagement/bot',
        ENGAGEMENT_CO_WORK: '/engagement/co-work',
        ENGAGEMENT_AGILE: '/engagement/agile',
    },
    LINK: {
        FIXED_PRICE: 'fixed-price',
        TIME_MATERIAL: 'time-material',
        HYBRID: 'hybrid',
        BOT: 'bot',
        CO_WORK: 'co-work',
        AGILE: 'agile',
    },
    LABEL:{
        FIXED_PRICE: 'Fixed Price',
        TIME_MATERIAL: 'Time Material',
        HYBRID: 'Hybrid',
        BOT: 'BOT',
        CO_WORK: 'Co-Work',
        AGILE: 'Agile',
    },
    API:{

    }
};
import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'admin-root',
  templateUrl: './admin.component.html',
  styleUrls: []
})
export class AdminComponent {
    constructor() {
    }
}

<!-- start: FORGOT -->
<div class="row">
    <div class="main-login col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2 col-md-4 col-md-offset-4 col-lg-4 col-lg-offset-4">
        <div class="logo text-center">
            <img src="{{CONFIG.IMAGE_URL}}/images/logo.png" alt="{{CONFIG.APP_NAME}}" class="img-responsive" />
        </div>
        <p class="text-center text-dark text-bold text-extra-large margin-top-15" >{{"auth.view.forgotTitle" | translate }}</p>
        <alert-message></alert-message>
        <p class="text-center" >{{ "auth.view.forgotText" | translate }}</p>
        <!-- start: FORGOT BOX -->
        <div class="box-forgot">
            <form class="form-forgot" name="forgotForm" role="form" (ngSubmit)="f.form.valid && forgot(forgotUser,f)" #f="ngForm" novalidate>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !email.valid }">
                    <input type="email" class="form-control" name="email" placeholder="Email"  [(ngModel)]="forgotUser.email" #email="ngModel" required>
                    <span class="error text-small block" *ngIf="f.submitted && !email.valid">{{ "forgot.error.email" | translate }}</span>
                </div>
                <div class="form-actions">
                    <button type="submit" class="btn btn-red btn-block" [disabled]="loading" >{{ "auth.view.submit" | translate }}</button>
                </div>
            </form>
            <div class="text-center margin-top-15" >
                <span [innerHtml]="'auth.view.learnTo' | translate"></span><a (click)="forgotHelp();"><span>{{ "auth.view.retrieveAccount" | translate }}</span></a>
            </div>
            <p class="text-center">
                <span>{{"auth.view.useAccount" | translate }}</span>
                <a [routerLink]="[AUTH_CONSTANTS.URL.LOGIN]">{{"auth.view.logIn" | translate }}</a>
            </p>
        </div>
        <!-- end: FORGOT BOX -->
    </div>
</div>
<!-- end: FORGOT -->
<forgot-help-modal></forgot-help-modal>
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { APP_CONFIG } from '../../constants';
import { AUTH_CONSTANTS } from '../../auth/constants';
import { APP_CONSTANTS } from '../../constants';
import { RouterLink } from '@angular/router';
import {Router} from '@angular/router';

import { AuthenticationService } from '../../auth/services/authentication.service';
import { AuthorizationService } from '../../auth/services/authorization.service';
import { LanguageSwitcherComponent } from '../../shared/directives/language-switcher/language-switcher.component';
import { ABOUT_US_CONSTANTS } from '../../about-us/constants';
import { TECHNOLOGIES_CONSTANTS } from '../../technologies/constants';
import { NEWS_EVENTS_CONSTANTS } from '../../news-events/constants';
import { MAIN_CONSTANTS } from '../../main/constants';
import { CLIENTS_CONSTANTS } from '../../clients/constants';
import { WHY_US_CONSTANTS } from '../../why-us/constants';
import { SERVICES_CONSTANTS } from '../../services/constants';

@Component({
  selector: 'template-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class HeaderComponent {
    currentUser = undefined;
    CONFIG=APP_CONFIG;
    AUTH_CONSTANTS=AUTH_CONSTANTS;
    APP_CONSTANTS=APP_CONSTANTS;
    isAdmin=false;
    constructor(private authenticationService: AuthenticationService,
                private authorizationService: AuthorizationService,
                private router: Router) {
        this.CONFIG=APP_CONFIG;
        this.AUTH_CONSTANTS=AUTH_CONSTANTS;
        this.APP_CONSTANTS=APP_CONSTANTS;
        this.authenticationService.sessionChange$.subscribe(
            value => {
                this.currentUser= authenticationService.getCurrentUser();
            }
        );
        this.currentUser= authenticationService.getCurrentUser();

        this.authorizationService.pageChange$.subscribe(
            value => {
                this.isAdmin= authorizationService.isPageAdmin();
            }
        );
        this.currentUser= authenticationService.getCurrentUser();
    }

    callOverview() {
        this.router.navigate([ABOUT_US_CONSTANTS.URL.ABOUT_US_OVERVIEW]);
    }
    callVisionMission() {
        this.router.navigate([ABOUT_US_CONSTANTS.URL.ABOUT_US_VISION_MISSION]);
    }
    callManagementTeam() {
        this.router.navigate([ABOUT_US_CONSTANTS.URL.ABOUT_US_MANAGEMENT_TEAM]);
    }
    callTechnologiesOverview() {
        this.router.navigate([TECHNOLOGIES_CONSTANTS.URL.TECHNOLOGIES_OVERVIEW]);
    }
    callTechnologiesTechnology() {
        this.router.navigate([TECHNOLOGIES_CONSTANTS.URL.TECHNOLOGIES_TECHNOLOGY]);
    }
    callNews() {
        this.router.navigate([NEWS_EVENTS_CONSTANTS.URL.NEWS_EVENTS_NEWS]);
    }
    callEvents() {
        this.router.navigate([NEWS_EVENTS_CONSTANTS.URL.NEWS_EVENTS_EVENTS]);
    }
    callCareers() {
        this.router.navigate([MAIN_CONSTANTS.URL.CAREERS]);
    }
    callClientsOverview() {
        this.router.navigate([CLIENTS_CONSTANTS.URL.CLIENTS_OVERVIEW]);
    }
    callClientEngagements() {
        this.router.navigate([CLIENTS_CONSTANTS.URL.CLIENTS_CLIENT_ENGAGEMENTS]);
    }
    callDevelopmentCycle() {
        this.router.navigate([CLIENTS_CONSTANTS.URL.CLIENTS_DEVELOPMENT_CYCLE]);
    }
    callFlexibleTerms() {
        this.router.navigate([CLIENTS_CONSTANTS.URL.CLIENTS_FLEXIBLE_TERMS]);
    }
    callTestimonials() {
        this.router.navigate([CLIENTS_CONSTANTS.URL.CLIENTS_TESTIMONIALS]);
    }
    callWhyUsOverview() {
        this.router.navigate([WHY_US_CONSTANTS.URL.WHY_US_OVERVIEW]);
    }
    callCompetencies() {
        this.router.navigate([WHY_US_CONSTANTS.URL.WHY_US_COMPETENCIES]);
    }
    callQuality() {
        this.router.navigate([WHY_US_CONSTANTS.URL.WHY_US_QUALITY]);
    }
    callSystemAnalysis() {
        this.router.navigate([WHY_US_CONSTANTS.URL.WHY_US_SYSTEM_ANALYSIS]);
    }
    callMaintenanceSupport() {
        this.router.navigate([WHY_US_CONSTANTS.URL.WHY_US_MAINTENANCE_SUPPORT]);
    }
    callServicesOverview() {
        this.router.navigate([SERVICES_CONSTANTS.URL.SERVICES_OVERVIEW]);
    }
    callOnlineMarket() {
        this.router.navigate([SERVICES_CONSTANTS.URL.SERVICES_ONLINE_MARKET]);
    }
    callInteranetSolution() {
        this.router.navigate([SERVICES_CONSTANTS.URL.SERVICES_INTERANET_SOLUTION]);
    }
    callTestingServices() {
        this.router.navigate([SERVICES_CONSTANTS.URL.SERVICES_TESTING_SERVICES]);
    }
    callDesktopSolution() {
        this.router.navigate([SERVICES_CONSTANTS.URL.SERVICES_DESKTOP_SOLUTION]);
    }
    callCmsImplement() {
        this.router.navigate([SERVICES_CONSTANTS.URL.SERVICES_CMS_IMPLEMENT]);
    }
    callCmsWebsite() {
        this.router.navigate([SERVICES_CONSTANTS.URL.SERVICES_CMS_WEBSITE]);
    }
    callCodeImprovement() {
        this.router.navigate([SERVICES_CONSTANTS.URL.SERVICES_CODE_IMPROVEMENT]);
    }
    callCodeDocumentation() {
        this.router.navigate([SERVICES_CONSTANTS.URL.SERVICES_CODE_DOCUMENTATION]);
    }
    callProductDevelopment() {
        this.router.navigate([SERVICES_CONSTANTS.URL.SERVICES_PRODUCT_DEVELOPMENT]);
    }
    callNetworkingCommunity() {
        this.router.navigate([SERVICES_CONSTANTS.URL.SERVICES_NETWORKING_COMMUNITY]);
    }
    callMobileSolution() {
        this.router.navigate([SERVICES_CONSTANTS.URL.SERVICES_MOBILE_SOLUTION]);
    }
    callDataProcessing() {
        this.router.navigate([SERVICES_CONSTANTS.URL.SERVICES_DATA_PROCESSING]);
    }

}

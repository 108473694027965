import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { FormsModule }    from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import {Routes, RouterModule} from '@angular/router';
import { AppComponent } from './app.component';
import { AppRoutingModule} from './app-routing.module';
import { TemplateModule} from './template/template.module';
import { SharedModule} from './shared/shared.module';
import { MainModule} from './main/main.module';
import { AuthModule} from './auth/auth.module';
import { EngagementModule} from './engagement/engagement.module';
import { OurServicesModule} from './our-services/our-services.module';
import { WorkingDomainModule} from './working-domain/working-domain.module';
import { WebTechnologiesModule} from './web-technologies/web-technologies.module';
import { MobileTechnologiesModule} from './mobile-technologies/mobile-technologies.module';
import { FrontendTechnologiesModule} from './frontend-technologies/frontend-technologies.module';
import { AboutUsModule} from './about-us/about-us.module';
import { TechnologiesModule} from './technologies/technologies.module';
import { NewsEventsModule} from './news-events/news-events.module';
import { ClientsModule} from './clients/clients.module';
import { WhyUsModule} from './why-us/why-us.module';
import { ServicesModule} from './services/services.module';

import { AuthenticationService } from './auth/services/authentication.service';
import { AuthorizationService } from './auth/services/authorization.service';
import { HttpClientService } from './shared/services/http-client.service';
import { RequestInterceptor } from './auth/services/request-interceptor.interceptor';


export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
      BrowserModule,
      FormsModule,
      TranslateModule.forRoot({
          loader: {
              provide: TranslateLoader,
              useFactory: (createTranslateLoader),
              deps: [HttpClient]
          }
      }),
      RouterModule,
      TemplateModule,
      SharedModule,
      MainModule,
      AuthModule,
      AppRoutingModule,
      EngagementModule,
      OurServicesModule,
      WorkingDomainModule,
      WebTechnologiesModule,
      MobileTechnologiesModule,
      FrontendTechnologiesModule,
      AboutUsModule,
      TechnologiesModule,
      NewsEventsModule,
      ClientsModule,
      WhyUsModule,
      ServicesModule,
  ],
  providers: [
      CookieService,
      HttpClientService,
      AuthenticationService,
      AuthorizationService,
      {provide: HTTP_INTERCEPTORS,useClass: RequestInterceptor,multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { NgModule }             from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './views/home/home.component';
import { ProductComponent } from './views/product/product.component';
import { Page404Component } from './views/page404/page404.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { EngagementModelComponent } from './views/engagement-model/engagement-model.component';
import { WorkingDomainComponent } from './views/working-domain/working-domain.component';
import { OurServicesComponent } from './views/our-services/our-services.component';
import { WebTechnologiesComponent } from './views/web-technologies/web-technologies.component';
import { FrontendTechnologiesComponent } from './views/frontend-technologies/frontend-technologies.component';
import { MobileTechnologiesComponent } from './views/mobile-technologies/mobile-technologies.component';
import { CareersComponent } from './views/careers/careers.component';

import { AuthGuard } from '../auth/services/auth.guard';

import { MAIN_CONSTANTS } from './constants';


const mainRoutes: Routes = [
    { path: MAIN_CONSTANTS.LINK.HOME, component: HomeComponent },
    { path: MAIN_CONSTANTS.LINK.PRODUCT, component: ProductComponent },
    { path: MAIN_CONSTANTS.LINK.PAGE404, component: Page404Component },
    { path: MAIN_CONSTANTS.LINK.DASHBOARD, component: DashboardComponent,canActivate: [AuthGuard] },
    { path: MAIN_CONSTANTS.LINK.ENGAGEMENTMODEL, component: EngagementModelComponent },
    { path: MAIN_CONSTANTS.LINK.WORKINGDOMAIN, component: WorkingDomainComponent },
    { path: MAIN_CONSTANTS.LINK.OURSERVICES, component: OurServicesComponent },
    { path: MAIN_CONSTANTS.LINK.WEBTECHNOLOGIES, component: WebTechnologiesComponent },
    { path: MAIN_CONSTANTS.LINK.FRONTEND_TECHNOLOGIES, component: FrontendTechnologiesComponent },
    { path: MAIN_CONSTANTS.LINK.MOBILE_TECHNOLOGIES, component: MobileTechnologiesComponent },
    { path: MAIN_CONSTANTS.LINK.CAREERS, component: CareersComponent },
];

@NgModule({
    imports: [RouterModule.forChild(mainRoutes)],
    exports: [RouterModule]
})
export class MainRoutingModule {}

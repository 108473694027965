import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import { Title }     from '@angular/platform-browser';
import {Router} from '@angular/router';
import { APP_CONFIG } from '../../../constants';
import { MAIN_CONSTANTS } from '../../constants';
import { OUR_SERVICES_CONSTANTS } from '../../../our-services/constants';

@Component({
  selector: 'our-services-root',
  templateUrl: './our-services.component.html',
  styleUrls: []
})
export class OurServicesComponent {
    CONFIG=APP_CONFIG;
    MAIN_CONSTANTS=MAIN_CONSTANTS;
    constructor(private titleService: Title, private router: Router) {
        this.CONFIG=APP_CONFIG;
        this.MAIN_CONSTANTS=MAIN_CONSTANTS;
        this.titleService.setTitle( APP_CONFIG.APP_NAME+ " :: "+ this.MAIN_CONSTANTS.LABEL.OURSERVICES);
    }
    callManageItServices(){
        this.router.navigate([OUR_SERVICES_CONSTANTS.URL.OUR_SERVICES_MANAGE_IT_SERVICES]);
    }
    callSoftwareTechnologiesConsulting(){
        this.router.navigate([OUR_SERVICES_CONSTANTS.URL.OUR_SERVICES_SOFTWARE_TECHNOLOGIES_CONSULTING]);
    }
    callSoftwareProductDevelopment(){
        this.router.navigate([OUR_SERVICES_CONSTANTS.URL.OUR_SERVICES_SOFTWARE_PRODUCT_DEVELOPMENT]);
    }
    callProductMaintenance(){
        this.router.navigate([OUR_SERVICES_CONSTANTS.URL.OUR_SERVICES_PRODUCT_MAINTENANCE]);
    }
    callQualityAssurance(){
        this.router.navigate([OUR_SERVICES_CONSTANTS.URL.OUR_SERVICES_QUALITY_ASSURANCE]);
    }
    callMobilitySolution(){
        this.router.navigate([OUR_SERVICES_CONSTANTS.URL.OUR_SERVICES_MOBILITY_SOLUTION]);
    }
}
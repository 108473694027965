import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import { Title }     from '@angular/platform-browser';

import { APP_CONFIG } from '../../../constants';
import { MAIN_CONSTANTS } from '../../constants';

@Component({
  selector: 'careers-root',
  templateUrl: './careers.component.html',
  styleUrls: []
})
export class CareersComponent {
    CONFIG=APP_CONFIG;
    MAIN_CONSTANTS=MAIN_CONSTANTS;
    constructor(private titleService: Title) {
        this.CONFIG=APP_CONFIG;
        this.MAIN_CONSTANTS=MAIN_CONSTANTS;
        this.titleService.setTitle( APP_CONFIG.APP_NAME+ " :: "+ this.MAIN_CONSTANTS.LABEL.CAREERS);
    }
}
import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import { Title }     from '@angular/platform-browser';

import { APP_CONFIG } from '../../../constants';
import { MAIN_CONSTANTS } from '../../constants';

@Component({
  selector: 'home-root',
  templateUrl: './home.component.html',
  styleUrls: []
})
export class HomeComponent {
    CONFIG=APP_CONFIG;
    MAIN_CONSTANTS=MAIN_CONSTANTS;
    constructor(private titleService: Title) {
        this.CONFIG=APP_CONFIG;
        this.MAIN_CONSTANTS=MAIN_CONSTANTS;
        this.titleService.setTitle( APP_CONFIG.APP_NAME+ " :: "+ this.MAIN_CONSTANTS.LABEL.HOME);
    }
    ngOnInit() {
          (<any>$("#owl-carousel-our-things")).owlCarousel({
                  responsiveClass: true,
                  loop: true,
                  dots: false,
                  autoplay: true,
                  autoplayTimeout: 3000,
                  autoplayHoverPause: true,
                  responsive: {
                      320: {
                          items: 1
                      },

                      375: {
                          items: 1
                      },

                      414: {
                          items: 1
                      },

                      768: {
                          items: 2
                      },

                      1024: {
                          items: 3
                      },

                      1350: {
                          items: 3
                      }
                  },
            });

          (<any>$("#owl-carousel-homepage")).owlCarousel({
                responsiveClass: true,
                loop: true,
                dots: false,
                autoplay: true,
                autoplayTimeout: 3000,
                autoplayHoverPause: false,
                responsive: {
                    320: {
                        items: 1
                    },

                    375: {
                        items: 1
                    },

                    414: {
                        items: 1
                    },

                    768: {
                        items: 3
                    },

                    1024: {
                        items: 4
                    },

                    1350: {
                        items: 5
                    }
                },
          });

    }
}

export const MAIN_CONSTANTS = {
    URL: {
        HOME: '/home',
        PRODUCT: '/product',
        DASHBOARD: '/dashboard',
        ENGAGEMENTMODEL: '/engagement-model',
        WORKINGDOMAIN: '/working-domain',
        OURSERVICES: '/our-services',
        WEBTECHNOLOGIES: '/web-technologies',
        FRONTEND_TECHNOLOGIES: '/frontend-technologies',
        MOBILE_TECHNOLOGIES: '/mobile-technologies',
        CAREERS: '/careers',
    },
    LINK: {
        HOME: 'home',
        PRODUCT: 'product',
        DASHBOARD: 'dashboard',
        PAGE404: 'page-not-found-404-error',
        ENGAGEMENTMODEL: 'engagement-model',
        WORKINGDOMAIN: 'working-domain',
        OURSERVICES: 'our-services',
        WEBTECHNOLOGIES: 'web-technologies',
        FRONTEND_TECHNOLOGIES: 'frontend-technologies',
        MOBILE_TECHNOLOGIES: 'mobile-technologies',
        CAREERS: 'careers',
    },
    LABEL:{
        HOME: 'Home',
        DASHBOARD: 'Dashboard',
        PAGE404: 'Page Not Found',
        ENGAGEMENTMODEL: 'Engagement Model',
        WORKINGDOMAIN: 'Working Domain',
        OURSERVICES: 'Our Services',
        WEBTECHNOLOGIES: 'Web Technologies',
        FRONTEND_TECHNOLOGIES: 'Web Frontend Development Technologies',
        MOBILE_TECHNOLOGIES: 'Mobile Development Technologies',
        CAREERS: 'Careers',
    },
    API:{

    }
};
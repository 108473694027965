<!-- sidebar -->
<div class="sidebar app-aside hidden-print" id="sidebar" toggleable parent-active-class="app-slide-off">
    <div class="sidebar-container">
        <sidebar></sidebar>
    </div>
</div>
<!-- / sidebar -->
<div class="app-content">
    <!-- top navbar -->
    <top-navbar></top-navbar>
    <!-- / top navbar -->
    <!-- main content -->
    <div class="main-content">
        <div class="wrap-content container" id="container">
            <ng-content></ng-content>
        </div>
    </div>
    <!-- / main content -->
</div>

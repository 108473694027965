export const FRONTEND_TECHNOLOGIES_CONSTANTS = {
    URL: {
        FRONTEND_TECHNOLOGIES_ANGULAR: '/frontend-technologies/angular',
        FRONTEND_TECHNOLOGIES_JQUERY: '/frontend-technologies/jquery',
        FRONTEND_TECHNOLOGIES_BOOTSTRAP: '/frontend-technologies/bootstrap',
        FRONTEND_TECHNOLOGIES_CSS_HTML: '/frontend-technologies/css-html',
        FRONTEND_TECHNOLOGIES_VUE_NUXT: '/frontend-technologies/vue-nuxt',
        FRONTEND_TECHNOLOGIES_REACT: '/frontend-technologies/react',
    },
    LINK: {
        ANGULAR: 'angular',
        JQUERY: 'jquery',
        BOOTSTRAP: 'bootstrap',
        CSS_HTML: 'css-html',
        VUE_NUXT: 'vue-nuxt',
        REACT: 'react',
    },
    LABEL:{
        ANGULAR: 'Angular',
        JQUERY: 'Jquery',
        BOOTSTRAP: 'Bootstrap',
        CSS_HTML: 'CSS & html',
        VUE_NUXT: 'Vue & NUXT',
        REACT: 'React',
    },
    API:{

    }
};

    <div class="our-works">
        <div class="container">
            <div class="row">
                <div id="owl-carousel-our-things" class="owl-carousel owl-theme">
                    <div class="item px-3">
                        <div>
                            <img src="assets/images/icons/product-development.svg">
                            <div class="mb-3"><h4>Product Development</h4></div>
                            <p>
                                We, at Indian Technologies Dreams offer an array
                                of web design services like Web site Design, Software, Custom Web site
                                development & Graphic Design Services in order to enhance the corporate
                                image of our clients. A strong web site must be developed with careful
                                planning, which should be visually appealing spreading out its distinctive
                                flavors...
                            </p>
                        </div>
                    </div>
                    <div class="item px-3">
                        <div>
                            <img src="assets/images/icons/data-processing.svg">
                            <div class="mb-3"><h4>Data Processing</h4></div>
                            <p>
                                Indian Technologies Dreams is a leading software development company in India providing Offshore
                                SoftwareDevelopment Services & solutions with services such as outsourcing software development
                                India, Web
                                Development India Product Development, E-strategy Consulting, Offshore software
                                development,E-strategy Consulting...
                            </p>
                        </div>
                    </div>
                    <div class="item px-3">
                        <div>
                            <img src="assets/images/icons/mobile-solution.svg">
                            <div class="mb-3"><h4>Mobile Solution</h4></div>
                            <p>
                                Indian Tech Dreams has wide experience in Mobile Application Development. We provide Mobile
                                Application
                                Development platform including Windows Mobile Application, Mobile enabled website development, J2ME
                                based Mobile Application Development on Nokia, Samsung, LG phones, Android - Google mobile
                                hosted...
                            </p>
                        </div>
                    </div>
                    <div class="item px-3">
                        <div>
                            <img src="assets/images/icons/web-technology.svg">
                            <div class="mb-3"><h4>Web Technology</h4></div>
                            <p>
                                Information Technology is a fast growing industry. New architecture and multiplying software
                                platforms are forcing the application development to undergo a massive change in the Technologies .
                                And yet the pressure of building a robust & agile application is growing continuously...
                            </p>
                        </div>
                    </div>
                    <div class="item px-3">
                        <div>
                            <img src="assets/images/icons/mobile-technology.svg">
                            <div class="mb-3"><h4>Mobile Technology</h4></div>
                            <p>
                                Information Technology is a fast growing industry. New architecture and multiplying software
                                platforms are forcing the application development to undergo a massive change in the Technologies .
                                And yet the pressure of building a robust & agile application is growing continuously...
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

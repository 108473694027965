import { Component, Input,OnInit } from '@angular/core';
import { ElementRef } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Router, NavigationStart, NavigationEnd, Event as NavigationEvent } from '@angular/router';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: []
})
export class NavbarComponent implements OnInit {
    @Input() featureCategories:any;
    @Input() features:any;
    newUrl;
    size="sm";
    initialized=false;
    constructor(private router: Router,private navRef : ElementRef,private translate: TranslateService) {
        var self=this;
        self.router.events.forEach((event: NavigationEvent) => {
            //Before Navigation
            if (event instanceof NavigationStart) {
            }
            //After Navigation
            if (event instanceof NavigationEnd) {
                this.newUrl=event.url;
            }
        });
    }

    validateUrl(name){
        var url=this.router.url.replace('/','');
        return url.indexOf(name)>=0 || url.startsWith(name) || name.indexOf(url) >= 0;
    }

    ngAfterViewInit() {
        if(!this.initialized){
            this.initialize();
        }
    }
    ngOnInit() {
        if(!this.initialized){
            this.initialize();
        }
    }

    ngOnChanges(changes: any) {
        var self=this;
        setTimeout(function(){
            if(!self.initialized){
                self.initialize();
            }
        },200);

    }

    initialize(){
        var self=this;
        $(self.navRef.nativeElement).find('a').on('click', function(e){
            if ($(this).next().is('ul') && $(this).parent().toggleClass('open')) {
                $(this).next().slideToggle(200, function () {
                    //window.trigger('resize');
                });
                e.stopPropagation();
                e.preventDefault();
            }
        });
        $(self.navRef.nativeElement).find('li').on('mouseover', function(e){
            $(this).addClass('hover');
        });
        $(self.navRef.nativeElement).find('li').on('mouseout', function(e){
            $(this).removeClass('hover');
        });
        $(self.navRef.nativeElement).find('a').each(function (index, value){
            self.initialized=true;
            var link = $(this).attr('href');
            if (link === self.newUrl) {
                $(this).parent().addClass("active");
                if ($(this).closest("ul").hasClass("sub-menu")) {
                    var menu = $(this).closest("ul");
                    menu.parent().addClass("active").addClass("open");
                    menu.slideDown(200).parent().siblings().children('.sub-menu').slideUp(200, function () {
                        $(this).parent().removeClass("open");
                    });
                } else {
                    $('.sub-menu').slideUp(200, function () {
                        $(this).parent().removeClass("open");
                    });
                }

            }
        });
    }
}


        <div class="how-it-works">
        <div class="heading-block text-center">
            <h3 class="heading-title">How We Work?</h3>
        </div>
        <div class="how-it-works-tabs">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <nav>
                            <div class="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
                                <a class="nav-item nav-link active w-25 text-center text-uppercase" id="nav-sd-tab" data-toggle="tab" href="#nav-sd" role="tab" aria-controls="nav-sd" aria-selected="true">Works Process</a>
                                <a class="nav-item nav-link w-25 text-center text-uppercase" id="nav-bot-tab" data-toggle="tab" href="#nav-bot" role="tab" aria-controls="nav-bot" aria-selected="false">Technologies Used</a>
                                <a class="nav-item nav-link w-25 text-center text-uppercase" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">Services</a>
                                <a class="nav-item nav-link w-25 text-center text-uppercase" id="nav-about-tab" data-toggle="tab" href="#nav-about" role="tab" aria-controls="nav-about" aria-selected="false">Contact</a>
                            </div>
                        </nav>
                        <div class="tab-content" id="nav-tabContent">
                            <div class="tab-pane fade show active" id="nav-sd" role="tabpanel" aria-labelledby="nav-sd-tab">
                                <div class="row mt-3 mt-md-5">
                                    <div class="col-md-8">
                                        <div class="row">
                                            <div class="d-md-block d-none col-md-3 steps-count-col"><div>1</div></div>
                                            <div class="col-md-9 steps-info-col">
                                                <span class="d-md-none d-block float-left steps-count-col"><div>1</div></span>
                                                <h4>Our Services</h4>
                                                <ul>
                                                    <li>Software Technologies Consulting</li>
                                                    <li>Manage IT Services</li>
                                                    <li>Software Product Development</li>
                                                    <li>Product Maintenance & Support</li>
                                                    <li>Quality Assurance Services</li>
                                                    <li>Enterprise Mobility Solutions </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 steps-img-col mt-3 mt-md-0"><img src="assets/images/itd-services.png"></div>
                                </div>
                                <div class="row mt-3 mt-md-5">
                                    <div class="col-md-8">
                                        <div class="row">
                                            <div class="d-md-block d-none col-md-3 steps-count-col"><div>2</div></div>
                                            <div class="col-md-9 steps-info-col">
                                                <span class="d-md-none d-block float-left steps-count-col"><div>2</div></span>
                                                <h4>Working Domain</h4>
                                                <ul>
                                                    <li>Health Care</li>
                                                    <li>Education</li>
                                                    <li>Ad & Campaign</li>
                                                    <li>Software & Information Technologies</li>
                                                    <li>Logistic & Transport</li>
                                                    <li>Event Management</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 steps-img-col mt-3 mt-md-0"><img src="assets/images/itd-working-domain.png"></div>
                                </div>
                                <div class="row mt-3 mt-md-5">
                                    <div class="col-md-8">
                                        <div class="row">
                                            <div class="d-md-block d-none col-md-3 steps-count-col"><div>3</div></div>
                                            <div class="col-md-9 steps-info-col">
                                                <span class="d-md-none d-block float-left steps-count-col"><div>3</div></span>
                                                <h4>Engagement Model</h4>
                                                <ul>
                                                    <li>Fixed Price</li>
                                                    <li>Agile Method</li>
                                                    <li>Time & Material</li>
                                                    <li>Hybrid (Fixed Time & Material Combined)</li>
                                                    <li>BOT (Build Operate Transfer)</li>
                                                    <li>Co-Work</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 steps-img-col mt-3 mt-md-0"><img src="assets/images/itd-engagement-model.png"></div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="nav-bot" role="tabpanel" aria-labelledby="nav-bot-tab">
                                <div class="row mt-3 mt-md-5">
                                    <div class="col-md-8">
                                        <div class="row">
                                            <div class="d-md-block d-none col-md-3 steps-count-col"><div>1</div></div>
                                            <div class="col-md-9 steps-info-col">
                                                <span class="d-md-none d-block float-left steps-count-col"><div>1</div></span>
                                                <h4>Web Application Development</h4>
                                                <ul>
                                                    <li>Java Enterprise Application</li>
                                                    <li>PHP Application</li>
                                                    <li>ROR Application</li>
                                                    <li>Dot Net Application</li>
                                                    <li>Python Application</li>
                                                    <li>Node JS Application</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 steps-img-col mt-3 mt-md-0"><img src="assets/images/web-development-tech.png"></div>
                                </div>

                                <div class="row mt-3 mt-md-5">
                                    <div class="col-md-8">
                                        <div class="row">
                                            <div class="d-md-block d-none col-md-3 steps-count-col"><div>2</div></div>
                                            <div class="col-md-9 steps-info-col">
                                                <span class="d-md-none d-block float-left steps-count-col"><div>3</div></span>
                                                <h4>Web Front End Development</h4>
                                                <ul>
                                                    <li>HTML</li>
                                                    <li>Bootstrap</li>
                                                    <li>J Query</li>
                                                    <li>Vue.JS</li>
                                                    <li>React</li>
                                                    <li>A</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 steps-img-col mt-3 mt-md-0"><img src="assets/images/web-frontend-tech.png"></div>
                                </div>
                                <div class="row mt-3 mt-md-5">
                                    <div class="col-md-8">
                                        <div class="row">
                                            <div class="d-md-block d-none col-md-3 steps-count-col"><div>3</div></div>
                                            <div class="col-md-9 steps-info-col">
                                                <span class="d-md-none d-block float-left steps-count-col"><div>3</div></span>
                                                <h4>Mobile Development</h4>
                                                <ul>
                                                    <li>Android Native</li>
                                                    <li>IOS Native</li>
                                                    <li>Titanium Hybrid</li>
                                                    <li>Ionic Hybrid</li>
                                                    <li>React Native Hybrid</li>
                                                    <li>Framework7 Hybrid</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 steps-img-col mt-3 mt-md-0"><img src="assets/images/mobile-dev-tech.png"></div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                                <div class="row mt-3 mt-md-5">
                                    <div class="col-md-8">
                                        <div class="row">
                                            <div class="d-md-block d-none col-md-3 steps-count-col"><div>1</div></div>
                                            <div class="col-md-9 steps-info-col">
                                                <span class="d-md-none d-block float-left steps-count-col"><div>1</div></span>
                                                <h4>Solutions</h4>
                                                <ul>
                                                    <li>Online Marketing Solutions </li>
                                                    <li>Intranet Solutions</li>
                                                    <li>Desktop Solutions</li>
                                                    <li>Mobile Solutions</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 steps-img-col mt-3 mt-md-0"><img src="assets/images/computer2.jpg"></div>
                                </div>
                                <div class="row mt-3 mt-md-5">
                                    <div class="col-md-8">
                                        <div class="row">
                                            <div class="d-md-block d-none col-md-3 steps-count-col"><div>2</div></div>
                                            <div class="col-md-9 steps-info-col">
                                                <span class="d-md-none d-block float-left steps-count-col"><div>2</div></span>
                                                <h4>Product Development</h4>
                                                <ul>
                                                    <li>CMS Implementation </li>
                                                    <li>CMS Based Websites</li>
                                                    <li>Products</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 steps-img-col mt-3 mt-md-0"><img src="assets/images/computer3.jpg"></div>
                                </div>
                                <div class="row mt-3 mt-md-5">
                                    <div class="col-md-8">
                                        <div class="row">
                                            <div class="d-md-block d-none col-md-3 steps-count-col"><div>3</div></div>
                                            <div class="col-md-9 steps-info-col">
                                                <span class="d-md-none d-block float-left steps-count-col"><div>3</div></span>
                                                <h4>Other Services</h4>
                                                <ul>
                                                    <li>Code Improvement</li>
                                                    <li>Code Documentation</li>
                                                    <li>Data Processing</li>
                                                    <li>Testing Services</li>
                                                    <li>Networking & Community</li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 steps-img-col mt-3 mt-md-0"><img src="assets/images/computer.jpg"></div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="nav-about" role="tabpanel" aria-labelledby="nav-about-tab">
                                <div class="row mt-3 mt-md-5">
                                    <div class="col-md-8">
                                        <div class="row">
                                            <div class="d-md-block d-none col-md-3 steps-count-col"><div>1</div></div>
                                            <div class="col-md-9 steps-info-col">
                                                <span class="d-md-none d-block float-left steps-count-col"><div>1</div></span>
                                                <h4>Contact Address</h4>
                                                <ul>
                                                    <li>
                                                    <p>2nd Floor # 6/2 Lava Kush Building</p>
                                                    <p>Beratena Agrahara, Begur Hobli</p>
                                                    <p>Electronic City Post Bangalore 560100</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 steps-img-col mt-3 mt-md-0"><img src="assets/images/"></div>
                                </div>
                                <div class="row mt-3 mt-md-5">
                                    <div class="col-md-8">
                                        <div class="row">
                                            <div class="d-md-block d-none col-md-3 steps-count-col"><div>2</div></div>
                                            <div class="col-md-9 steps-info-col">
                                                <span class="d-md-none d-block float-left steps-count-col"><div>2</div></span>
                                                <h4>Contact Details</h4>
                                                <ul>
                                                    <li>Phone : (91) 080 4890 6057, (91) 080 4091 0121 </li>
                                                    <li>Email Id : info@indiantechdreams.com </li>
                                                    <li>Skype Id : ITDPLOnSkype</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 steps-img-col mt-3 mt-md-0"><img src="assets/images/"></div>
                                </div>
                                <div class="row mt-3 mt-md-5">
                                    <div class="col-md-8">
                                        <div class="row">
                                            <div class="d-md-block d-none col-md-3 steps-count-col"><div>3</div></div>
                                            <div class="col-md-9 steps-info-col">
                                                <span class="d-md-none d-block float-left steps-count-col"><div>3</div></span>
                                                <h4>Location Map</h4>
                                                <ul>
                                                    <li> </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 steps-img-col mt-3 mt-md-0"><img src="assets/images/"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import { Title }     from '@angular/platform-browser';
import {Router} from '@angular/router';
import { APP_CONFIG } from '../../../constants';
import { MAIN_CONSTANTS } from '../../constants';
import { WEB_TECHNOLOGIES_CONSTANTS } from '../../../web-technologies/constants';

@Component({
  selector: 'web-technologies-root',
  templateUrl: './web-technologies.component.html',
  styleUrls: []
})
export class WebTechnologiesComponent {
    CONFIG=APP_CONFIG;
    MAIN_CONSTANTS=MAIN_CONSTANTS;
    constructor(private titleService: Title, private router: Router) {
        this.CONFIG=APP_CONFIG;
        this.MAIN_CONSTANTS=MAIN_CONSTANTS;
        this.titleService.setTitle( APP_CONFIG.APP_NAME+ " :: "+ this.MAIN_CONSTANTS.LABEL.WEBTECHNOLOGIES);
    }
    callJava() {
        this.router.navigate([WEB_TECHNOLOGIES_CONSTANTS.URL.WEB_TECHNOLOGIES_JAVA]);
    }
    callPhp() {
        this.router.navigate([WEB_TECHNOLOGIES_CONSTANTS.URL.WEB_TECHNOLOGIES_PHP]);
    }
    callRuby() {
        this.router.navigate([WEB_TECHNOLOGIES_CONSTANTS.URL.WEB_TECHNOLOGIES_RUBY]);
    }
    callCSharp() {
        this.router.navigate([WEB_TECHNOLOGIES_CONSTANTS.URL.WEB_TECHNOLOGIES_C_SHARP]);
    }
    callPython() {
        this.router.navigate([WEB_TECHNOLOGIES_CONSTANTS.URL.WEB_TECHNOLOGIES_PYTHON]);
    }
    callNodeJs() {
        this.router.navigate([WEB_TECHNOLOGIES_CONSTANTS.URL.WEB_TECHNOLOGIES_NODE_JS]);
    }
}
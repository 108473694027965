<footer class="footer-section">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-lg-12 p-0 pt-5 pb-4">
                <div class="row m-0 py-md-5 py-lg-0">
                    <div class="col-lg-8 p-0">
                        <div class="row">
                            <div class="col-md-4 col-lg-4 pb-5">
                                <img class="footer-logo" src="assets/images/itd-logo.png"/>
                                <p class="footer-content">We believe in <strong>Research</strong>, <strong>Innovation</strong>& <strong>Communication</strong> in Development.</p>
                                <div class="footer-content">
                                    <address>
                                        <strong>Headquarters:</strong><br/>
                                        2nd Floor # 6/2 Lava Kush Building<br/>
                                        Beratena Agrahara, Begur Hobli<br/>
                                        Electronic City Post Bangalore 560100<br/>
                                    </address>
                                    <abbr title="Phone Number"><strong>Phone:</strong></abbr>
                                    (91) 080 4890 6057<br/>
                                    (91) 080 4091 0121<br/>
                                    <abbr title="Fax"><strong>Fax:</strong></abbr>
                                    (91) 080 4890 6057<br/>
                                    (91) 080 4091 0121<br/>
                                    <abbr title="Email Address"><strong>Email:</strong></abbr>
                                    info@indiantechdreams.com
                                </div>
                            </div>
                            <div class="col-md-4 col-lg-4 footer-lists pb-5">
                                <h5 class="footer-title">Blogroll</h5>
                                <ul>
                                    <li><i class="fa fa-angle-right font-weight-bold" aria-hidden="true"></i><a href="#">Documentation</a></li>
                                    <li><i class="fa fa-angle-right font-weight-bold" aria-hidden="true"></i><a href="#">Feedback</a></li>
                                    <li><i class="fa fa-angle-right font-weight-bold" aria-hidden="true"></i><a href="#">Plugins</a></li>
                                    <li><i class="fa fa-angle-right font-weight-bold" aria-hidden="true"></i><a href="#">Support Forums</a></li>
                                    <li><i class="fa fa-angle-right font-weight-bold" aria-hidden="true"></i><a href="#">Themes</a></li>
                                    <li><i class="fa fa-angle-right font-weight-bold" aria-hidden="true"></i><a href="#">ITDPL Blog</a></li>
                                    <li><i class="fa fa-angle-right font-weight-bold" aria-hidden="true"></i><a href="#">ITDPL Planet</a></li>
                                </ul>
                            </div>
                            <div class="col-md-4 col-lg-4 pb-5">
                                <h5 class="footer-title">Recent Posts</h5>
                                <div class="row">
                                    <div class="col-12 pb-4">
                                        <div class="row">
                                            <div class="col">
                                                <div class="entry-title">
                                                    <h6><a href="#">Quality, like integrity, is not negotiable.</a></h6>
                                                </div>
                                                <div class="entry-meta">
                                                    <ul>
                                                        <li>24th Mar 2022</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 pb-4">
                                        <div class="row">
                                            <div class="col">
                                                <div class="entry-title">
                                                    <h6><a href="#">System analysis & Design</a></h6>
                                                </div>
                                                <div class="entry-meta">
                                                    <ul>
                                                        <li>30th Dec 2021</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 pb-4">
                                        <div class="row">
                                            <div class="col">
                                                <div class="entry-title">
                                                    <h6><a href="#">Maintenance and Support</a></h6>
                                                </div>
                                                <div class="entry-meta">
                                                    <ul>
                                                        <li>18th Jan 2022</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 px-0">
                        <div class="row">
                            <div class="col-md-4 col-lg-12 pb-5 pb-md-0 pb-lg-5">
                                <div class="row">
                                    <div class="col-lg-6 mb-4">
                                        <div class="counters">
                                            <span></span>
                                        </div>
                                        <h6 class="mb-0 text-uppercase size14 total-downloads-clients"></h6>
                                    </div>
                                    <div class="col-lg-6 mb-4">
                                        <div class="counters">
                                            <span></span>
                                        </div>
                                        <h6 class="mb-0 text-uppercase size14 total-downloads-clients"></h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5 col-lg-12 pb-5 pb-md-0 pb-lg-5 subscribe-box">
                                <h6 class="mb-3"><strong>Subscribe</strong> to Our Newsletter to get Important News, Amazing Offers & Inside Scoops:</h6>
                                <form>
                                    <div class="input-group mx-auto">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <i class="fa fa-paper-plane-o" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <input type="email" class="form-control required" placeholder="Enter your Email">
                                        <div class="input-group-append">
                                           <button class="btn btn-success" type="submit">Subscribe</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="col-md-3 col-lg-12 pb-5 pb-md-0 pb-lg-5 social-media-box">
                                <div class="row">
                                    <div class="col-6 col-md-12 col-lg-6 mb-md-4 mb-lg-0">
                                        <a href="#" class="social-icons">
                                            <div class="background-facebook"><i class="fa fa-facebook icon-facebook" aria-hidden="true"></i></div>
                                        </a>
                                        <a href="#" class="social-texts">
                                            <small><strong>Like us</strong><br />on Facebook</small>
                                        </a>
                                    </div>
                                    <div class="col-6 col-md-12 col-lg-6">
                                        <a href="#" class="social-icons">
                                            <div class="background-rss"><i class="fa fa-rss icon-rss" aria-hidden="true"></i></div>
                                        </a>
                                        <a href="#" class="social-texts">
                                            <small><strong>Subscribe</strong><br />to RSS Feeds</small>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="copyrights">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6 text-center text-md-left">
                    Copyrights © 2020 All Rights Reserved by Indian Technologies Dreams Pvt Ltd
                    <br>
                    <div class="copyright-links">
                        <a href="#">Terms of Use</a>/<a href="#">Privacy Policy</a>
                    </div>
                </div>
                <div class="col-md-6 text-center text-md-right social-media-box">
                    <div class="d-flex justify-content-center justify-content-md-end">
                        <a href="#" class="social-icons-mini">
                            <div class="background-facebook-mini"><i class="fa fa-facebook" aria-hidden="true"></i></div>
                        </a>
                        <a href="#" class="social-icons-mini">
                            <div class="background-twitter-mini"><i class="fa fa-twitter" aria-hidden="true"></i></div>
                        </a>
                        <a href="#" class="social-icons-mini">
                            <div class="background-google-plus-mini"><i class="fa fa-google-plus" aria-hidden="true"></i></div>
                        </a>
                        <a href="#" class="social-icons-mini">
                            <div class="background-pinterest-mini"><i class="fa fa-pinterest" aria-hidden="true"></i></div>
                        </a>
                        <a href="#" class="social-icons-mini">
                            <div class="background-vimeo-mini"><i class="fa fa-vimeo-square" aria-hidden="true"></i></div>
                        </a>
                        <a href="#" class="social-icons-mini">
                            <div class="background-github-mini"><i class="fa fa-github" aria-hidden="true"></i></div>
                        </a>
                        <a href="#" class="social-icons-mini">
                            <div class="background-yahoo-mini"><i class="fa fa-yahoo" aria-hidden="true"></i></div>
                        </a>
                        <a href="#" class="social-icons-mini">
                            <div class="background-linkedin-mini"><i class="fa fa-linkedin-square" aria-hidden="true"></i></div>
                        </a>
                    </div>
                    <div>
                        <i class="fa fa-envelope-o" aria-hidden="true"></i>
                        info@indiantechdreams.com
                        <span class="middot"><i class="fa fa-circle dot-separator" aria-hidden="true"></i></span>
                        <i class="fa fa-headphones" aria-hidden="true"></i>
                        (91) 080 4890 6057 , (91) 080 4091 0121
                        <span class="middot"><i class="fa fa-circle dot-separator" aria-hidden="true"></i></span>
                        <i class="fa fa-skype" aria-hidden="true"></i>
                        ITDPLOnSkype
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

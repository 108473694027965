import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import { Title }     from '@angular/platform-browser';

import { APP_CONFIG } from '../../../constants';
import { FRONTEND_TECHNOLOGIES_CONSTANTS } from '../../constants';

@Component({
  selector: 'css-html-root',
  templateUrl: './css-html.component.html',
  styleUrls: []
})
export class CssHtmlComponent {
    CONFIG=APP_CONFIG;
    FRONTEND_TECHNOLOGIES_CONSTANTS=FRONTEND_TECHNOLOGIES_CONSTANTS;
    constructor(private titleService: Title) {
        this.CONFIG=APP_CONFIG;
        this.FRONTEND_TECHNOLOGIES_CONSTANTS=FRONTEND_TECHNOLOGIES_CONSTANTS;
        this.titleService.setTitle( APP_CONFIG.APP_NAME+ " :: "+ this.FRONTEND_TECHNOLOGIES_CONSTANTS.LABEL.CSS_HTML);
    }
}
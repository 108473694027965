<!-- start: LOGIN -->
<div class="row">
    <div class="main-login col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2 col-md-4 col-md-offset-4 col-lg-4 col-lg-offset-4">
        <div class="logo text-center">
            <img src="{{CONFIG.IMAGE_URL}}/images/logo.png" alt="{{CONFIG.APP_NAME}}" class="img-responsive"/>
        </div>
        <p class="text-center text-dark text-bold text-extra-large margin-top-15" >
            {{ "auth.view.welcomeMessage" | translate }}
        </p>
        <p class="text-center">{{ "auth.view.loginUserPassword" | translate }}</p>

        <alert-message></alert-message>

        <p class="text-center" >{{ "auth.view.loginPasswordText" | translate }}</p>
        <!-- start: LOGIN BOX -->
        <div class="box-login">
            <form class="form-login" name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
                    <input type="text" class="form-control" name="username" placeholder="Username" [(ngModel)]="user.username" #username="ngModel" required />
                    <span *ngIf="f.submitted && !username.valid"  class="error text-small block" >{{ "login.error.username" | translate }}</span>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
                    <input autocomplete="false" type="password" class="form-control password" name="password" placeholder="Password" [(ngModel)]="user.password" #password="ngModel" required />
                    <div class="error text-small block" *ngIf="f.submitted && !password.valid" >{{ "login.error.password" | translate }}</div>
                </div>
                <div class="form-actions">
                    <button type="submit" class="btn btn-red btn-block" [disabled]="loading">
                        Login
                        <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    </button>

                    <div class="checkbox clip-check check-primary">
                        <input type="checkbox" id="remember" value="1">
                        <label for="remember"> {{ "auth.view.keepLogin" | translate }} </label>
                    </div>
                </div>
                <div class="text-center">
                    <a [routerLink]="[AUTH_CONSTANTS.URL.FORGOT_PASSWORD]" > {{ "auth.view.forgotPassword" | translate }}</a>
                </div>
                <div class="new-account text-center">
                    <span >{{ "auth.view.notHaveAccount" | translate }}</span>
                    <a [routerLink]="[AUTH_CONSTANTS.URL.REGISTER]" >{{ "auth.view.createAccount" | translate }}</a>
                </div>
            </form>
        </div>
        <!-- end: LOGIN BOX -->
    </div>
</div>
<!-- end: LOGIN -->
import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import { Title }     from '@angular/platform-browser';

import { APP_CONFIG } from '../../../constants';
import { MOBILE_TECHNOLOGIES_CONSTANTS } from '../../constants';

@Component({
  selector: 'react-native-root',
  templateUrl: './react-native.component.html',
  styleUrls: []
})
export class ReactNativeComponent {
    CONFIG=APP_CONFIG;
    MOBILE_TECHNOLOGIES_CONSTANTS=MOBILE_TECHNOLOGIES_CONSTANTS;
    constructor(private titleService: Title) {
        this.CONFIG=APP_CONFIG;
        this.MOBILE_TECHNOLOGIES_CONSTANTS=MOBILE_TECHNOLOGIES_CONSTANTS;
        this.titleService.setTitle( APP_CONFIG.APP_NAME+ " :: "+ this.MOBILE_TECHNOLOGIES_CONSTANTS.LABEL.REACT_NATIVE);
    }
}
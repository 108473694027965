import { Injectable } from '@angular/core';
import { HttpClient  } from '@angular/common/http';

import { APP_CONFIG } from '../../constants';

@Injectable()
export class HttpClientService {
    CONFIG=APP_CONFIG;
    baseUrl='';
    constructor(private http: HttpClient ) {
        this.CONFIG=APP_CONFIG;
        this.baseUrl=this.CONFIG.CONTEXT+this.CONFIG.API_URL+this.CONFIG.API_VERSION;
    }

    getRequest(url,data){
        return this.http.get(this.baseUrl+url, data);
    }

    postRequest(url,data){
        return this.http.post(this.baseUrl+url, data);
    }

    putRequest(url,data){
        return this.http.put(this.baseUrl+url, data);
    }

    deleteRequest(url,data){
        return this.http.delete(this.baseUrl+url, data);
    }
}
import { NgModule }            from '@angular/core';
import { CommonModule }        from '@angular/common';
import {TranslateModule}       from '@ngx-translate/core';
import {HttpClientModule,HttpClient} from '@angular/common/http';
import { FormsModule }   from '@angular/forms';
import { TemplateModule} from '../template/template.module';
import { WhyUsRoutingModule }   from './why-us-routing.module';
import { OverviewComponent } from './views/overview/overview.component';
import { CompetenciesComponent } from './views/competencies/competencies.component';
import { QualityComponent } from './views/quality/quality.component';
import { SystemAnalysisComponent } from './views/system-analysis/system-analysis.component';
import { MaintenanceSupportComponent } from './views/maintenance-support/maintenance-support.component';

@NgModule({
    declarations: [
        OverviewComponent,
        CompetenciesComponent,
        QualityComponent,
        SystemAnalysisComponent,
        MaintenanceSupportComponent,
    ],
    imports: [
        TranslateModule,
        HttpClientModule,
        CommonModule,
        FormsModule,
        TemplateModule,
        WhyUsRoutingModule,
    ],
    exports: [OverviewComponent,CompetenciesComponent,QualityComponent,SystemAnalysisComponent,MaintenanceSupportComponent,],
    providers: [],
    bootstrap: [OverviewComponent,CompetenciesComponent,QualityComponent,SystemAnalysisComponent,MaintenanceSupportComponent,]
})
export class WhyUsModule { }

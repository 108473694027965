<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>News</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <ul>
                        <li>Indian Technologies Dreams going to launch a CMS based Web Hosting System. It will Provide Hosting services , CMS facility to web site customers. People can get Online with this and can modify their site content without any help of technical person.</li>
                        <li>Shortly going to open branch in Delhi with CMS web hosting facility</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
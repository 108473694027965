<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>Desktop Solution</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <p class="mb-4">Applications running in a desktop (or laptop) computer in contrast to Web-based applications over the Internet. Since the advent of the Web, developers have been trying to make Web applications as interactive and responsive as applications running within the client locally or in a client/server environment over the local network</p>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import { Title }     from '@angular/platform-browser';

import { APP_CONFIG } from '../../../constants';
import { WORKING_DOMAIN_CONSTANTS } from '../../constants';

@Component({
  selector: 'health-care-root',
  templateUrl: './health-care.component.html',
  styleUrls: []
})
export class HealthCareComponent {
    CONFIG=APP_CONFIG;
    WORKING_DOMAIN_CONSTANTS=WORKING_DOMAIN_CONSTANTS;
    constructor(private titleService: Title) {
        this.CONFIG=APP_CONFIG;
        this.WORKING_DOMAIN_CONSTANTS=WORKING_DOMAIN_CONSTANTS;
        this.titleService.setTitle( APP_CONFIG.APP_NAME+ " :: "+ this.WORKING_DOMAIN_CONSTANTS.LABEL.HEALTH_CARE);
    }
}
    <nav id="sidebar-nav">
        <!-- start: MAIN NAVIGATION MENU -->
        <div class="navbar-title">
            <span>{{ "sidebar.heading.NAVIGATION" | translate }}</span>
        </div>
        <ul class="main-navigation-menu"  >
            <li *ngFor="let featureCategory of featureCategories.others" [ngClass]="{'active open':validateUrl(featureCategory.state)}" >
                <a [routerLink]="[featureCategory.state]" *ngIf="featureCategory.features.length == 0">
                    <div class="item-content">
                        <div class="item-media">
                            <letter-icon [data]="featureCategory.translation" [charCount]="2" [size]="size"></letter-icon>
                        </div>
                        <div class="item-inner">
                            <span class="title"> {{featureCategory.translation | translate }}</span>
                        </div>
                    </div>
                </a>
                <a href="javascript:void(0)" *ngIf="featureCategory.features.length > 0">
                    <div class="item-content">
                        <div class="item-media">
                            <letter-icon [data]="featureCategory.translation" [charCount]="2" [size]="size"></letter-icon>
                        </div>
                        <div class="item-inner">
                            <span class="title"> {{featureCategory.translation | translate }} </span>
                            <i class="icon-arrow"></i>
                        </div>
                    </div>
                </a>
                <ul class="sub-menu" *ngIf="featureCategory.features.length > 0 ">
                    <li href-active="active" *ngFor="let feature of featureCategory.menus">
                        <a [routerLink]="[feature.state]"> <span class="title"> {{feature.translation | translate }} </span> </a>
                    </li>
                </ul>
            </li>
        </ul>
        <!-- end: MAIN NAVIGATION MENU -->
    <!-- start: FEATURES -->
    <div class="navbar-title">
        <span translate="sidebar.heading.FEATURES">Core Features</span>
    </div>
    <ul class="folders">
        <li *ngFor="let featureCategory of featureCategories.cores" [ngClass]="{'active open':validateUrl(featureCategory.state)}" >
            <a [routerLink]="[featureCategory.state]" *ngIf="featureCategory.features.length == 0">
                <div class="item-content">
                    <div class="item-media">
                        <letter-icon [data]="featureCategory.translation" [charCount]="2" [size]="size"></letter-icon>
                    </div>
                    <div class="item-inner">
                        <span class="title"> {{featureCategory.translation | translate }}</span>
                    </div>
                </div>
            </a>
            <a href="javascript:void(0)" *ngIf="featureCategory.features.length > 0">
                <div class="item-content">
                    <div class="item-media">
                        <letter-icon [data]="featureCategory.translation" [charCount]="2" [size]="size"></letter-icon>
                    </div>
                    <div class="item-inner">
                        <span class="title"> {{featureCategory.translation | translate }} </span>
                        <i class="icon-arrow"></i>
                    </div>
                </div>
            </a>
            <ul class="sub-menu" *ngIf="featureCategory.features.length > 0 ">
                <li href-active="active" *ngFor="let feature of featureCategory.menus">
                    <a [routerLink]="[feature.state]" > <span class="title"> {{feature.translation | translate }} </span> </a>
                </li>
            </ul>
        </li>
    </ul>
    <!-- end: FEATURES -->
</nav>
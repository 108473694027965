<div class="button-wrapper">
    <div class="page-size-select">
        <span >{{ 'page.list.showing' | translate }}</span>
        <select class="list-select" [(ngModel)]="pageSizeSelect" (change)="changePageSize(pageSizeSelect)">
            <option *ngFor="let pageSizeValue of pageSizeList" value="{{pageSizeValue}}" [selected]="pageSizeValue == pageSizeSelect">{{pageSizeValue}}</option>
        </select>
        <span> {{'page.list.entries_page' | translate}}</span>
    </div>
    <button class="btn btn-default pull-right" (click)="addNew();">
        <i class="fa fa-plus" aria-hidden="true"></i> {{ 'page.action.create' | translate }}
    </button>
</div>

import { NgModule }             from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { HomeComponent } from './main/views/home/home.component';
import { MainModule} from './main/main.module';
import { AuthModule} from './auth/auth.module';
import { Page404Component } from './main/views/page404/page404.component';
import { EngagementModule} from './engagement/engagement.module';
import { OurServicesModule} from './our-services/our-services.module';
import { WorkingDomainModule} from './working-domain/working-domain.module';
import { WebTechnologiesModule} from './web-technologies/web-technologies.module';
import { MobileTechnologiesModule} from './mobile-technologies/mobile-technologies.module';
import { FrontendTechnologiesModule} from './frontend-technologies/frontend-technologies.module';
import { AboutUsModule} from './about-us/about-us.module';
import { TechnologiesModule} from './technologies/technologies.module';
import { NewsEventsModule} from './news-events/news-events.module';
import { ClientsModule} from './clients/clients.module';
import { WhyUsModule} from './why-us/why-us.module';
import { ServicesModule} from './services/services.module';

import { APP_CONSTANTS } from './constants';

const appRoutes: Routes = [
    { path: APP_CONSTANTS.LINK.HOME, component: HomeComponent },
    { path: APP_CONSTANTS.LINK.AUTH, loadChildren: () => AuthModule },
    { path: APP_CONSTANTS.LINK.MAIN, loadChildren: () => MainModule },
    { path: APP_CONSTANTS.LINK.ENGAGEMENT, loadChildren: () => EngagementModule },
    { path: APP_CONSTANTS.LINK.OUR_SERVICES, loadChildren: () => OurServicesModule },
    { path: APP_CONSTANTS.LINK.WORKING_DOMAIN, loadChildren: () => WorkingDomainModule },
    { path: APP_CONSTANTS.LINK.WEB_TECHNOLOGIES, loadChildren: () => WebTechnologiesModule },
    { path: APP_CONSTANTS.LINK.MOBILE_TECHNOLOGIES, loadChildren: () => MobileTechnologiesModule },
    { path: APP_CONSTANTS.LINK.FRONTEND_TECHNOLOGIES, loadChildren: () => FrontendTechnologiesModule },
    { path: APP_CONSTANTS.LINK.ABOUT_US, loadChildren: () => AboutUsModule },
    { path: APP_CONSTANTS.LINK.TECHNOLOGIES, loadChildren: () => TechnologiesModule },
    { path: APP_CONSTANTS.LINK.NEWS_EVENTS, loadChildren: () => NewsEventsModule },
    { path: APP_CONSTANTS.LINK.CLIENTS, loadChildren: () => ClientsModule },
    { path: APP_CONSTANTS.LINK.WHY_US, loadChildren: () => WhyUsModule },
    { path: APP_CONSTANTS.LINK.SERVICES, loadChildren: () => ServicesModule },
    // otherwise redirect to home
    { path: APP_CONSTANTS.LINK.OTHERS, redirectTo: APP_CONSTANTS.LINK.PAGE404 }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}

<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>System analysis & Design</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <p class="mb-4">Systems analysis is effective when all sides of the problem are reviewed. Systems design is most effective when more than one solution can be proposed. The plans for the care and feeding of a new system are as important as the problems they solve.</p>
                </div>
            </div>
        </div>
    </div>
</div>
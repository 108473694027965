import { NgModule }             from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NativeComponent } from './views/native/native.component';
import { XamarinComponent } from './views/xamarin/xamarin.component';
import { TitaniumHybridComponent } from './views/titanium-hybrid/titanium-hybrid.component';
import { IonicComponent } from './views/ionic/ionic.component';
import { ReactNativeComponent } from './views/react-native/react-native.component';
import { Framework7Component } from './views/framework-7/framework-7.component';

import { AuthGuard } from '../auth/services/auth.guard';

import { MOBILE_TECHNOLOGIES_CONSTANTS } from './constants';


const mobileTechnologiesRoutes: Routes = [
    { path: MOBILE_TECHNOLOGIES_CONSTANTS.LINK.NATIVE, component: NativeComponent },
    { path: MOBILE_TECHNOLOGIES_CONSTANTS.LINK.XAMARIN, component: XamarinComponent },
    { path: MOBILE_TECHNOLOGIES_CONSTANTS.LINK.TITANIUM_HYBRID, component: TitaniumHybridComponent },
    { path: MOBILE_TECHNOLOGIES_CONSTANTS.LINK.IONIC, component: IonicComponent },
    { path: MOBILE_TECHNOLOGIES_CONSTANTS.LINK.REACT_NATIVE, component: ReactNativeComponent },
    { path: MOBILE_TECHNOLOGIES_CONSTANTS.LINK.FRAMEWORK_7, component: Framework7Component },
];

@NgModule({
    imports: [RouterModule.forChild(mobileTechnologiesRoutes)],
    exports: [RouterModule]
})
export class MobileTechnologiesRoutingModule {}

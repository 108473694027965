<div class="pagination-section">
    <div class="page-size-show" ng-show="totalSize>0">
        <span>Showing {{ totalSize >0 ? (currentPage-1)*pageSize+1 : 0}} to {{(currentPage-1)*pageSize+itemSize > totalSize ? totalSize : (currentPage-1)*pageSize+itemSize}} from {{totalSize}} entries</span>
    </div>
    <div class="page-list" *ngIf="totalSize>0">
        <ul class="pagination">
            <li ><a (click)="previousPage();">&laquo;</a></li>
            <li *ngFor="let page of getNumber()" [ngClass]="{'active': currentPage == page }"><a (click)="getPage(page);">{{page}}</a></li>
            <li><a (click)="nextPage();">&raquo;</a></li>
        </ul>
    </div>
</div>
export const CLIENTS_CONSTANTS = {
    URL: {
        CLIENTS_OVERVIEW: '/clients/overview',
        CLIENTS_CLIENT_ENGAGEMENTS: '/clients/client-engagements',
        CLIENTS_DEVELOPMENT_CYCLE: '/clients/development-cycle',
        CLIENTS_FLEXIBLE_TERMS: '/clients/flexible-terms',
        CLIENTS_TESTIMONIALS: '/clients/testimonials',
    },
    LINK: {
        OVERVIEW: 'overview',
        CLIENT_ENGAGEMENTS: 'client-engagements',
        DEVELOPMENT_CYCLE: 'development-cycle',
        FLEXIBLE_TERMS: 'flexible-terms',
        TESTIMONIALS: 'testimonials',
    },
    LABEL:{
        OVERVIEW: 'Overview',
        CLIENT_ENGAGEMENTS: 'Client Engagements',
        DEVELOPMENT_CYCLE: 'Development Cycle',
        FLEXIBLE_TERMS: 'Flexible Terms',
        TESTIMONIALS: 'Testimonials',
    },
    API:{

    }
};
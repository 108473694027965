import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormsModule }   from '@angular/forms';
import { Title }     from '@angular/platform-browser';

import { APP_CONFIG } from '../../../constants';
import { AUTH_CONSTANTS } from '../../constants';

import { AuthenticationService } from '../../services/authentication.service';
import { AlertService } from '../../../shared/services/alert.service';
import { AlertComponent } from '../../../shared/directives/alert/alert.component';

@Component({
    moduleId: module.id.toString(),
    templateUrl: 'screen-lock.component.html'
})

export class ScreenLockComponent implements OnInit {
    user: any = {};
    loading = false;
    CONFIG=APP_CONFIG;
    AUTH_CONSTANTS=AUTH_CONSTANTS;
    currentUser=undefined;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private titleService: Title) {
        this.CONFIG=APP_CONFIG;
        this.AUTH_CONSTANTS=AUTH_CONSTANTS;
        this.titleService.setTitle( APP_CONFIG.APP_NAME+ " :: "+ this.AUTH_CONSTANTS.LABEL.SCREEN_LOCK);
    }

    ngOnInit() {
        this.currentUser=this.authenticationService.getCurrentUser();
    }

    unlock() {
        this.loading = true;
        this.user.username=this.currentUser.username;
        this.authenticationService.login(this.user.username, this.user.password)
            .subscribe(
            data => {
                this.currentUser=data["data"][0];
                this.router.navigate([this.AUTH_CONSTANTS.URL.DASHBOARD]);
            },
            error => {
                this.alertService.error(error.message);
                this.loading = false;
            });
    }
}

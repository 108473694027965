<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>Code Documentation</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <p class="mb-4">The documentation is extracted directly from the sources, which makes it much easier to keep the documentation consistent with the source code. the XML documentation tags recommended by Microsoft, and shows how to put these tags to use. By taking the time to document your code, not only will you and others be able to clearly understand the code and utilize features such as IntelliSense.</p>
                </div>
            </div>
        </div>
    </div>
</div>
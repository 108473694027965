<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>Competencies</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <h4 class="mb-4">Our core competencies are:</h4>
                    <ul>
                        <li>Outsourced custom software development in open source and enterprise Java,.Net,Flex.</li>
                        <li>Driven towards bringing the rigor,quality and ownership of product development to a services engagement.</li>
                        <li>Excellent competency in web Technologies like AJAX,DHTML and Java Script.</li>
                        <li>Web 2.0 + RIA and concepts.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- start: BREADCRUMB -->
<div class="breadcrumb-wrapper">
    <div class="featureIcon"><i class="fa fa-user"></i></div>
    <h4 class="mainTitle no-margin">{{setting.pageTitle | translate}}</h4>
    <span class="mainDescription">{{setting.pageDesc | translate}}</span>
    <ul class="pull-right breadcrumb ng-isolate-scope">
        <li>
            <a [routerLink]="[MAIN_CONSTANTS.URL.DASHBOARD]">
                <i class="fa fa-home margin-right-5 text-large text-dark"></i>
                Home
            </a>
        </li>
        <li class="ng-binding ng-scope" *ngFor="let step of steps; let i = index" [attr.data-index]="i">
            <a [routerLink]="[step.state]" *ngIf="i < steps.length-1">{{step.title | translate}}</a>
            <span *ngIf="i == steps.length-1">{{step.title | translate}}</span>
        </li>
    </ul>
</div>
<!-- end: BREADCRUMB -->

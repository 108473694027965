export const WEB_TECHNOLOGIES_CONSTANTS = {
    URL: {
        WEB_TECHNOLOGIES_JAVA: '/web-technologies/java',
        WEB_TECHNOLOGIES_PHP: '/web-technologies/php',
        WEB_TECHNOLOGIES_RUBY: '/web-technologies/ruby',
        WEB_TECHNOLOGIES_C_SHARP: '/web-technologies/c-sharp',
        WEB_TECHNOLOGIES_PYTHON: '/web-technologies/python',
        WEB_TECHNOLOGIES_NODE_JS: '/web-technologies/node-js',
    },
    LINK: {
        JAVA: 'java',
        PHP: 'php',
        RUBY: 'ruby',
        C_SHARP: 'c-sharp',
        PYTHON: 'python',
        NODE_JS: 'node-js',
    },
    LABEL:{
        JAVA: 'Java',
        PHP: 'Php',
        RUBY: 'Ruby',
        C_SHARP: 'C#.Net',
        PYTHON: 'Python',
        NODE_JS: 'Node Js',
    },
    API:{

    }
};
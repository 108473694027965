import { NgModule }             from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { JavaComponent } from './views/java/java.component';
import { PhpComponent } from './views/php/php.component';
import { RubyComponent } from './views/ruby/ruby.component';
import { CSharpComponent } from './views/c-sharp/c-sharp.component';
import { PythonComponent } from './views/python/python.component';
import { NodeJsComponent } from './views/node-js/node-js.component';

import { AuthGuard } from '../auth/services/auth.guard';

import { WEB_TECHNOLOGIES_CONSTANTS } from './constants';


const webTechnologiesRoutes: Routes = [
    { path: WEB_TECHNOLOGIES_CONSTANTS.LINK.JAVA, component: JavaComponent },
    { path: WEB_TECHNOLOGIES_CONSTANTS.LINK.PHP, component: PhpComponent },
    { path: WEB_TECHNOLOGIES_CONSTANTS.LINK.RUBY, component: RubyComponent },
    { path: WEB_TECHNOLOGIES_CONSTANTS.LINK.C_SHARP, component: CSharpComponent },
    { path: WEB_TECHNOLOGIES_CONSTANTS.LINK.PYTHON, component: PythonComponent },
    { path: WEB_TECHNOLOGIES_CONSTANTS.LINK.NODE_JS, component: NodeJsComponent },
];

@NgModule({
    imports: [RouterModule.forChild(webTechnologiesRoutes)],
    exports: [RouterModule]
})
export class WebTechnologiesRoutingModule {}

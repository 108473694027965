
<div class="feature-title heading-block text-center mt-5">
    <h3 class="heading-title">Working Domain</h3>
</div>
<div class="feature-box">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6 pb-5">
                <div class="inside-fb">
                    <div class="fb-icon"><a href="#"><i class="fa fa-heartbeat" aria-hidden="true"></i></a></div>
                    <div class="fb-content">
                        <h3>Health Care</h3>
                        <p>ITDPL provides Assistance, Guidance and feedback of Healthcare organization with the decision support Software.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 pb-5">
                <div class="inside-fb">
                    <div class="fb-icon"><a href="#"><i class="fa fa-book" aria-hidden="true"></i></a></div>
                    <div class="fb-content">
                        <h3>Education</h3>
                        <p>ITDPL makes Education management efficient and effective with the Education software.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 pb-5">
                <div class="inside-fb">
                    <div class="fb-icon"><a href="#"><i class="fa fa-users" aria-hidden="true"></i></a></div>
                    <div class="fb-content">
                        <h3>Human Resource Management</h3>
                        <p>ITDPL provides digital solution to manage and optimize Human Resource Management Tasks and goals of organization.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 pb-5">
                <div class="inside-fb">
                    <div class="fb-icon"><a href="#"><i class="fa fa-desktop" aria-hidden="true"></i></a></div>
                    <div class="fb-content">
                        <h3>Software & Information Technologies</h3>
                        <p>ITDPL makes effective and efficient Software & Information Technologies solutions for the different sectors.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 pb-5">
                <div class="inside-fb">
                    <div class="fb-icon"><a href="#"><i class="fa fa-taxi" aria-hidden="true"></i></a></div>
                    <div class="fb-content">
                        <h3>Logistic & Transport</h3>
                        <p>ITDPL provides Software and IT support for all the processes of Logistic & Transport.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 pb-5">
                <div class="inside-fb">
                    <div class="fb-icon"><a href="#"><i class="fa fa-handshake-o" aria-hidden="true"></i></a></div>
                    <div class="fb-content">
                        <h3>Customer Relationship Management</h3>
                        <p>ITDPL provides complete guidance and support for customer and potential customers interactions and sales tracking.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 pb-5">
                <div class="inside-fb">
                    <div class="fb-icon"><a href="#"><i class="fa fa-calendar" aria-hidden="true"></i></a></div>
                    <div class="fb-content">
                        <h3>Event Management</h3>
                        <p>ITDPL provides complete software and IT support for managing the Events of customers.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 pb-5">
                <div class="inside-fb">
                    <div class="fb-icon"><a href="#"><i class="fa fa-camera-retro" aria-hidden="true"></i></a></div>
                    <div class="fb-content">
                        <h3>Ad & Campaign</h3>
                        <p>ITDPL provides software for planning, execution and tracking of advertising the new products or events.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 pb-5">
                <div class="inside-fb">
                    <div class="fb-icon"><a href="#"><i class="fa fa-tasks" aria-hidden="true"></i></a></div>
                    <div class="fb-content">
                        <h3>Enterprise Resource Planning</h3>
                        <p>ITDPL provides software and IT support to manage day-to-day business activities. </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 pb-5">
                <div class="inside-fb">
                    <div class="fb-icon"><a href="#"><i class="fa fa-cloud" aria-hidden="true"></i></a></div>
                    <div class="fb-content">
                        <h3>Cloud Computing</h3>
                        <p>ITDPL provides delivery of computing services including servers, storage, databases, networking, software, analytics, and intelligence.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 pb-5">
                <div class="inside-fb">
                    <div class="fb-icon"><a href="#"><i class="fa fa-briefcase" aria-hidden="true"></i></a></div>
                    <div class="fb-content">
                        <h3>Project and Process Management</h3>
                        <p>ITDPL provides Project and Process Management software for quality improvement and defect reduction techniques.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 pb-5">
                <div class="inside-fb">
                    <div class="fb-icon"><a href="#"><i class="fa fa-code" aria-hidden="true"></i></a></div>
                    <div class="fb-content">
                        <h3>Code Management</h3>
                        <p>ITDPL provides effective and efficient software to handle the code changes of computer software.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="optimization-title heading-block text-center mt-5">
    <h3 class="heading-title">Engagement Model</h3>
</div>
<div class="optimist-responsive">
    <div class="container">
        <div class="row">
            <div class="col-md-4 text-center align-self-center">
                <img class="left-side-img" src="assets/images/laptop.png">
            </div>
            <div class="col-md-8 text-center text-md-left right-side-block">
                <div class="heading-section">
                    <h3>Engagement Models for Services.</h3>
                </div>
                <p>Fixed Price</p>
                <p>Agile Method</p>
                <p>Time & Material</p>
                <p>Hybrid (Fixed Time & Material Combined)</p>
                <p>BOT (Build Operate Transfer)</p>
                <p>Co-Work</p>

                    <a href="#" class="btn btn-outline-dark">Learn more</a>
                </div>
            </div>
        </div>
    </div>
    <div class="heading-block-tabs">
        <div class="row">
            <ul class="nav nav-tabs w-100" id="myTab" role="tablist">
                <li class="nav-item col-6">
                    <a class="nav-link active" id="features-tab" data-toggle="tab" href="#id-features" role="tab" aria-controls="id-features" aria-selected="true">
                        <div class="feature-title heading-block text-center mt-5">
                            <h3 class="heading-title">Working Domain</h3>
                        </div>
                    </a>
                </li>
                <li class="nav-item col-6">
                    <a class="nav-link" id="optimization-tab" data-toggle="tab" href="#id-optimization" role="tab" aria-controls="id-optimization" aria-selected="false">
                        <div class="optimization-title heading-block text-center mt-5">
                            <h3 class="heading-title">Engagement Model</h3>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="id-features" role="tabpanel" aria-labelledby="features-tab">
            <div class="feature-box">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-sm-6 pb-5">
                            <div class="inside-fb">
                                <div class="fb-icon"><a href="#"><i class="fa fa-heartbeat" aria-hidden="true"></i></a></div>
                                <div class="fb-content">
                                    <h3>Health Care</h3>
                                    <p>ITDPL provides Assistance, Guidance and feedback of Healthcare organization with the decision support Software.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 pb-5">
                            <div class="inside-fb">
                                <div class="fb-icon"><a href="#"><i class="fa fa-book" aria-hidden="true"></i></a></div>
                                <div class="fb-content">
                                    <h3>Education</h3>
                                    <p>ITDPL makes Education management efficient and effective with the Education software.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 pb-5">
                            <div class="inside-fb">
                                <div class="fb-icon"><a href="#"><i class="fa fa-users" aria-hidden="true"></i></a></div>
                                <div class="fb-content">
                                    <h3>Human Resource Management</h3>
                                    <p>ITDPL provides digital solution to manage and optimize Human Resource Management Tasks and goals of organization.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 pb-5">
                            <div class="inside-fb">
                                <div class="fb-icon"><a href="#"><i class="fa fa-desktop" aria-hidden="true"></i></a></div>
                                <div class="fb-content">
                                    <h3>Software & Information Technologies</h3>
                                    <p>ITDPL makes effective and efficient Software & Information Technologies solutions for the different sectors.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 pb-5">
                            <div class="inside-fb">
                                <div class="fb-icon"><a href="#"><i class="fa fa-taxi" aria-hidden="true"></i></a></div>
                                <div class="fb-content">
                                    <h3>Logistic & Transport</h3>
                                    <p>ITDPL provides Software and IT support for all the processes of Logistic & Transport.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 pb-5">
                            <div class="inside-fb">
                                <div class="fb-icon"><a href="#"><i class="fa fa-handshake-o" aria-hidden="true"></i></a></div>
                                <div class="fb-content">
                                    <h3>Customer Relationship Management</h3>
                                    <p>ITDPL provides complete guidance and support for customer and potential customers interactions and sales tracking.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 pb-5">
                            <div class="inside-fb">
                                <div class="fb-icon"><a href="#"><i class="fa fa-calendar" aria-hidden="true"></i></a></div>
                                <div class="fb-content">
                                    <h3>Event Management</h3>
                                    <p>ITDPL provides complete software and IT support for managing the Events of customers.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 pb-5">
                            <div class="inside-fb">
                                <div class="fb-icon"><a href="#"><i class="fa fa-camera-retro" aria-hidden="true"></i></a></div>
                                <div class="fb-content">
                                    <h3>Ad & Campaign</h3>
                                    <p>ITDPL provides software for planning, execution and tracking of advertising the new products or events.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 pb-5">
                            <div class="inside-fb">
                                <div class="fb-icon"><a href="#"><i class="fa fa-tasks" aria-hidden="true"></i></a></div>
                                <div class="fb-content">
                                    <h3>Enterprise Resource Planning</h3>
                                    <p>ITDPL provides software and IT support to manage day-to-day business activities.</p>
                                </div>
                                </div>
                            </div>
                        <div class="col-lg-4 col-sm-6 pb-5">
                            <div class="inside-fb">
                                <div class="fb-icon"><a href="#"><i class="fa fa-cloud" aria-hidden="true"></i></a></div>
                                <div class="fb-content">
                                    <h3>Cloud Computing</h3>
                                    <p>ITDPL provides delivery of computing services including servers, storage, databases, networking, software, analytics, and intelligence.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 pb-5">
                            <div class="inside-fb">
                                <div class="fb-icon"><a href="#"><i class="fa fa-briefcase" aria-hidden="true"></i></a></div>
                                <div class="fb-content">
                                    <h3>Project and Process Management</h3>
                                    <p>ITDPL provides Project and Process Management software for quality improvement and defect reduction techniques.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 pb-5">
                            <div class="inside-fb">
                                <div class="fb-icon"><a href="#"><i class="fa fa-code" aria-hidden="true"></i></a></div>
                                <div class="fb-content">
                                    <h3>Code Management</h3>
                                    <p>ITDPL provides effective and efficient software to handle the code changes of computer software.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="id-optimization" role="tabpanel" aria-labelledby="optimization-tab">
            <div class="optimist-responsive">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4 text-center align-self-center">
                            <img class="left-side-img" src="assets/images/laptop.png">
                        </div>
                        <div class="col-md-8 text-center text-md-left right-side-block">
                            <div class="heading-section">
                                <h3>Engagement Models for Services.</h3>
                            </div>
                            <p>Fixed Price</p>
                            <p>Agile Method</p>
                            <p>Time & Material</p>
                            <p>Hybrid (Fixed Time & Material Combined)</p>
                            <p>BOT (Build Operate Transfer)</p>
                            <p>Co-Work</p>
                            <a href="#" class="btn btn-outline-dark">Learn more</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


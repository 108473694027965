<!-- Modal -->
<div class="modal fade" id="privacyContentModal" role="dialog">
    <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header" >
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4><span class="glyphicon glyphicon-lock"></span> Privacy & Agreement</h4>
            </div>
            <div class="modal-body">
                <div class="privacy-data" >
                    <div class="content-wrapper" >
                        <div class="content" >
                            <strong>Privacy Policy</strong>
                            <p>We, One97 Communications Ltd., are registered at 1st Floor  Devika Tower, Nehru Place New Delhi - 110019 and headquartered at B-121,  Sector-5, Noida - 201301, hereinafter referred to as Paytm. At Paytm, we value  your trust &amp; respect your privacy. This Privacy Policy provides you with  details about the manner in which your data is collected, stored &amp; used by  us. You are advised to read this Privacy Policy carefully. By downloading and  using the Paytm application/ website/WAP site you expressly give us consent to  use &amp; disclose your personal information in accordance with this Privacy  Policy. If you do not agree to the terms of the policy, please do not use or  access Paytm.</p>
                            <p>Note: Our privacy policy may change at any time without  prior notification. To make sure that you are aware of any changes, kindly  review the policy periodically. This Privacy Policy shall apply uniformly to  Paytm applications, desktop website &amp; mobile WAP site. </p>
                            <strong>General</strong>
                            <p> We will not sell, share or rent your personal information to  any 3rd party or use your email address/mobile number for unsolicited emails  and/or SMS. Any emails and/or SMS sent by Paytm will only be in connection with  the provision of agreed services &amp; products and this Privacy Policy.<br>
                                Periodically, we may reveal general statistical information  about Paytm &amp; its users, such as number of visitors, number and type of  goods and services purchased, etc.<br>
                                We reserve the right to communicate your personal information  to any third party that makes a legally-compliant request for its disclosure.</p>
                            <strong>Personal Information</strong>
                            <p>Personal Information means and includes all information that  can be linked to a specific individual or to identify any individual, such as  name, address, mailing address, telephone number, email ID, credit card number,  cardholder name, card expiration date, information about your mobile phone, DTH  service, data card, electricity connection, Smart Tags and any details that may  have been voluntarily provide by the user in connection with availing any of  the services on Paytm.</p>
                            <p>When you browse through Paytm, we may collect information  regarding the mobile/ tab device details, domain and host from which you access  the internet, the Internet Protocol [IP] address of the computer or Internet  service provider [ISP] you are using, and anonymous site statistical data.</p>
                            <strong>Use of Personal  Information</strong>
                            <p>
                                We use personal information to provide you with services  &amp; products you explicitly requested for, to resolve disputes, troubleshoot  concerns, help promote safe services, collect money, measure consumer interest  in our services, inform you about offers, products, services, updates,  customize your experience, detect &amp; protect us against error, fraud and  other criminal activity, enforce our terms and conditions, etc.<br>
                                We also use your contact information to send you offers  based on your previous orders and interests.<br>
                                We may occasionally ask you to complete optional online  surveys. These surveys may ask you for contact information and demographic  information (like zip code, age, gender, etc.). We use this data to customize  your experience at Paytm, providing you with content that we think you might be  interested in and to display content according to your preferences.</p>
                            <strong>Cookies</strong>
                            <p>A &quot;cookie&quot; is a small piece of information stored  by a web server on a web browser so it can be later read back from that  browser. Paytm uses cookie and tracking technology depending on the features  offered. No personal information will be collected via cookies and other  tracking technology; however, if you previously provided personally  identifiable information, cookies may be tied to such information. Aggregate  cookie and tracking information may be shared with third parties.</p>
                            <strong>Links to Other Sites</strong>
                            <p>Our site links to other websites that may collect personally  identifiable information about you. Paytm is not responsible for the privacy  practices or the content of those linked websites.</p>

                            <strong>Security</strong>
                            <p> Paytm has stringent security measures in place to protect  the loss, misuse, and alteration of the information under our control. Whenever  you change or access your account information, we offer the use of a secure  server. Once your information is in our possession we adhere to strict security  guidelines, protecting it against unauthorized access.</p>

                            <strong>Consent</strong>
                            <p>By using Paytm and/or by providing your information, you  consent to the collection and use of the information you disclose on Paytm in  accordance with this Privacy Policy, including but not limited to your consent  for sharing your information as per this privacy policy.</p>
                            <p>We are required to make an automated IVR or a manual verification call to the customer for every cash on delivery (COD) order placed. You will receive this verification call even if your number is registered for the Do Not Disturb (DND) option. </p>
                            <p>The name and contact details of the Grievance Officer are  provided below:<p>
                            Mr. Nishant Saluja<br>
                            One97 Communications Ltd.<br>
                            B-121, Sector-5, Noida - 201301<br>
                            Email:&nbsp;care@paytm.com<br>
                            Time: Mon - Sat (9:00 am - 6:00 pm)</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-danger btn-default pull-right" data-dismiss="modal"><span class="glyphicon glyphicon-remove"></span> Cancel</button>
            </div>
        </div>
    </div>
</div>

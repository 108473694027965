import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import { Title }     from '@angular/platform-browser';
import {Router} from '@angular/router';
import { APP_CONFIG } from '../../../constants';
import { MAIN_CONSTANTS } from '../../constants';
import { WORKING_DOMAIN_CONSTANTS } from '../../../working-domain/constants';

@Component({
  selector: 'working-domain-root',
  templateUrl: './working-domain.component.html',
  styleUrls: []
})
export class WorkingDomainComponent {
    CONFIG=APP_CONFIG;
    MAIN_CONSTANTS=MAIN_CONSTANTS;
    constructor(private titleService: Title, private router: Router) {
        this.CONFIG=APP_CONFIG;
        this.MAIN_CONSTANTS=MAIN_CONSTANTS;
        this.titleService.setTitle( APP_CONFIG.APP_NAME+ " :: "+ this.MAIN_CONSTANTS.LABEL.WORKINGDOMAIN);
    }
    callHealthCare() {
        this.router.navigate([WORKING_DOMAIN_CONSTANTS.URL.WORKING_DOMAIN_HEALTH_CARE]);
    }
    callEducation() {
        this.router.navigate([WORKING_DOMAIN_CONSTANTS.URL.WORKING_DOMAIN_EDUCATION]);
    }
    callAdCampaign() {
        this.router.navigate([WORKING_DOMAIN_CONSTANTS.URL.WORKING_DOMAIN_AD_CAMPAIGN]);
    }
    callSoftwareInformation() {
        this.router.navigate([WORKING_DOMAIN_CONSTANTS.URL.WORKING_DOMAIN_SOFTWARE_INFORMATION]);
    }
    callLogistics() {
        this.router.navigate([WORKING_DOMAIN_CONSTANTS.URL.WORKING_DOMAIN_LOGISTICS]);
    }
    callEventManagement() {
        this.router.navigate([WORKING_DOMAIN_CONSTANTS.URL.WORKING_DOMAIN_EVENT_MANAGEMENT]);
    }
}
<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>Technology Overview</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <p class="mb-4">Information Technology is a fast growing industry. New architecture and multiplying software platforms are forcing the application development to undergo a massive change in the Technologies . And yet the pressure of building a robust & agile application is growing continuously. The technical skill factor has no longer remained as guarantee to primary success: collaboration, accountability, compliance, portfolio and resource management are all becoming key factors of the new Application Development Team. We have expertise in building Enterprise class Software Solutions using technology from Microsoft, Oracle and Java Platforms. Our software teams are bound by dedication and loyalty; that enables us to manage our resources effectively & efficiently. We believe in team work where every team member is accountable for his deeds.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>Technology</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <p class="mb-4">Indian Technologies Dreams has a unique blend of web site programmers web site designers that ensures you quality work in quick time. Our web site design and development services guarantee hassle-free software development from conception to conceptualization at throw-away budget. As a result we have been blessed with a great base of clients that involve us in some exciting projects. Our growing list of clientele and proud portfolio suggest the same and vouch safes authentic service from expert and qualified professionals.</p>
                    <p class="mb-4">We, at Indian Technologies Dreams offer an array of web design services like <b>Web site Design, Software, Custom Web site development & Graphic Design Services</b> in order to enhance the corporate image of our clients. A strong web site must be developed with careful planning, which should be visually appealing spreading out its distinctive flavors and colors across the world. We believes in creations that not just only stand out due to its amiable look and matchless splendor, but can also effectively draw the target audience.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
<div class="clusters-section">
    <div class="page-title">
        <div class="container page-title-padding">
            <h1>CMS Based Websites</h1>
        </div>
    </div>
    <div class="page-meta">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 text-center text-md-left dropdown-content">
                    <p class="mb-4">All of the aforementioned sites host a database and a Content Management System (CMS) to manage the contents i.e. text and images of these website just the way you would want wish to manage various sections on your website. We have designed and continually maintain several corporate websites and can surely look at designing and developing one for you.</p>
                    <h4 class="mb-4">Benefits of CMS</h4>
                    <ul>
                        <li>Our customized CMS solutions result in better performance, save your precious time and money. You don't need to have extra staff for making regular changes and management of your website - and editing of content will become just simple as typing a document and saving it - similar to the MS word.</li>
                        <li>Our tailored made CMS systems keep your website up-to-date that results in competitive advantage and you can gain leadership in your Internet presence that in turns give prosperity in your business.</li>
                        <li>Our well coupled CMS solutions help you to manage website navigation, website structure, dynamic elements, content, tables and web-forms.</li>
                    </ul>
                    <p class="mb-4">The Content Management System is a web based software application that helps you to manage your website content with great ease through a web based browser application. We at Indian Technologies Dreams, offer you a choice between our Standard Open Source CMS Solution and Professional CMS Solution. We have the expertise and knowledge in various CMS like Drupal, Jhoomla etc, which gives us the flexibility to provide you with solutions and a professional user interface according to your requirements.</p>
                </div>
            </div>
        </div>
    </div>
</div>